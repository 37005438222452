/* eslint-disable prettier/prettier */
import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP, PATH_PAGE } from 'src/routes/paths';
import i18next from '../../../locales/i18n';


// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

// const path1 = (name) => `/static/icons/navbar/png/${name}.png`;



const ICONS = {

  
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  marketing: <MIcon src={path('Blogs')} />,
  toll: <MIcon src={path('toll')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  locationLog: <MIcon src={path('Artboard 2')} />,
  locationGroup: <MIcon src={path('Artboard 4')} />,
  order: <MIcon src={path('Artboard 6')} />,
  settings: <MIcon src={path('Artboard 9')} />,
  report: <MIcon src={path('Artboard 3')} />,
  rider: <MIcon src={path('Artboard 5')} />,
  superVisor: <MIcon src={path('Artboard 10')} />,
  driverManagement: <MIcon src={path('name-id-icon')} />,
  carDrivers: <MIcon src={path('driver-icon')} />,
  taxiIcon: <MIcon src={path('taxi-icon')} />,
  liveTracking: <MIcon src={path('liveTracking')} />,
  corporate: <MIcon src={path('corporate')} />,
  setting: <MIcon src={path('setting-icon')} />,
  location: <MIcon src={path('location')} />,
  discount: <MIcon src={path('discount-icon')} />,
  customer: <MIcon src={path('customer')} />,
  companies: <MIcon src={path('companies')} />,
  cars: <MIcon src={path('cars-icon')} />,
  permission: <MIcon src={path('user')} />,
  managePermission: <MIcon src={path('1472534437')} />,
  handOver:<MIcon src={path('Artboard 7')} />,
  
};


const navConfig = [

  
  {
    // subheader: i18next.t('dashboard'),
    items: [
      {
        title: i18next.t('dashboard'),
        href: PATH_APP.report.engine,
        icon: ICONS.dashboard
      },
      {
        title: i18next.t('nds'),
        href: PATH_APP.report.report_new,
        icon: ICONS.report
      },
      {
        title: i18next.t('dispatch_map'),
        href: PATH_APP.report.taxiDispatch,
        icon: ICONS.liveTracking
      },
      // {
      //   title: 'Live Tracking',
      //   href: PATH_PAGE.comingSoon,
      //   icon: ICONS.liveTracking
      // },
      {
        title: i18next.t('orders'),
        href: PATH_APP.report.newBooking,
        icon: ICONS.order
      },
      {
        title: i18next.t('manage_permission'),
        href: PATH_APP.report.companyList,
        icon: ICONS.managePermission
      },
      // Need to comment ⚠️
      {
        title: i18next.t('manage_handover'),
        href: PATH_APP.report.manageHandover,
        icon: ICONS.handOver
      },
      {
        title: i18next.t('custom_zone'),
        href: PATH_APP.report.manageCustomZone,
        icon: ICONS.permission
      },
      {
        title: i18next.t('lifestyle_offer_zone'),
        href: PATH_APP.report.manageOfferZone,
        icon: ICONS.chat
      },
    ]
  },
  {
    items: [
      {
        title: i18next.t('divisions'),
        icon: ICONS.charts,
        href: PATH_APP.report.manageDivision,
      
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('departments'),
        icon: ICONS.locationGroup,
        href: PATH_APP.report.manageDepartment,
      
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('drivers'),
        icon: ICONS.carDrivers,
        items: [
          {
            title: i18next.t('manage_drivers'),
            href: PATH_APP.report.manageDriver
          },
          {
            title: i18next.t('manage_new_drivers'),
            href: PATH_APP.report.manageNewDrivers
          },
          {
            title: i18next.t('export_driver_list'),
            href: PATH_APP.report.exportDriverList
          },
          // Need to comment ⚠️
          {
            title: i18next.t('manage_lost_found_logs'),
            href: PATH_APP.report.lostFoundList
          },
          // {
          //   title: 'New Driver Request',
          //   href: PATH_PAGE.comingSoon,
          //   icon: ICONS.page
          // },
          // {
          //   title: 'Driver Subscription',
          //   href: PATH_PAGE.comingSoon,
          //   icon: ICONS.page
          // },
          {
            title: i18next.t('driver_document_request'),
            href: PATH_APP.report.driverDocument,
            icon: ICONS.page
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('cars'),
        icon: ICONS.cars,
        items: [
          // {
          //   title: 'Manage Fare',
          //   href: PATH_APP.report.manageFare,
          //   icon: ICONS.page
          // },
          {
            title: i18next.t('manage_models'),
            href: PATH_APP.report.manageModel,
            icon: ICONS.page
          },
          {
            title: i18next.t('manage_cars'),
            href: PATH_APP.report.manageCars,
            icon: ICONS.page
          },
          // uncommented for sheet 
          // {
          //   title: 'Car Models',
          //   href: PATH_PAGE.comingSoon,
          //   icon: ICONS.page
          // },
          {
            title: i18next.t('assign_car'),
            href: PATH_APP.report.assignCar,
            icon: ICONS.page
          },
          {
            title: i18next.t('manage_assigned_cars'),
            href: PATH_APP.report.assignCars,
            icon: ICONS.page
          },
          {
            title: i18next.t('taxi_subscription_list'),
            href: PATH_APP.report.taxiList,
            icon: ICONS.page
          },
          {
            title: i18next.t('car_manufacturer'),
            href: PATH_APP.report.manageManufacturer,
            icon: ICONS.page
          },
          {
            title: i18next.t('car_make'),
            href: PATH_APP.report.manageCarMake,
            icon: ICONS.page
          },
        ]
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('companies') ,
        icon: ICONS.companies,
        href: PATH_APP.report.companies,
        items: [
          {
            title: i18next.t('manage_company'),
            href: PATH_APP.report.companies,
            icon: ICONS.page
          },
          {
            title: i18next.t('manage_promocode'),
            href: PATH_APP.report.managePromocode,
            icon: ICONS.page
          },
        
        ]
      }
    ]
  },
  // {
  //   items: [
  //     {
  //       title: 'Customers',
  //       icon: ICONS.customer,
  //       items: [
  //         {
  //           title: 'Passenger Wallet Logs',
  //           href: PATH_PAGE.comingSoon,
  //           icon: ICONS.page
  //         },
  //         {
  //           title: 'Passenger Request',
  //           href: PATH_PAGE.comingSoon,
  //           icon: ICONS.page
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    items: [
      {
        title: i18next.t('corporates'),
        icon: ICONS.corporate,
        href: PATH_APP.report.zone,
        items: [
          {
            title: i18next.t('manage_corporate'),
            href: PATH_APP.report.corporates,
            icon: ICONS.page
          },
          {
            title: i18next.t('corporate_packages'),
            href: PATH_APP.report.corporatePackage,
            icon: ICONS.page
          },
          // {
          //   title: 'Manage Location',
          //   href: PATH_APP.report.companyLocations,
          //   icon: ICONS.page
          // },
          {
            title: i18next.t('manage_zone'),
            href: PATH_APP.report.manageZone,
            icon: ICONS.page
          },
          {
            // title: 'Zone',
            title: i18next.t('zone_map_view'),
            href: PATH_APP.report.zone,
            icon: ICONS.elements
          }
        ]
      }
    ]
  },

 
  {
    items: [
      {
        title: i18next.t('passengers'),
        icon: ICONS.user,
        href: PATH_APP.report.passengers,
        items: [
          {
            title: i18next.t('manage_passengers'),
            href: PATH_APP.report.passengers,
            icon: ICONS.page
          },
          {
            title: i18next.t('passenger_packages'),
            href: PATH_APP.report.passengerPackage,
            icon: ICONS.page
          },
        ]
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('locations'),
        icon: ICONS.location,
        href: PATH_APP.report.zone,
        items: [
          //TODO Working progress start customize Fare

          {
            title: i18next.t('manage_location'),
            href: PATH_APP.report.companyLocations,
            icon: ICONS.page
          },
          {
            title: i18next.t('manage_queue_location'),
            href: PATH_APP.report.manageLocationQueue,
            icon: ICONS.page
          },

          //Working progress end

          // Location Group
          {
            title: i18next.t('location_group'),
            href: PATH_APP.report.locationGroup,
            icon: ICONS.map
          },

          // {
          //   title: 'test',
          //   href: PATH_APP.report.test,
          //   icon: ICONS.map
          // },
          {
            title: i18next.t('location_log'),
            href: PATH_APP.report.timeZone,
            icon: ICONS.locationLog
          },

          {
            title: i18next.t('location_queue_driver_log'),
            href: PATH_APP.report.locationQueueLogs,
            icon: ICONS.locationLog
          }
        ]
      }
    ]
  },

  
  {
    items: [
      {
        title: i18next.t('known_locations'),
        icon: ICONS.language,
        href: PATH_APP.report.zone,
        items: [

          {
            title: i18next.t('known_location_country'),
            href: PATH_APP.report.knownLocationCountry,
            icon: ICONS.page
          },
          {
            title: i18next.t('manage_category'),
            href: PATH_APP.report.manageCategory,
            icon: ICONS.page
          },
          {
            title: i18next.t('manage_known_loc'),
            href: PATH_APP.report.manageKnownLocation,
            icon: ICONS.page
          },

        ]
      }
    ]
  },

  // Tolls

  {
    items: [
      {
        title: i18next.t('tolls'),
        icon: ICONS.toll,
        href: PATH_APP.report.manageTolls,
        items: [
          {
            title: i18next.t('manage_tolls'),
            href: PATH_APP.report.manageTolls,
            icon: ICONS.toll
          },
        ]
      }
    ]
  },

  {
    items: [
      {
        title: i18next.t('supervisor'),
        icon: ICONS.superVisor,
        href: PATH_APP.report.supervisor,
        items: [
          {
            title: i18next.t('supervisor'),
            href: PATH_APP.report.supervisor,
            icon: ICONS.superVisor
          },
          {
            title: i18next.t('supervisor_trip_reports'),
            href: PATH_APP.report.supervisorTripReports
          },
          {
            title: i18next.t('supervisor_logs'),
            href: PATH_APP.report.supervisorLogs
          }
        ]
      }
    ]
  },
  // {
  //   items: [
  //     {
  //       title: 'Fine',
  //       icon: ICONS.superVisor,
  //       href: PATH_APP.report.supervisor,
  //       items: [
  //         {
  //           title: 'Manage Fine',
  //           href: PATH_APP.report.manageFine,
  //           icon: ICONS.elements
  //         },
  //         {
  //           title: 'Manage Fine Category',
  //           href: PATH_APP.report.manageFineCategory,
  //           icon: ICONS.elements
  //         },

  //       ]
  //     }
  //   ]
  // },

  {
    items: [
      {
        title: i18next.t('discount'),
        icon: ICONS.discount,
        // href: PATH_APP.report.driver,
        items: [
          {
            title:i18next.t('mobile_promotion'),
            href: PATH_PAGE.comingSoon,
            icon: ICONS.page
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('marketing'),
        icon: ICONS.marketing,
        href: PATH_APP.report.zone,
        items: [
          {
            title:i18next.t('lifestyle_location'),
            href: PATH_APP.report.lifestyleLocations,
            icon: ICONS.location
          },
          {
            title:i18next.t('manage_referral_settings'),
            href: PATH_APP.report.manageReferralSettings
          },
         
        ]
      }
    ]
  },

  // {
  //   items: [
  //     {
  //       title: 'Locations',
  //       icon: ICONS.location,
  //       // href: PATH_APP.report.driver,
  //       items: [
  //         {
  //           title: 'Location Queue',
  //           href: PATH_PAGE.comingSoon,
  //           icon: ICONS.elements
  //         },
  //         {
  //           title: 'Manage Location',
  //           href: PATH_APP.report.manageLocations,
  //           icon: ICONS.elements
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   // subheader: 'DashboardUser',
  //   items: [
  //     {
  //       title: 'Supervisor',
  //       href: PATH_APP.report.supervisor,
  //       icon: ICONS.superVisor
  //     },

  //     {
  //       title: 'Location Log',
  //       href: PATH_APP.report.timeZone,
  //       icon: ICONS.locationLog
  //     },
  //   ]
  // },
  {
    // subheader: 'Dashboard',
    items: [
      {
        title: i18next.t('reports'),
        // icon: ICONS.elements,
        icon: ICONS.editor,
        href: PATH_APP.report.report,
        items: [
          {
            title: i18next.t('driver_shift_hours'),
            href: PATH_PAGE.comingSoon
          },
          {
            title: i18next.t('settlement'),
            href: PATH_APP.report.settle
          },
          {
            title:i18next.t('driver_status'),
            href: PATH_APP.report.driverStatus
          },
          {
            title: i18next.t('exit_record'),
            href: PATH_APP.report.exitRecord
          },
          {
            title: i18next.t('geo_report'),
            href: PATH_APP.report.geoReport
          },
          {
            title: i18next.t('location_queue_driver_logs'),
            href: PATH_APP.report.LocationQueueDriverLogs
          },
          {
            title: i18next.t('all_other_reports'),
            href: PATH_PAGE.comingSoon
          },
          {
            title: i18next.t('driver_rejection_report'),
            href: PATH_APP.report.driverRejectionReport
          },
        ]
      }
    ]
  },
  {
    items: [
      {
        title: i18next.t('trip_reports'),
        icon: ICONS.report,
        href: PATH_APP.report.allTransactions,
        items: [
          {
            title: i18next.t('all_transactions'),
            href: PATH_APP.report.allTransactions
          },
          {
            title: i18next.t('completed_transactions'),
            href: PATH_APP.report.CompletedTransactions
          },
          {
            title: i18next.t('cancelled_transactions'),
            href: PATH_APP.report.cancelledTransactions
          },
          {
            title: i18next.t('account_reports'),
            href: PATH_APP.report.accountReports
          },
          // uncommented Start
          // {
          //   title: 'Rejected Trips',
          //   href: PATH_APP.report.rejectedTrips
          // },
          // {
          //   title: 'Pending Payment Details',
          //   href: PATH_APP.report.pendingPaymentDetails
          // },
          // {
          //   title: 'Cancel Trip Report',
          //   href: PATH_APP.report.cancelTripReport
          // },
          // {
          //   title: 'Driver Shift Reports',
          //   href: PATH_APP.report.driverShiftHistory
          // },
          // uncommented end
          {
            title: i18next.t('supervisor_trip_reports'),
            href: PATH_APP.report.supervisorTripReports
          },
          // uncommented Start
          // {
          //   title: 'Supervisor Logs',
          //   href: PATH_APP.report.supervisorLogs
          // },
          // uncommented end
          {
            title: i18next.t('activity_logs'),
            href: PATH_APP.report.activityLogs
          },
          {
            title: i18next.t('historical_data'),
            href: PATH_APP.report.historicalData,
          },
          // uncommented Start
          // {
          //   title: 'Zone Entry Logs',
          //   href: PATH_APP.report.zoneEntryLogs
          // }
          // uncommented end
        ]
      }
    ]
  },
  {
    // subheader: 'Dashboard',
    items: [
      {
        title: i18next.t('settings'),
        icon: ICONS.setting,
        href: PATH_APP.report.report,
        items: [
          {
            title: i18next.t('site_settings'),
            href: PATH_APP.report.manageSiteSettings
          },
          {
            title: i18next.t('sms_templates'),
            href: PATH_APP.report.manageSmsTemplates
          },
          {
            title: i18next.t('admin_smtp_settings'),
            href: PATH_APP.report.editAdminSMTPSetting
          },
          {
            title: i18next.t('company_smtp_settings'),
            href: PATH_APP.report.manageSmtpMailSetting
          },
          {
            title: i18next.t('admin_sms_settings'),
            href: PATH_APP.report.editSMSSMTPSetting
          },
          {
            title: i18next.t('company_sms_settings'),
            href: PATH_APP.report.manageSMSSetting
          },
          {
            title: i18next.t('manage_country'),
            href: PATH_APP.report.manageCountry
          },
          {
            title: i18next.t('manage_state'),
            href: PATH_APP.report.manageState
          },
          {
            title: i18next.t('manage_city'),
            href: PATH_APP.report.manageCity
          },
          {
            title: i18next.t('manage_social_network'),
            href: PATH_APP.report.addEditSocialNetwork
          },
          {
            title: i18next.t('manage_google_settings'),
            href: PATH_APP.report.manageGoogleSettings
          },
          // {
          //   title: 'Custome Zone',
          //   href: PATH_PAGE.comingSoon
          // },
          // {
          //   title: 'Permission',
          //   href: PATH_PAGE.comingSoon
          // },
          // {
          //   title: 'Tolls',
          //   href: PATH_PAGE.comingSoon
          // },
          // {
          //   title: 'All Other Settings',
          //   href: PATH_PAGE.comingSoon
          // }
        ]
      }
    ]
  },
  {
    items: [
      // {
      //   title: 'Location Group',
      //   href: PATH_APP.report.locationGroup,
      //   icon: ICONS.map
      // },
      {
        title: i18next.t('rider'),
        href: PATH_APP.report.pushNotification,
        icon: ICONS.rider,
      }
      // {
      //   title: 'Maps',
      //   href: PATH_APP.report.gMaps,
      //   icon: ICONS.rider
      // }
    ]
  }

  // {
  //   items: [
  //     {
  //       title: 'Car & Drivers',
  //       icon: ICONS.taxiIcon,
  //       href: PATH_APP.report.manageCars,
  //       items: [
  //         {
  //           title: 'Manage Cars',
  //           href: PATH_APP.report.manageCars
  //         },
  //         {
  //           title: 'Driver Management',
  //           href: PATH_APP.report.manageDriver,
  //           icon: ICONS.driverManagement,
  //           items: [
  //             {
  //               title: 'Manage Drivers',
  //               href: PATH_APP.report.manageDriver
  //             },
  //             {
  //               title: 'Manage New Drivers',
  //               href: PATH_APP.report.manageNewDrivers
  //             },
  //             {
  //               title: 'Export Driver List',
  //               href: PATH_APP.report.exportDriverList
  //             },
  //             {
  //               title: 'Manage lost/found logs',
  //               href: PATH_APP.report.lostFoundList
  //             }
  //           ]
  //         },
  //         {
  //           title: 'Manage Assigned Cars',
  //           href: PATH_APP.report.assignCars
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default navConfig;

