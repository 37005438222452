/* eslint-disable prettier/prettier */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  // demo: {
  //   title: `Arabic`,
  //   introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  // },
  // docs: {
  //   hi: `أهلا`,
  //   description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
  //   documentation: `توثيق`,
  // },
  // app: `تطبيق`,
  // user: `المستعمل`,
  // list: `قائمة`,
  // edit: `تعديل`,
  // shop: `متجر`,
  // blog: `مقالات`,
  // post: `بريد`,
  // mail: `بريد`,
  // chat: `دردشة`,
  // cards: `البطاقات`,
  // posts: `المشاركات`,
  // create: `يزيد`,
  // kanban: `كانبان`,
  // general: `جنرال لواء`,
  // banking: `الخدمات المصرفية`,
  // booking: `الحجز`,
  // profile: `الملف الشخصي`,
  // account: `الحساب`,
  // product: `المنتوج`,
  // invoice: `فاتورة`,
  // details: `تفاصيل`,
  // checkout: `الدفع`,
  // calendar: `التقويم`,
  // analytics: `التحليلات`,
  // ecommerce: `التجارة الإلكترونية`,
  // management: `إدارة`,
  // menu_level: `مستوى القائمة `,
  // menu_level_2a: `مستوى القائمة 2 أ`,
  // menu_level_2b: `مستوى القائمة 2 ب`,
  // menu_level_3a: `مستوى القائمة 3 أ`,
  // menu_level_3b: `مستوى القائمة 3 ب`,
  // menu_level_4a: `مستوى القائمة 4 أ`,
  // menu_level_4b: `مستوى القائمة 4 ب`,
  // item_disabled: `العنصر معطل`,
  // item_label: `تسمية العنصر`,
  // item_caption: `عنوان العنصر`,
  // item_external_link: `رابط خارجي للمادة`,
  // description: `وصف`,
  // other_cases: `حالات اخرى`,
  // item_by_roles: `عنصر حسب الأدوار`,
  // only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,

  "dashboard": "لوحة القيادة",
    "nds": "NDS",
    "dispatch_map": "خريطة الإرسال",
    "orders": "طلبات",
    "manage_permission": "إدارة الإذن",
    "manage_handover": "إدارة التسليم",
    "custom_zone": "المنطقة المخصصة",
    "lifestyle_offer_zone": "منطقة عروض لايف ستايل",
    "divisions": "الأقسام",
    "departments": "الأقسام",
    "drivers": "السائقين",
    "manage_drivers": "إدارة برامج التشغيل",
    "manage_new_drivers": "إدارة برامج التشغيل الجديدة",
    "export_driver_list": "تصدير قائمة السائقين",
    "manage_lost_found_logs": "إدارة السجلات المفقودة / التي تم العثور عليها",
    "driver_document_request": "طلب وثيقة السائق",
    "cars": "سيارات",
    "manage_models": "إدارة النماذج",
    "manage_cars": "إدارة السيارات",
    "assign_car": "تعيين سيارة",
    "manage_assigned_cars": "إدارة السيارات المخصصة",
    "taxi_subscription_list": "قائمة الاشتراك في سيارات الأجرة",
    "car_manufacturer": "الشركة المصنعة للسيارة",
    "car_make": "صنع السيارة",
    "companies": "شركات",
    "manage_company": "إدارة الشركة",
    "manage_promocode": "إدارة الرمز الترويجي",
    "corporates": "الشركات",
    "manage_corporate": "إدارة الشركة",
    "corporate_packages": "حزم الشركات",
    "manage_zone": "إدارة المنطقة",
    "zone_map_view": "عرض خريطة المنطقة",
    "passengers": "الركاب",
    "manage_passengers": "إدارة الركاب",
    "passenger_packages": "حزم الركاب",
    "locations": "المواقع",
    "known_locations": "المواقع المعروفة",
    "known_location_country": "إدارة البلد الموقع المعروف",
    "manage_category": "إدارة الفئة",
    "manage_known_loc": "إدارة الموقع المعروف",
    "manage_location": "إدارة الموقع",
    "manage_queue_location": "إدارة موقع قائمة الانتظار",
    "location_group": "مجموعة الموقع",
    "location_log": "سجل الموقع",
    "location_queue_driver_log": "سجل سائق قائمة انتظار الموقع",
    "tolls": "رسوم",
    "manage_tolls": "إدارة الرسوم",
    "supervisor": "مشرف",
    "supervisor_trip_reports": "تقارير رحلة المشرف",
    "supervisor_logs": "سجلات المشرف",
    "discount": "تخفيض",
    "mobile_promotion": "ترويج المحمول",
    "marketing": "تسويق",
    "lifestyle_location": "موقع نمط الحياة",
    "manage_referral_settings": "إدارة إعدادات الإحالة",
    "reports": "التقارير",
    "driver_shift_hours": "ساعات تبديل السائق",
    "settlement": "مستعمرة",
    "driver_status": "حالة السائق",
    "exit_record": "سجل الخروج",
    "geo_report": "التقرير الجغرافي",
    "location_queue_driver_logs": "سجلات سائق قائمة انتظار الموقع",
    "all_other_reports": "جميع التقارير الأخرى",
    "driver_rejection_report": "تقرير رفض السائق",
    "trip_reports": "تقارير الرحلة",
    "all_transactions": "كل الحركات المالية",
    "completed_transactions": "المعاملات المكتملة",
    "cancelled_transactions": "المعاملات الملغاة",
    "account_reports": "تقارير الحساب",
    "activity_logs": "سجلات النشاط",
    "historical_data": "البيانات التاريخية",
    "settings": "إعدادات",
    "site_settings": "إعدادات الموقع",
    "sms_templates": "قوالب الرسائل القصيرة",
    "admin_smtp_settings": "إعدادات المشرف SMTP",
    "company_smtp_settings": "إعدادات SMTP للشركة",
    "admin_sms_settings": "إعدادات الرسائل القصيرة للمشرف",
    "company_sms_settings": "إعدادات الرسائل القصيرة للشركة",
    "manage_country": "إدارة البلد",
    "manage_state": "إدارة الدولة",
    "manage_city": "إدارة المدينة",
    "manage_social_network": "إدارة الشبكة الاجتماعية",
    "manage_google_settings": "إدارة إعدادات جوجل",
    "rider": "رايدر",
    "this_report_displays_the_total_driver_shift_hours_for_the_selected_day": "يعرض هذا التقرير إجمالي ساعات وردية السائق لليوم المحدد!",
    "select_company": "اختر الشركة",
    "total_active_hours": "إجمالي ساعات النشاط",
    "active_drivers": "السائقين النشطين",
    "total_vehicle": "إجمالي المركبة",
    "total_driver": "سائق إجمالي",
    "total_shift_in_hours": "إجمالي التحول في ساعات",
    "total_shift_out_hours": "إجمالي ساعات التحول",
    "shift_list": "قائمة التحول",
    "export": "يصدّر",
    "search": "يبحث",
    "device_id": "معرف الجهاز",
    "unique_id": "معرف فريد",
    "driver_name": "اسم السائق",
    "vehicle_model": "طراز السيارة",
    "Company": "شركة",
    "engine_hours": "ساعات المحرك",
    "idle_hours": "ساعات الخمول",
    "shift_in_hours": "التحول في ساعات",
    "shift_out_hours": "ساعات التحول",
    "rows_per_page": "صفوف لكل صفحة",
    "driver_info": "معلومات السائق",
    "general_info": "معلومات عامة",
    "gps": "نظام تحديد المواقع",
    "software_info": "معلومات البرامج",
    "social": "اجتماعي",
    "view_history": "عرض التاريخ",
    "from_date": "من التاريخ",
    "end_date": "تاريخ الانتهاء",
    "cancel": "يلغي",
    "shift_reports": "تقارير التحول",
    "fare_update": "تحديث الأجرة",
    "update": "تحديث",
    "no_data_found": "لم يتم العثور على بيانات",
    "data_not_found": "لم يتم العثور على البيانات",
    "file_upload": "تحميل الملف",
    "password": "كلمة المرور",
    "edit": "يحرر",
    "passenger_name": "اسم الراكب",
    "trip_fare": "أجرة الرحلة",
    "emirate_id": "هوية الإمارة",
    "passenger_phone_number": "رقم هاتف الراكب",
    "free": "حر",
    "s_no": "رقم S",
    "busy": "مشغول",
    "date": "تاريخ",
    "log_in_time": "تسجيل الدخول الوقت",
    "shift_in_duration": "التحول في المدة",
    "shift_out_duration": "مدة التحول",
    "inmove": "تحرك",
    "idle": "عاطل",
    "lat_lng": "خط العرض",
    "accuracy": "دقة",
    "altitude": "ارتفاع",
    "bearing": "تحمل",
    "speed": "سرعة",
    "last_update": "التحديث الأخير",
    "profile": "حساب تعريفي",
    "android": "أندرويد",
    "ios": "دائرة الرقابة الداخلية",
    "user_profile_management": "إدارة ملف تعريف المستخدم",
    "ok": "نعم",
    "change_password": "تغيير كلمة المرور",
    "new_password": "كلمة المرور الجديدة",
    "confirm_password": "تأكيد كلمة المرور",
    "please_enter_new_password": "الرجاء إدخال كلمة المرور الجديدة",
    "please_enter_confirm_password": "الرجاء إدخال تأكيد كلمة المرور",
    "submit": "يُقدِّم",
    "reset": "إعادة ضبط",
    "view_more": "عرض المزيد",
    "driver_information": "معلومات السائق",
    "first_name": "الاسم الأول",
    "email": "بريد إلكتروني",
    "mobile_number": "رقم الهاتف المحمول",
    "otp": "مكتب المدعي العام",
    "date_of_birth": "تاريخ الميلاد",
    "booking_limit_per_day": "حد الحجز في اليوم الواحد",
    "rating_points": "نقاط التقييم",
    "out_of": "خارج",
    "referral_code": "رمز الإحالة",
    "my_current_location": "موقعي الحالي",
    "company_name": "اسم الشركة",
    "company_information": "معلومات الشركة",
    "company_address": "عنوان الشركة",
    "country": "دولة",
    "state": "ولاية",
    "city": "مدينة",
    "i_am_free_online": "أنا حر - أون لاين",
    "shift_out_offline": "التحول للخارج - غير متصل",
    "i_am_busy_in_trip": "أنا مشغول بالرحلة",
    "signed_out": "تم تسجيل الخروج",
    "trip_id": "معرف الرحلة",
    "logout": "تسجيل الخروج",
    "refresh": "ينعش",
    "last_updated": "آخر تحديث",
    "shift_history": "تاريخ التحول",
    "show_chart": "إظهار الرسم البياني",
    "show_list": "عرض القائمة",
    "referral_history": "تاريخ الإحالة",
    "total_earned": "مجموع المكتسبة",
    "pending": "قيد الانتظار",
    "completed_journeys": "الرحلات المكتملة",
    "current_month": "الشهر الحالي",
    "previous_month": "الشهر السابق",
    "mail_settings": "إعدادات البريد",
    "send_mail": "إرسال البريد",
    "trip_reports_title": "تقارير الرحلات (العدد المكتمل)",
    "subscription_reports_title": "تقارير الاشتراك",
    "driver_shift_reports_title": "تقارير تحول السائق",
    "location_queue_title": "قائمة انتظار الموقع (عدد السائقين)",
    "email_settings": "إعدادات البريد الإلكتروني",
    "emails": "رسائل البريد الإلكتروني",
    "emails_helper_text": "يجب أن تكون رسائل البريد الإلكتروني مثل abc@gmail.com،xyz@gmail.com",
    "time_to_send": "الوقت لإرسال البريد الإلكتروني",
    "start_time": "وقت البدء",
    "this_field_is_required": "هذه الخانة مطلوبه",
    "success": "نجاح",
    "error_message": "حدث خطأ غير متوقع.",
    "mobile_b2c_now_booking": "Mobile B2C - الحجز الآن",
    "mobile_b2c_later_booking": "Mobile B2C - الحجز لاحقًا",
    "admin_b2c_now_booking": "المشرف - B2C الحجز الآن",
    "admin_b2c_later_booking": "المشرف - الحجز لاحقًا B2C",
    "admin_b2b_now_booking": "المشرف - حجز B2B الآن",
    "admin_b2b_later_booking": "المشرف - الحجز لاحقًا B2B",
    "corporate_b2b_now_booking": "الشركات - حجز B2B الآن",
    "corporate_b2b_later_booking": "الشركات - الحجز لاحقًا لـ B2B",
    "walkin_trip": "رحلة المشي",
    "driver_booking": "حجز السائق",
    "location_trip": "رحلة الموقع",
    "website": "موقع إلكتروني",
    "total_trips": "إجمالي الرحلات",
    "tooltip_completed_transaction": "انتقل إلى تقارير رحلة المعاملات المكتملة",
    "requested": "مطلوب",
    "subscribed": "مشترك",
    "cancelled": "تم الإلغاء",
    "unsubscribed": "غير مشترك",
    "unsubscription_pending": "إلغاء الاشتراك معلق",
    "on_hold": "في الانتظار",
    "total_active_subscriber_count": "إجمالي عدد المشتركين النشطين",
    "tooltip": "انتقل إلى قائمة الاشتراك في سيارات الأجرة",
    "0_8_hours": "0-8 ساعات",
    "8_16_hours": "8-16 ساعة",
    "16_24_hours": "16-24 ساعة",
    "go_to_dashboard": "انتقل إلى تقارير تبديل برامج تشغيل لوحة المعلومات",
    "go_to_manage_location_queue": "انتقل إلى إدارة قائمة انتظار الموقع",
    "driver_details": "تفاصيل السائق",
    "car_model": "موديل السيارة",
    "new_booking": "حجز جديد",
    "search_trip_id": "البحث عن طريق معرف الرحلة",
    "clear": "واضح",
    "upcoming_booking": "الحجز القادم",
    "ongoing_booking": "الحجز مستمر",
    "past_booking": "الحجز الماضي",
    "cancelled_booking": "الحجز ملغى",
    "notification_status": "حالة الإخطار",
    "something_went_wrong": "حدث خطأ ما",
    "mail_preview": "معاينة البريد",
    "send": "يرسل",
    "close": "يغلق",
    "send_mail_to_corporate": "إرسال البريد إلى الشركة",
    "navigate_to_trip": "انتقل إلى الرحلة",
    "passenger_log": "سجل الركاب",
    "pickup_location": "موقع الالتقاط",
    "pickup_landmark": "بيك اب لاندمارك",
    "drop_location": "إسقاط الموقع",
    "drop_landmark": "إسقاط المعالم",
    "booking_type": "نوع الحجز",
    "pickup_time": "وقت الاستلام",
    "payment_method": "طريقة الدفع",
    "note_to_driver": "ملاحظة للسائق",
    "flight_number": "رقم الرحلة",
    "reference_number": "الرقم المرجعي",
    "promo_code": "الرمز الترويجي",
    "customer_price": "سعر العميل",
    "customer_hourly_rate": "سعر العميل بالساعة",
    "motor_info": "معلومات المحرك",
    "model_name": "اسم النموذج",
    "passenger_info": "معلومات الركاب",
    "passenger_email": "البريد الإلكتروني للركاب",
    "country_code": "رمز البلد",
    "passenger_mobile": "موبايل الركاب",
    "passenger": "راكب",
    "corporate": "شركة كبرى",
    "passenger_list": "قائمة الركاب",
    "corporate_list": "قائمة الشركات",
    "select_passenger_or_corporate_details": "الرجاء تحديد تفاصيل الركاب أو الشركة",
    "guest_passenger": "راكب ضيف",
    "edit_passenger": "تحرير الركاب",
    "customer_details": "تفاصيل العميل",
    "personal_info": "المعلومات الشخصية",
    "name": "اسم",
    "phone": "هاتف",
    "clear_passenger": "مسح الركاب",
    "add_passenger": "إضافة راكب",
    "please_enter_name": "الرجاء إدخال الاسم",
    "please_enter_email": "الرجاء إدخال عنوان البريد الإلكتروني",
    "please_enter_valid_email": "يرجى إدخال عنوان بريد إلكتروني صالح",
    "please_enter_phone": "الرجاء إدخال رقم الهاتف",
    "guest_name": "اسم الضيف",
    "please_enter_guest_name": "الرجاء إدخال اسم الضيف",
    "guest_email": "البريد الإلكتروني للضيف",
    "guest_phone": "هاتف الضيف",
    "please_enter_guest_phone": "الرجاء إدخال هاتف الضيف",
    "stop_1": "توقف 1",
    "select_pickup_location": "الرجاء تحديد موقع الالتقاط",
    "select_drop_location": "الرجاء تحديد موقع الإسقاط",
    "enable_paste_map_link": "قم بتمكين هذا للصق رابط خريطة Google بدلاً من كتابة العنوان في موقع الالتقاط والتوصيل",
    "pickup_location_google_map": "موقع الالتقاء (رابط خريطة جوجل)",
    "drop_location_google_map": "إسقاط الموقع (رابط خريطة جوجل)",
    "stop": "توقف {{stopNumber}}",
    "previous": "سابق",
    "next": "التالي",
    "select_booking_type": "حدد نوع الحجز",
    "normal_booking": "الحجز العادي",
    "package_booking": "حجز الحزمة",
    "as_soon_as_possible": "في أسرع وقت ممكن",
    "now_booking_tooltip": "الحجز الآن",
    "later_booking_tooltip": "الحجز لاحقا",
    "now": "الآن",
    "please_select_date": "الرجاء تحديد التاريخ",
    "select_package_type": "حدد نوع الحزمة",
    "hours": "ساعات",
    "hrs": "ساعة",
    "days": "أيام",
    "minutes": "دقائق",
    "select_package": "حدد الحزمة",
    "please_select_valid_package_type": "الرجاء تحديد نوع الحزمة الصالحة",
    "km": "كم",
    "aed": "درهم",
    "please_select_valid_package": "الرجاء تحديد الحزمة الصالحة",
    "payment_type": "نوع الدفع",
    "select_payment_type": "حدد نوع الدفع",
    "cash": "نقدي",
    "bill": "فاتورة",
    "complimentary": "مجاني",
    "pos": "نقاط البيع",
    "select_valid_payment_method": "الرجاء تحديد طريقة دفع صالحة",
    "additional_elements": "عناصر إضافية",
    "custom_pricing": "التسعير المخصص",
    "payment_by": "الدفع عن طريق",
    "customer": "عميل",
    "note_to_driver_optional": "ملاحظة للسائق (اختياري)",
    "flight_number_optional": "رقم الرحلة (اختياري)",
    "reference_number_optional": "الرقم المرجعي (اختياري)",
    "promo_code_optional": "الرمز الترويجي (اختياري)",
    "assign_to_driver_optional": "التعيين للسائق (اختياري)",
    "note_to_admin_optional": "ملاحظة للمسؤول (اختياري)",
    "operational_supervisor_optional": "مشرف العمليات (اختياري)",
    "room_no_optional": "رقم الغرفة (اختياري)",
    "driver_share": "مشاركة السائق",
    "rsl_share": "مشاركة آر إس إل",
    "corporate_share": "حصة الشركات",
    "extra_charge": "رسوم إضافية",
    "extra_charge_error_message": "لا يمكن أن تكون الرسوم الإضافية أكبر من سعر العميل",
    "trip_duration": "مدة الرحلة",
    "remarks": "ملاحظات",
    "trip_type": "نوع الرحلة",
    "normal_trip": "رحلة عادية",
    "round_trip": "رحلة ذهابا وإيابا",
    "round_trip_fare": "أجرة الرحلة ذهابًا وإيابًا",
    "single": "أعزب",
    "double": "مزدوج",
    "do_join": "com.doJoin",
    "b2b": "B2B",
    "b2c": "B2C",
    "booking_status": "حالة الحجز",
    "all": "الجميع",
    "yet_to_start": "لم تبدأ بعد",
    "waiting_for_passenger": "في انتظار الراكب",
    "reassign": "إعادة التعيين",
    "driver_assigned": "تم تعيين السائق",
    "driver_on_the_way": "سائق في الطريق",
    "type_of_booking": "نوع الحجز",
    "later": "لاحقاً",
    "package": "طَرد",
    "booking_from": "الحجز من",
    "passenger_app": "تطبيق الركاب",
    "dispatcher_passenger": "المرسل - الركاب",
    "dispatcher_corporate": "المرسل - الشركات",
    "supervisor_app": "تطبيق المشرف",
    "walk_in_trip": "المشي في رحلة",
    "booking_from_new": "الحجز من جديد",
    "mobile_app": "تطبيق الجوال",
    "driver_trip": "رحلة السائق",
    "location": "موقع",
    "company": "شركة",
    "car_type": "نوع السيارة",
    "type_of_car": "نوع السيارة",
    "search_by_booking_or_group_id": "البحث عن طريق الحجز أو معرف المجموعة",
    "search_by_reference_number": "البحث بالرقم المرجعي",
    "search_without_date_range": "البحث بدون نطاق زمني",
    "search_with_date_range": "البحث مع النطاق الزمني",
    "to_date": "حتى الآن",
    "booking_id": "معرف الحجز",
    "type": "يكتب",
    "corporate_name": "اسم الشركة",
    "status": "حالة",
    "from": "من",
    "dojoin": "دوجوين",
    "custom_zone_name": "اسم المنطقة المخصصة",
    "driver": "سائق",
    "car_no": "رقم السيارة",
    "others": "آحرون",
    "assign_to_driver": "تعيين للسائق",
    "reference_code": "الرمز المرجعي",
    "note_to_admin": "ملاحظة للمسؤول",
    "estimated_customer_price": "السعر المقدر للعميل",
    "car_company": "شركة السيارات",
    "operational_supervisor": "المشرف التشغيلي",
    "staff_number": "رقم الموظفين",
    "room_number": "رقم الغرفة",
    "company_booking_type": "نوع حجز الشركة",
    "transfer": "تحويل",
    "full_day": "يوم كامل",
    "half_day": "نصف يوم",
    "assign_driver": "تعيين سائق",
    "unassign": "إلغاء التعيين",
    "no_trip": "لا توجد رحلة متاحة",
    "booking_cancellation": "إلغاء الحجز",
    "are_you_sure_you_want_to_cancel_this_booking": "هل أنت متأكد أنك تريد إلغاء هذا الحجز؟",
    "cancel_reason": "إلغاء السبب",
    "yes": "نعم",
    "no": "لا",
    "driver_unassign": "إلغاء تعيين برنامج التشغيل",
    "send_sms_to_driver": "إرسال الرسائل القصيرة إلى السائق",
    "send_whatsapp_to_corporate": "إرسال واتس اب للشركات",
    "are_you_sure_you_want_to_unassign": "هل أنت متأكد أنك تريد إلغاء التعيين؟",
    "unassign_reason": "إلغاء تعيين السبب",
    "trip_details": "تفاصيل الرحلة",
    "date_time_picker": "DateTimePicker",
    "check_in": "تحقق في",
    "check_out": "الدفع",
    "to": "ل",
    "outlined": "المبينة",
    "date_time_range": "النطاق الزمني للتاريخ",
    "date_time": "التاريخ والوقت",
    "time": "وقت",
    "save": "يحفظ",
    "payment_options": "خيارات الدفع",
    "select_payment_method": "اختر طريقة الدفع",
    "card": "بطاقة",
    "toggle_visibility": "تبديل الرؤية",
    "no_permission_to_change_fare": "ليس لديك إذن المشرف لتغيير الأجرة",
    "error_message_rsl_customer_price": "سعر العميل غير صالح",
    "extra_charge_error": "لا يمكن أن تكون الرسوم الإضافية أكبر من سعر العميل",
    "select_driver": "حدد برنامج التشغيل",
    "customer_rate_optional": "سعر العميل (اختياري)",
    "please_select_drop_location": "الرجاء تحديد موقع الإسقاط",
    "back": "خلف",
    "alert_message": "نجاح! لقد اكتمل الإجراء الخاص بك.",
    "choose_the_driver_from_list": "اختر برنامج التشغيل من القائمة",
    "driver_list": "قائمة السائقين",
    "rating_for_drivers": "تصنيف للسائقين",
    "accepted_driver": "سائق مقبول",
    "rejected_driver": "سائق مرفوض",
    "booking_info": "معلومات الحجز",
    "now_single_stop": "الآن (محطة واحدة)",
    "united_arab_emirates": "الإمارات العربية المتحدة الإمارات العربية المتحدة",
    "guest_info": "معلومات الضيف",
    "fare_details": "تفاصيل الأجرة",
    "base_fare": "الأجرة الأساسية",
    "distance_fare": "أجرة المسافة",
    "waiting_cost": "تكلفة الانتظار",
    "night_charge": "تهمة ليلية",
    "evening_charge": "تهمة المساء",
    "toll_fare": "أجرة الرسوم",
    "used_wallet_amount": "مبلغ المحفظة المستخدم",
    "promo_discount_amount": "مبلغ الخصم الترويجي",
    "actual_trip_fare": "أجرة الرحلة الفعلية",
    "payment_mode": "وضع الدفع",
    "trip_route": "طريق الرحلة",
    "driver_route": "طريق السائق",
    "trip_log": "سجل الرحلة",
    "driver_rejection_log": "سجل رفض السائق",
    "pickup_gmap": "يلتقط",
    "drop_gmap": "يسقط",
    "okay": "تمام",
    "are_you_sure_to_end_trip": "هل أنت متأكد من إنهاء الرحلة؟",
    "trip_in_progress": "الرحلة جارية",
    "waiting_for_payment": "في انتظار الدفع",
    "trip_start_time": "وقت بدء الرحلة",
    "trip": "رحلة",
    "booking_price": "سعر الحجز",
    "additional_booking_price": "سعر الحجز الإضافي",
    "car_number": "رقم السيارة",
    "end_trip": "نهاية الرحلة",
    "toggle_dropdown_visibility": "تبديل رؤية كلمة المرور",
    "parking_charge": "رسوم وقوف السيارات",
    "total_fare": "إجمالي الأجرة",
    "trip_end_time": "وقت نهاية الرحلة",
    "verify_status": "التحقق من الحالة",
    "verified": "تم التحقق منه",
    "need_to_verify": "بحاجة إلى التحقق",
    "phone_number": "رقم التليفون",
    "emirates_id": "معرف الإمارات",
    "dropoff": "الإنزال",
    "room_no": "رقم الغرفة",
    "Hrs": "ساعات",
    "mins": "دقيقة",
    "secs": "ثواني",
    "trip_distance": "مسافة الرحلة",
    "waiting_time": "وقت الانتظار",
    "past_trips_cancellation_fare": "أجرة إلغاء الرحلات الماضية",
    "trip_completed": "اكتملت الرحلة",
    "receipt": "إيصال",
    "verify": "يؤكد",
    "passenger_cancelled": "تم إلغاء الراكب",
    "dispatcher_cancelled": "تم إلغاء المرسل",
    "driver_cancelled": "تم إلغاء السائق",
    "cancellation_reason": "سبب الإلغاء",
    "no_show": "لا تظهر",
    "manage_custom_zone": "إدارة المنطقة المخصصة",
    "keyword": "الكلمة الرئيسية",
    "search_by_zone_name_location": "البحث حسب اسم المنطقة، موقع المنطقة",
    "status_update": "تحديث الحالة",
    "reason": "سبب",
    "please_enter_reason": "الرجاء إدخال السبب",
    "save_changes": "حفظ التغييرات",
    "add": "يضيف",
    "active": "نشيط",
    "block": "حاجز",
    "trash": "نفاية",
    "zone_name": "اسم المنطقة",
    "zone_location": "موقع المنطقة",
    "action": "فعل",
    "model_fare": "الأجرة النموذجية (إضافة/تعديل)",
    "driver_list_add_edit": "قائمة برامج التشغيل (إضافة/تحرير)",
    "push_notification": "دفع الإخطار",
    "custom_zone_add": "إضافة منطقة مخصصة",
    "custom_zone_edit": "تحرير المنطقة المخصصة",
    "zone_amount": "مبلغ المنطقة",
    "priority": "أولوية",
    "additional_information": "معلومات إضافية",
    "key": "مفتاح",
    "value": "قيمة",
    "remove_polygon": "إزالة المضلع",
    "add_polygon_required": "الرجاء إضافة المضلع، المضلع مطلوب!",
    "address": "عنوان",
    "customzone_fare": "أجرة المنطقة المخصصة",
    "minimum_kilometers": "الحد الأدنى من الكيلومترات",
    "minimum_fare": "الحد الأدنى للأجرة",
    "cancellation_fare": "أجرة الإلغاء",
    "below_above_kilometers": "أقل/فوق الكيلومترات",
    "below_kilometers": "أقل من كيلومترات",
    "above_kilometers": "فوق الكيلومترات",
    "fare_per_minute": "الأجرة في الدقيقة",
    "morning_charge": "تهمة الصباح",
    "morning_timing_from": "التوقيت الصباحي من",
    "evening_timing_from": "التوقيت المسائي من",
    "evening_timing_to": "التوقيت المسائي ل",
    "admin_commission": "اللجنة الادارية",
    "company_commission": "لجنة الشركة",
    "driver_commission": "لجنة السائق",
    "corporate_commission": "لجنة الشركات",
    "night_timing_from": "التوقيت الليلي من",
    "night_timing_to": "التوقيت الليلي ل",
    "no1": "لا",
    "yes1": "نعم",
    "custom_zone_drivers": "برامج تشغيل المنطقة المخصصة",
    "add_driver_custom_zone": "أضف برنامج التشغيل ضمن هذه المنطقة المخصصة",
    "zone_list": "قائمة المنطقة",
    "manage_offer_zone": "إدارة منطقة العرض",
    "offer_zone_add": "أضف منطقة العرض",
    "offer_zone_edit": "تحرير منطقة العرض",
    "manage_divisions": "إدارة الأقسام",
    "search_by_division_name": "البحث حسب اسم القسم",
    "division_name": "اسم القسم",
    "edit_division": "قسم التحرير",
    "add_division": "إضافة شعبة",
    "manage_departments": "إدارة الأقسام",
    "search_by_department_name": "البحث حسب اسم القسم",
    "department_name": "اسم القسم",
    "edit_department": "قسم التحرير",
    "add_department": "إضافة قسم",
    "division": "قسم",
    "invalid_email": "تنسيق البريد الإلكتروني غير صالح",
    "phone_must_7_digits": "رقم الهاتف يجب أن يكون 7 أرقام",
    "total_drivers": "إجمالي السائقين",
    "unassigned": "غير معين",
    "new_drivers": "برامج تشغيل جديدة",
    "drivers_shift_status": "حالة تحول السائقين",
    "shift_in": "التحول في",
    "shift_out": "التحول للخارج",
    "offline": "غير متصل",
    "sign_out": "تسجيل الخروج",
    "trip_assigned": "الرحلة المخصصة",
    "busy_in_trip": "مشغول في الرحلة",
    "driver_search": "البحث حسب الاسم والبريد الإلكتروني واسم الشركة والهاتف والمعرف الفريد",
    "previous_reason": "السبب السابق",
    "referral_poster": "ملصق الإحالة",
    "valid": "صالح",
    "expired": "منتهي الصلاحية!",
    "open": "يفتح",
    "driverInfo": "معلومات السائق",
    "driver_unique_id": "معرف فريد للسائق",
    "attached_vehicle": "المركبة المرفقة",
    "photo": "صورة",
    "driver_current_version": "برنامج التشغيل الإصدار الحالي",
    "location_status": "حالة الموقع",
    "created_date": "تاريخ الإنشاء",
    "expiry_status": "حالة انتهاء الصلاحية",
    "manage_driver_record": "إدارة سجل السائق",
    "profile_picture": "صورة الملف الشخصي",
    "created_date_and_time": "تاريخ الإنشاء ووقته",
    "taxi_no": "رقم التاكسي",
    "manufacturer_and_make_name": "الشركة المصنعة واسم الصنع",
    "device_type": "نوع الجهاز",
    "no_of_rows": "عدد الصفوف",
    "export_data": "تصدير البيانات",
    "add_driver": "إضافة سائق",
    "gender": "جنس",
    "male": "ذكر",
    "female": "أنثى",
    "please_enter_driver_unique_id": "الرجاء إدخال معرف السائق الفريد",
    "please_enter_number": "الرجاء إدخال رقم الجوال (7 أحرف على الأقل)",
    "state_error": "من فضلك اختر الولاية",
    "city_error": "من فضلك اختر المدينة",
    "additional_info": "معلومات إضافية",
    "company_error": "من فضلك قم باختيار شركة السيارات",
    "driver_license_id": "معرف رخصة القيادة",
    "driver_license_expiry_date": "تاريخ انتهاء رخصة القيادة",
    "rta_card_id": "معرف بطاقة هيئة الطرق والمواصلات",
    "rta_card_expiry_date": "تاريخ انتهاء بطاقة هيئة الطرق والمواصلات",
    "driver_national_id": "الهوية الوطنية للسائق",
    "driver_national_id_expiry_date": "تاريخ انتهاء الهوية الوطنية للسائق",
    "driver_iban_bank_account": "حساب بنكي للسائق IBAN",
    "bank_name": "اسم البنك",
    "please_select_expiry_date": "من فضلك، حدد تاريخ انتهاء الصلاحية",
    "please_enter_booking_limit": "الرجاء إدخال حد الحجز في اليوم الواحد",
    "attach_single_taxi": "إرفاق سيارة أجرة واحدة",
    "assign_multiple_taxi": "تعيين سيارات أجرة متعددة",
    "password_not_matched": "كلمة المرور غير متطابقة",
    "documents": "وثائق",
    "emirates_id_front_image": "الصورة الأمامية لبطاقة الهوية الإماراتية",
    "emirates_id_back_image": "الصورة الخلفية للهوية الإماراتية",
    "rta_card_front_image": "الصورة الأمامية لبطاقة هيئة الطرق والمواصلات",
    "rta_card_back_image": "الصورة الخلفية لبطاقة هيئة الطرق والمواصلات",
    "driving_license_front_image": "الصورة الأمامية لرخصة القيادة",
    "driving_license_back_image": "رخصة القيادة الصورة الخلفية",
    "edit_driver": "تحرير برنامج التشغيل",
    "view_banner": "عرض لافتة",
    "download": "تحميل",
    "download_vertically": "تحميل عموديا",
    "download_horizontally": "تحميل أفقيا",
    "newly_registered_drivers": "السائقين المسجلين حديثا",
    "waiting_for_approval": "في انتظار الموافقة",
    "approve": "يعتمد",
    "disapprove": "لا أوافق",
    "edit_cars": "تحرير السيارات",
    "disapprove_driver_confirmation": "هل أنت متأكد من رفض برنامج التشغيل هذا؟",
    "show_report_for": "عرض التقرير ل",
    "export_driver_status": "تصدير برنامج التشغيل حسب الحالة",
    "all_driver_records": "جميع سجلات السائق",
    "shift_in_driver_records": "التحول في سجلات السائق",
    "shift_out_driver_records": "تحويل سجلات السائق",
    "trip_assigned_driver_records": "سجلات السائق المخصصة للرحلة",
    "busy_in_trip_driver_records": "مشغول في سجلات سائق الرحلة",
    "sign_out_driver_records": "تسجيل الخروج من سجلات السائق",
    "offline_driver_records": "سجلات السائق غير متصل",
    "manage_loss_found_logs_records": "إدارة سجلات فقدان/العثور على السجلات",
    "comments": "تعليقات",
    "driver_id": "معرف السائق",
    "subject": "موضوع",
    "description": "وصف",
    "image": "وثيقة",
    "logs": "سجلات",
    "no_logs": "لا سجلات",
    "select_status": "اختر الحالة",
    "approved": "موافقة",
    "rejected": "مرفوض",
    "please_select_status": "الرجاء تحديد الحالة",
    "enter_your_commands": "أدخل الأوامر الخاصة بك",
    "driver_document_request_record": "سجل طلب مستند السائق",
    "search_by_model_name": "البحث حسب اسم الموديل",
    "add_model": "إضافة نموذج",
    "edit_model": "تحرير النموذج",
    "enter_model_name": "أدخل اسم النموذج.",
    "model_size": "حجم النموذج",
    "enter_model_size": "أدخل حجم النموذج.",
    "enter_base_fare": "أدخل السعر الأساسي",
    "min_km": "الحد الأدنى كم",
    "min_km_error": "أدخل الحد الأدنى من الكيلومترات بتنسيق رقمي (على سبيل المثال: 50,60)",
    "min_fare": "الحد الأدنى للأجرة",
    "min_fare_error": "أدخل الحد الأدنى للأجرة بتنسيق رقمي (على سبيل المثال: 50,60.45)",
    "cancellation_fee": "رسوم الإلغاء",
    "below_above_km": "نطاق الكيلومترات أدناه وفوقها",
    "below_km_fare": "سعر أقل من الكيلومتر (لكل كيلومتر)",
    "above_km_fare": "سعر أعلى من الكيلومترات (لكل كيلومتر)",
    "waiting_charge": "رسوم انتظار السيارة (لكل ساعة)",
    "taxi_speed": "سرعة التاكسي",
    "taxi_min_speed": "الحد الأدنى لسرعة سيارات الأجرة",
    "car_waiting_charge": "رسوم انتظار السيارة",
    "enter_cancellation_fee": "أدخل رسوم الإلغاء بتنسيق رقمي (على سبيل المثال، 30، 34.56)",
    "enter_below_above_km": "أدخل النطاق أدناه وفوق KM بتنسيق رقمي (على سبيل المثال، 50)",
    "enter_below_km_fare": "أدخل سعر الكيلومتر أدناه بتنسيق رقمي (على سبيل المثال، 7، 6.50)",
    "enter_above_km_fare": "أدخل سعر الكيلومتر المذكور أعلاه بتنسيق رقمي (على سبيل المثال، 12، 15.50)",
    "enter_waiting_charge": "أدخل رسوم انتظار السيارة",
    "enter_fare_per_minute": "أدخل الأجرة في الدقيقة",
    "enter_taxi_speed": "أدخل سرعة السيارة في الساعة",
    "enter_taxi_min_speed": "أدخل الحد الأدنى للأجرة بتنسيق رقمي (على سبيل المثال، 50، 60.45)",
    "above_km": "سعر أعلى من الكيلومترات (لكل كيلومتر)",
    "minutes_fare": "الأجرة في الدقيقة",
    "night_fare": "أجرة الليل",
    "evening_fare": "أجرة المساء",
    "enter_car_waiting_charge": "أدخل رسوم انتظار السيارة",
    "android_model_unfocus_image": "صورة غير مركزة لنموذج Android (175 × 100 بكسل)",
    "ios_model_focus_image": "صورة التركيز لنموذج iOS (125 × 71 بكسل)",
    "ios_model_unfocus_image": "صورة غير مركزة لنموذج iOS (125 × 71 بكسل)",
    "location_model_image": "صورة نموذج الموقع (418 × 183 بكسل)",
    "taxi_number": "رقم التاكسي",
    "car_manufacturer_name": "اسم الشركة المصنعة للسيارة",
    "car_make_name": "اسم صنع السيارة",
    "total_cars": "مجموع السيارات",
    "search_by_taxi_no_company_name": "البحث عن طريق رقم التاكسي، اسم الشركة",
    "car_model_year": "سنة طراز السيارة",
    "car_owner_name": "اسم مالك السيارة",
    "car_color": "لون السيارة",
    "taxi_motor_expiry_date": "تاريخ انتهاء صلاحية سيارة الأجرة",
    "add_car": "أضف سيارة",
    "edit_car": "تحرير السيارة",
    "taxi_unique_id": "معرف تاكسي فريد",
    "taxi_insurance_number": "رقم تأمين سيارة الأجرة",
    "car_insurance_expiry_date": "تاريخ انتهاء تأمين السيارة",
    "car_chassis_number": "رقم هيكل السيارة",
    "please_select_state": "الرجاء تحديد الدولة",
    "please_select_city": "الرجاء اختيار المدينة",
    "car_image_340_pixel": "صورة السيارة (340 × 183 بكسل)",
    "select_car_company": "الرجاء اختيار شركة السيارات",
    "please_select_country": "الرجاء اختيار البلد",
    "car": "سيارة",
    "manage_assigned_car_records": "إدارة سجل السيارات المخصصة",
    "search_by_driver_name": "البحث حسب اسم السائق",
    "active_assigned_cars": "Active_AssignedCars",
    "block_assigned_cars": "Block_AssignedCars",
    "trash_assigned_cars": "Trash_AssignedCars",
    "unassigned_assigned_cars": "UnAssigned_AssignedCars",
    "all_assigned_cars": "All_AssignedCars",
    "total_subscription_list": "إجمالي قائمة الاشتراك",
    "requested_date": "التاريخ المطلوب",
    "subscribed_date": "تاريخ الاشتراك",
    "unsubscribe_cancel_date": "إلغاء الاشتراك/إلغاء التاريخ",
    "duration_days_hours_minutes_seconds": "المدة (أيام ساعات دقائق ثواني)",
    "subscription_status": "حالة الاشتراك",
    "user": "مستخدم",
    "subscription_amount": "مبلغ الاشتراك",
    "log_count": "عدد السجل",
    "total_amount": "المبلغ الإجمالي",
    "total_active": "إجمالي النشاط",
    "total_subscribe": "إجمالي الاشتراك",
    "total_unsubscribe": "إجمالي إلغاء الاشتراك",
    "add_driver_to_subscription": "إضافة سائق إلى الاشتراك",
    "choose_driver_to_subscribe": "اختر برنامج التشغيل للاشتراك",
    "request_subscription": "طلب الاشتراك",
    "activate_subscription": "تفعيل الاشتراك",
    "subscription_setting": "إعداد الاشتراك",
    "auto": "آلي",
    "manual": "يدوي",
    "content": "محتوى",
    "hold": "يمسك",
    "activate": "فعل",
    "date_filter_type": "نوع مرشح التاريخ",
    "search_taxi_number": "البحث عن طريق رقم التاكسي",
    "reason_for_hold_subscription": "سبب تعليق الاشتراك",
    "reason_for_approve_subscription": "سبب الموافقة على الاشتراك",
    "reason_for_cancel_subscription": "سبب الغاء الاشتراك",
    "other_drivers": "السائقين الآخرين",
    "subscription_date": "تاريخ الاشتراك",
    "unsubscribe_date": "تاريخ إلغاء الاشتراك",
    "duration": "مدة",
    "all_taxi_subscription_records": "جميع سجلات الاشتراك في سيارات الأجرة",
    "active_taxi_subscription_records": "سجلات الاشتراك في سيارات الأجرة النشطة",
    "subscribed_taxi_subscription_records": "سجلات الاشتراك في سيارات الأجرة المشتركة",
    "unsubscribed_taxi_subscription_records": "سجلات الاشتراك في سيارات الأجرة غير المشتركة",
    "cancelled_taxi_subscription_records": "سجلات اشتراك سيارات الأجرة الملغاة",
    "hold_taxi_subscription_records": "عقد سجلات الاشتراك في سيارات الأجرة",
    "pending_taxi_subscription_records": "في انتظار سجلات الاشتراك في سيارات الأجرة",
    "other_drivers_taxi_subscription_records": "سجلات الاشتراك في سيارات الأجرة للسائقين الآخرين",
    "manufacturer_list": "قائمة الشركة المصنعة",
    "edit_manufacturer": "تحرير الشركة المصنعة",
    "add_manufacturer": "إضافة الشركة المصنعة",
    "car_make_list": "قائمة صنع السيارة",
    "search_by_car_make_name": "البحث حسب اسم ماركة السيارة...",
    "edit_car_make": "تحرير صنع السيارة",
    "add_car_make": "أضف ماركة السيارة",
    "upload_car_make_image": "تحميل صورة صنع السيارة",
    "car_make_image_allowed_format": "التنسيقات المسموح بها: .jpeg، .jpg، .png، .webp",
    "download_document": "تنزيل الوثيقة",
    "total_company": "شركة توتال",
    "manage_company_record": "إدارة سجل الشركة",
    "search_by_company_name_user_name_email": "البحث حسب اسم الشركة واسم المستخدم ومعرف البريد الإلكتروني",
    "no_of_cars": "عدد السيارات",
    "no_of_drivers": "عدد السائقين",
    "add_company": "أضف شركة",
    "personal_information": "معلومات شخصية",
    "last_name": "اسم العائلة",
    "company_image": "صورة الشركة (340 × 183 بكسل)",
    "choose_theme": "اختر الموضوع",
    "primary_color": "اللون الأساسي *",
    "secondary_color": "اللون الثانوي *",
    "primary_color_required": "اللون الأساسي مطلوب",
    "secondary_color_required": "اللون الثانوي مطلوب",
    "document_required": "الوثيقة مطلوبة",
    "shift_timing_from": "توقيت التحول من",
    "shift_timing_to": "توقيت التحول إلى",
    "shift_hours": "ساعات التحول",
    "edit_company": "تحرير الشركة",
    "company_info": "معلومات الشركة",
    "company_car": "سيارة الشركة",
    "company_driver": "سائق الشركة",
    "promo_code_discount": "خصم الرمز الترويجي (%)",
    "start_date": "تاريخ البدء",
    "expiry_date": "تاريخ انتهاء الصلاحية",
    "mobile_app_visibility": "رؤية تطبيقات الجوال",
    "promocode_limit": "حد الرمز الترويجي",
    "offer_type": "نوع العرض",
    "promocode_generation_type": "نوع إنشاء الرمز الترويجي",
    "start_date_from": "تاريخ البدء من",
    "start_date_to": "تاريخ البدء إلى",
    "expiry_date_from": "تاريخ انتهاء الصلاحية من",
    "expiry_date_to": "تاريخ انتهاء الصلاحية إلى",
    "search_by_promocode": "البحث عن طريق الرمز الترويجي",
    "manage_promocode_record": "إدارة سجل الرمز الترويجي",
    "promocode": "الرمز الترويجي",
    "promocode_discount": "خصم الرمز الترويجي",
    "enabled": "ممكّن",
    "disabled": "عاجز",
    "promocode_flow": "تدفق الرمز الترويجي",
    "lifestyle_promotion_flow": "تدفق الترويج لأسلوب الحياة",
    "dynamic": "متحرك",
    "used_promocode_count": "عدد الرموز الترويجية المستخدمة",
    "unused_promocode_count": "عدد الرموز الترويجية غير المستخدمة",
    "add_promocode": "أضف الرمز الترويجي",
    "select": "يختار",
    "all_passenger": "جميع الركاب",
    "select_passenger": "حدد الراكب",
    "new_passengers": "الركاب الجدد",
    "categories": "فئات",
    "limit_per_passenger": "الحد لكل راكب",
    "promocode_maximum_limit": "الحد الأقصى للرمز الترويجي",
    "discount_percentage": "نسبة مئوية",
    "discount_flat": "مستوي",
    "ticket": "تذكرة",
    "discount_type": "نوع الخصم",
    "percentage": "نسبة مئوية",
    "flat": "مستوي",
    "ticket_count": "عدد التذاكر",
    "discount_in_percent": "الخصم بنسبة %",
    "maximum_discount": "الحد الأقصى للخصم",
    "flat_amount": "المبلغ الثابت",
    "select_activity_packages": "حدد حزم الأنشطة",
    "select_staycation_packages": "حدد باقات الإقامة",
    "select_bus_rental_packages": "حدد باقات تأجير الحافلات",
    "select_holipackages": "حدد باقات العطلات",
    "select_car_rental_packages": "حدد باقات تأجير السيارات",
    "select_packages": "حدد الحزم",
    "offer_for_other_services": "عرض للخدمات الأخرى *",
    "offer_zone": "منطقة العرض *",
    "min_KM": "دقيقة كم",
    "max_km": "ماكس كم",
    "edit_promocode": "تحرير الرمز الترويجي",
    "view_dynamic_promocode": "عرض سجل الرمز الترويجي الديناميكي",
    "dynamic_promocode_history": "تاريخ الرموز الترويجية الديناميكية",
    "user_details": "تفاصيل المستخدم",
    "not_used": "غير مستخدم",
    "used": "مستخدم",
    "total_corporate": "إجمالي الشركات",
    "manage_corporate_record": "إدارة سجل الشركة",
    "search_by_name_email": "البحث بالاسم والبريد الإلكتروني",
    "add_edit_zone_group": "إضافة/تحرير مجموعة المنطقة",
    "zone_group_fare_mapping_new": "تحديد أسعار مجموعة المناطق الجديدة (نوع السيارة)",
    "active_corporates": "الشركات النشطة",
    "block_corporates": "منع الشركات",
    "trash_corporates": "شركات القمامة",
    "all_corporates": "جميع الشركات",
    "add_corporate": "أضف شركة",
    "edit_corporate": "تحرير الشركة",
    "fare_visibility": "رؤية الأجرة للسائق",
    "fare_settings": "إعدادات الأجرة",
    "zone_to_zone_fare": "منطقة إلى منطقة الأجرة",
    "company_fare": "أجرة الشركة",
    "trade_license_image": "صورة الرخصة التجارية (340 × 183 بكسل)",
    "corporate_logo": "شعار الشركة (340 × 183 بكسل)",
    "contract_document": "وثيقة العقد",
    "below_above_km_range": "أقل وأعلى من نطاق الكيلومتر",
    "file_allowed_format": "التنسيقات المسموح بها .jpeg,.jpg,.png,.pdf,.doc,.docx",
    "img_allowed_format": "التنسيقات المسموح بها .jpeg،.jpg،.png",
    "do_you_want_to_add_new_password": "هل تريد إضافة كلمة مرور جديدة؟",
    "password_cannot_be_empty": "لا يمكن أن تكون كلمة المرور فارغة",
    "download_trade_license": "تحميل الرخصة التجارية",
    "download_contract_document": "تحميل وثيقة العقد",
    "inspection_alert_duration": "مدة تنبيه التفتيش",
    "inspection_setting": "إعداد التفتيش",
    "duration_cannot_be_zero": "لا يمكن أن تكون المدة 0",
    "manage_zone_group": "إدارة مجموعة المنطقة",
    "search_by_zone_group_name": "البحث حسب اسم مجموعة المنطقة",
    "manage_zone_group_record": "إدارة سجل مجموعة المنطقة",
    "zone_group_name": "اسم مجموعة المنطقة",
    "zone_names": "أسماء المناطق",
    "add_zone_group": "إضافة مجموعة المنطقة",
    "add_zone_under_this_group": "أضف منطقة ضمن مجموعة المناطق هذه",
    "edit_zone_group": "تحرير مجموعة المنطقة",
    "active_zone_groups": "مجموعات المنطقة النشطة",
    "block_zone_groups": "مجموعات منطقة الحظر",
    "trash_zone_groups": "مجموعات منطقة المهملات",
    "all_zone_groups": "جميع مجموعات المنطقة",
    "corporate_amount": "مبلغ الشركة",
    "driver_amount": "مبلغ السائق",
    "company_amount": "مبلغ الشركة",
    "admin_amount": "المبلغ الإداري",
    "end_passenger_amount": "نهاية مبلغ الركاب",
    "remove_confirmation": "هل أنت متأكد أنك تريد الإزالة؟",
    "delete_confirmation": "هل أنت متأكد أنك تريد الحذف؟",
    "zone_group_fare_mapping": "رسم خرائط أسعار مجموعة المناطق (نوع السيارة)",
    "zone_from": "المنطقة من",
    "zone_to": "المنطقة إلى",
    "zone_group_from": "مجموعة المنطقة من",
    "zone_group_to": "منطقة المجموعة ل",
    "remove": "يزيل",
    "corporate_id": "معرف الشركة",
    "package_type": "نوع الحزمة",
    "add_package": "أضف الحزمة",
    "package_hrs_days": "ساعات/أيام الحزمة",
    "package_hours_days": "ساعات/أيام الحزمة",
    "search_by_zone_name": "البحث حسب اسم المنطقة",
    "package_distance": "مسافة الحزمة",
    "package_amount": "مبلغ الحزمة",
    "edit_zone": "تحرير المنطقة",
    "add_zone": "أضف منطقة",
    "zone_name_required": "اسم المنطقة مطلوب",
    "polygon_required": "الرجاء إضافة المضلع، المضلع مطلوب!",
    "active_zones": "المناطق النشطة",
    "block_zones": "مناطق الحظر",
    "trash_zones": "مناطق القمامة",
    "all_zones": "جميع المناطق",
    "update_wallet_amount": "تحديث مبلغ المحفظة",
    "amount": "كمية",
    "enter_amount": "الرجاء إدخال المبلغ",
    "enter_password": "الرجاء إدخال كلمة المرور",
    "enter_comments": "الرجاء إدخال التعليقات",
    "manage_passengers_record": "إدارة سجل الركاب",
    "active_passengers": "الركاب النشطين",
    "block_passengers": "منع الركاب",
    "trash_passengers": "ركاب القمامة",
    "all_passengers": "جميع الركاب",
    "wallet_amount": "مبلغ المحفظة",
    "user_information": "معلومات المستخدم",
    "otp_count": "عدد OTP",
    "reset_otp_count": "إعادة تعيين عدد OTP",
    "reset_otp_count_confirmation": "هل أنت متأكد أنك تريد إعادة تعيين عدد OTP؟",
    "package_additional_fare_per_km": "السعر الإضافي للباقة (لكل كيلومتر)",
    "package_additional_fare_per_hrs": "سعر الباقة الإضافية (لكل ساعة)",
    "short_description": "وصف قصير",
    "long_description": "وصف طويل",
    "edit_package": "تحرير الحزمة",
    "multiple_dropoff_locations_add_edit": "مواقع التسليم المتعددة (إضافة/تعديل)",
    "customize_fare": "تخصيص الأجرة",
    "total_location": "الموقع الإجمالي",
    "manage_location_record": "إدارة سجل الموقع",
    "search_by_name_email_company": "البحث حسب الاسم والبريد الإلكتروني واسم الشركة",
    "active_location": "الموقع النشط",
    "block_location": "حظر الموقع",
    "trash_location": "موقع سلة المهملات",
    "all_location": "جميع المواقع",
    "edit_location": "تحرير الموقع",
    "add_location": "أضف الموقع",
    "address_is_required": "العنوان مطلوب",
    "location_name": "اسم الموقع",
    "queue_limit": "حد قائمة الانتظار",
    "drop_your_message": "أسقط رسالتك",
    "commission_type": "نوع العمولة",
    "subscription": "الاشتراك",
    "polygon_restriction": "تقييد المضلع",
    "enable": "يُمكَِن",
    "disable": "إبطال",
    "cancel_password_required": "إلغاء كلمة المرور *",
    "cancel_password": "إلغاء كلمة المرور",
    "cancel_password_empty": "إلغاء كلمة المرور لا يمكن أن تكون فارغة",
    "confirm_cancel_password_required": "تأكيد إلغاء كلمة المرور *",
    "confirm_cancel_password": "تأكيد إلغاء كلمة المرور",
    "driver_limit_start_supervisor_trip": "حد السائق لبدء رحلة المشرف",
    "location_type": "نوع الموقع",
    "general": "عام",
    "hotel": "الفندق",
    "target_trip_count": "عدد الرحلات المستهدفة",
    "restrict_driver_remove_from_queue": "تقييد إزالة برنامج التشغيل من قائمة الانتظار",
    "restriction_hours": "ساعات التقييد",
    "location_fare_edit": "تحرير أجرة الموقع",
    "quick_trip_option_in_supervisor_app": "خيار الرحلة السريعة في تطبيق المشرف",
    "driver_app_visibility": "رؤية تطبيق السائق",
    "add_driver_to_queue": "إضافة سائق إلى قائمة الانتظار",
    "queue_alert_notification": "إشعار تنبيه قائمة الانتظار",
    "queue_alert_notification_time": "وقت إشعار تنبيه قائمة الانتظار (بالدقائق)",
    "open_timing_from": "فتح التوقيت من",
    "open_timing_to": "فتح التوقيت ل",
    "get_ready_queue_reorder_time_in_minutes": "الاستعداد لوقت إعادة ترتيب قائمة الانتظار (بالدقائق)",
    "document_verification": "التحقق من الوثيقة",
    "verification_type": "نوع التحقق",
    "auto_verify": "التحقق التلقائي",
    "manual_verify": "التحقق اليدوي",
    "waiting_queue_n_drivers_to_reorder": "قائمة انتظار الانتظار - عدد N من برامج التشغيل المطلوب إعادة ترتيبها",
    "n_drivers_update": "عدد N من برامج التشغيل (الموقع الخارجي) لتحديث الوقت الحالي",
    "waiting_queue_time_in_minutes": "قائمة الانتظار - الوقت بالدقائق لإعادة الطلب",
    "driver_time_update": "الوقت (بالدقائق - برامج التشغيل - الموقع الخارجي) لتحديث وقت التاريخ الحالي",
    "queue_setting": "إعداد قائمة الانتظار",
    "manual_setting": "يدوي - يمكن للمشرف فقط إضافة سائق إلى قائمة الانتظار",
    "driver_add_to_queue_restriction": "سائق إضافة إلى تقييد قائمة الانتظار",
    "multiple": "عديد",
    "location_driver_details": "تفاصيل سائق الموقع",
    "add_driver_under_location": "أضف سائقًا ضمن منطقة الموقع هذه",
    "location_list": "قائمة الموقع",
    "notes": "ملحوظات",
    "multiple_dropoff_locations": "مواقع الإنزال المتعددة",
    "latitude": "خط العرض *",
    "longitude": "خط الطول *",
    "fare": "الأجرة *",
    "peak_hour_fare": "أجرة ساعة الذروة",
    "contract_amount": "مبلغ العقد",
    "location_customized_fare": "أجرة الموقع المخصصة",
    "models": "نماذج",
    "queue_location_list": "قائمة مواقع الانتظار",
    "main_queue": "قائمة الانتظار الرئيسية",
    "waiting_queue": "طابور الانتظار",
    "queue_location_list_label": "قائمة مواقع الانتظار",
    "model_list": "قائمة النماذج",
    "note_model_filter": "ملاحظة: لا يمكنك إعادة ترتيب قائمة الانتظار إذا قمت بتطبيق عامل تصفية النموذج",
    "car_plate_number": "رقم لوحة السيارة",
    "confirm_queue_change": "هل أنت متأكد أنك تريد التغيير إلى",
    "confirm_remove_driver": "هل أنت متأكد من إزالة برنامج التشغيل من قائمة الانتظار؟",
    "group_name": "اسم المجموعة",
    "kiosk_list": "قائمة الكشك",
    "sunday": "الأحد",
    "monday": "الاثنين",
    "tuesday": "يوم الثلاثاء",
    "wednesday": "الأربعاء",
    "thursday": "يوم الخميس",
    "friday": "جمعة",
    "saturday": "السبت",
    "count": "عدد",
    "count_exceeded": "لا يمكن أن يمتد عدد الإشعارات لأكثر من",
    "location_queue_driver_logs_record": "سجل سجلات سائق قائمة انتظار الموقع",
    "queue_position": "موقف قائمة الانتظار",
    "supervisor_name": "إسم المشرف",
    "supervisor_unique_id": "معرف المشرف الفريد",
    "added_from": "تمت الإضافة من",
    "search_by_toll_name": "البحث عن طريق اسم الرقم",
    "manage_toll_record": "إدارة سجل حصيلة",
    "toll_name": "اسم الرسوم",
    "toll_location": "موقع حصيلة",
    "toll_amount": "مبلغ الرسوم",
    "add_toll": "إضافة حصيلة",
    "edit_toll": "تحرير الرسوم",
    "toll_location_is_required": "موقع الرسوم مطلوب",
    "manage_supervisor": "إدارة المشرف",
    "manage_supervisor_record": "إدارة سجل المشرف",
    "active_supervisors_report": "تقرير المشرفين النشطين",
    "block_supervisors_report": "تقرير المشرفين المحظورين",
    "trash_supervisors_report": "تقرير المشرفين على سلة المهملات",
    "all_supervisors_report": "تقرير جميع المشرفين",
    "edit_supervisor": "مشرف التحرير",
    "add_supervisor": "إضافة مشرف",
    "supervisor_details": "تفاصيل المشرف",
    "view_supervisor": "شاهد المشرف",
    "send_push_notification": "إرسال إشعار الدفع",
    "supervisor_not_login_error": "يبدو أن هذا المشرف لم يسجل الدخول!",
    "supervisor_monitor_list": "قائمة مراقبة المشرف",
    "push_id": "معرف الدفع",
    "video_link": "رابط الفيديو",
    "updated_date": "تاريخ التحديث",
    "please_enter_remarks": "الرجاء إدخال الملاحظات",
    "supervisor_login_time": "وقت دخول المشرف",
    "supervisor_logout_time": "وقت خروج المشرف",
    "supervisor_login_location": "موقع دخول المشرف",
    "supervisor_logout_location": "موقع تسجيل خروج المشرف",
    "login_image": "صورة تسجيل الدخول",
    "logout_image": "صورة الخروج",
    "shift_in_duration_hh_mm_ss": "مدة التحول (س:د:س:ث ث)",
    "login_duration": "مدة تسجيل الدخول (س:د:د:ث س)",
    "select_columns_you_need": "حدد الأعمدة التي تحتاجها",
    "export_selected_data": "تصدير البيانات المحددة",
    "remove_all": "إزالة الكل",
    "select_all": "حدد الكل",
    "alert": "يُحذًِر",
   "employee_id":" معرف الموظف",
   "rsl_number":" رقم آر إس إل",
   "log_date":"تاريخ السجل",
   "taxi_subscription_logs":"سجلات الاشتراك في سيارات الأجرة"


};

export default ar;
