/* eslint-disable prettier/prettier */


// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  "dashboard": "仪表板",
  "nds": "国家统计局",
  "dispatch_map": "调度地图",
  "orders": "订单",
  "manage_permission": "管理权限",
  "manage_handover": "管理移交",
  "custom_zone": "定制区",
  "lifestyle_offer_zone": "生活方式优惠区",
  "divisions": "部门",
  "departments": "部门",
  "drivers": "司机",
  "manage_drivers": "管理司机",
  "manage_new_drivers": "管理新司机",
  "export_driver_list": "导出驱动程序列表",
  "manage_lost_found_logs": "管理丢失/找到日志",
  "driver_document_request": "司机文件请求",
  "cars": "汽车",
  "manage_models": "管理模型",
  "manage_cars": "管理汽车",
  "assign_car": "分配汽车",
  "manage_assigned_cars": "管理指定车辆",
  "taxi_subscription_list": "出租车订购列表",
  "car_manufacturer": "汽车制造商",
  "car_make": "汽车制造商",
  "companies": "公司",
  "manage_company": "管理公司",
  "manage_promocode": "管理促销代码",
  "corporates": "企业",
  "manage_corporate": "管理企业",
  "corporate_packages": "企业套餐",
  "manage_zone": "管理区",
  "zone_map_view": "区域地图视图",
  "passengers": "乘客",
  "manage_passengers": "管理乘客",
  "passenger_packages": "旅客套票",
  "locations": "地点",
  "known_locations": "已知地点",
  "known_location_country": "管理已知位置国家/地区",
  "manage_category": "管理类别",
  "manage_known_loc": "管理已知位置",
  "manage_location": "管理位置",
  "manage_queue_location": "管理队列位置",
  "location_group": "地点组",
  "location_log": "位置日志",
  "location_queue_driver_log": "位置队列驱动程序日志",
  "tolls": "通行费",
  "manage_tolls": "管理通行费",
  "supervisor": "导师",
  "supervisor_trip_reports": "主管旅行报告",
  "supervisor_logs": "主管日志",
  "discount": "折扣",
  "mobile_promotion": "移动推广",
  "marketing": "营销",
  "lifestyle_location": "生活方式 地点",
  "manage_referral_settings": "管理推荐设置",
  "reports": "报告",
  "driver_shift_hours": "司机轮班时间",
  "settlement": "沉降",
  "driver_status": "驾驶员状态",
  "exit_record": "退出记录",
  "geo_report": "地理报告",
  "location_queue_driver_logs": "位置队列驱动程序日志",
  "all_other_reports": "所有其他报告",
  "driver_rejection_report": "司机拒绝报告",
  "trip_reports": "旅行报告",
  "all_transactions": "所有交易",
  "completed_transactions": "已完成交易",
  "cancelled_transactions": "取消的交易",
  "account_reports": "账户报告",
  "activity_logs": "活动日志",
  "historical_data": "史料",
  "settings": "设置",
  "site_settings": "站点设置",
  "sms_templates": "短信模板",
  "admin_smtp_settings": "管理 SMTP 设置",
  "company_smtp_settings": "公司 SMTP 设置",
  "admin_sms_settings": "管理员短信设置",
  "company_sms_settings": "公司短信设置",
  "manage_country": "管理国家",
  "manage_state": "管理状态",
  "manage_city": "管理城市",
  "manage_social_network": "管理社交网络",
  "manage_google_settings": "管理 Google 设置",
  "rider": "骑士",
  "this_report_displays_the_total_driver_shift_hours_for_the_selected_day": "此报告显示所选日期的驾驶员轮班总小时数！",
  "select_company": "选择公司",
  "total_active_hours": "总活跃时间",
  "active_drivers": "主动驱动程序",
  "total_vehicle": "车辆总数",
  "total_driver": "总驱动力",
  "total_shift_in_hours": "总轮班时间",
  "total_shift_out_hours": "总轮班时间",
  "shift_list": "轮班表",
  "export": "出口",
  "search": "搜索",
  "device_id": "设备 ID",
  "unique_id": "唯一ID",
  "driver_name": "司机姓名",
  "vehicle_model": "车辆型号",
  "Company": "公司",
  "engine_hours": "发动机运转时间",
  "idle_hours": "空闲时间",
  "shift_in_hours": "小时班次",
  "shift_out_hours": "轮班时间",
  "rows_per_page": "每页行数",
  "driver_info": "司机信息",
  "general_info": "一般信息",
  "gps": "全球定位系统",
  "software_info": "软件信息",
  "social": "社会的",
  "view_history": "查看历史记录",
  "from_date": "开始日期",
  "end_date": "结束日期",
  "cancel": "取消",
  "shift_reports": "轮班报告",
  "fare_update": "票价更新",
  "update": "更新",
  "no_data_found": "没有找到数据",
  "data_not_found": "未找到数据",
  "file_upload": "文件上传",
  "password": "密码",
  "edit": "编辑",
  "passenger_name": "乘客姓名",
  "trip_fare": "行程票价",
  "emirate_id": "酋长国身份证",
  "passenger_phone_number": "乘客电话号码",
  "free": "自由的",
  "s_no": "序列号",
  "busy": "忙碌的",
  "date": "日期",
  "log_in_time": "登录时间",
  "shift_in_duration": "持续时间转变",
  "shift_out_duration": "移出持续时间",
  "inmove": "因莫夫",
  "idle": "闲置的",
  "lat_lng": "拉丁语",
  "accuracy": "准确性",
  "altitude": "高度",
  "bearing": "轴承",
  "speed": "速度",
  "last_update": "最后更新",
  "profile": "轮廓",
  "android": "安卓",
  "ios": "iOS系统",
  "user_profile_management": "用户档案管理",
  "ok": "好的",
  "change_password": "更改密码",
  "new_password": "新密码",
  "confirm_password": "确认密码",
  "please_enter_new_password": "请输入新密码",
  "please_enter_confirm_password": "请输入确认密码",
  "submit": "提交",
  "reset": "重置",
  "view_more": "查看更多",
  "driver_information": "司机信息",
  "first_name": "名",
  "email": "电子邮件",
  "mobile_number": "手机号码",
  "otp": "一次性TP",
  "date_of_birth": "出生日期",
  "booking_limit_per_day": "每日预订限额",
  "rating_points": "评级点",
  "out_of": "出",
  "referral_code": "推荐码",
  "my_current_location": "我当前的位置",
  "company_name": "公司名称",
  "company_information": "公司资料",
  "company_address": "公司地址",
  "country": "国家",
  "state": "状态",
  "city": "城市",
  "i_am_free_online": "我有空 - 在线",
  "shift_out_offline": "移出 - 离线",
  "i_am_busy_in_trip": "我忙着旅行",
  "signed_out": "已退出",
  "trip_id": "行程编号",
  "logout": "退出",
  "refresh": "刷新",
  "last_updated": "最后更新",
  "shift_history": "班次历史记录",
  "show_chart": "显示图表",
  "show_list": "显示列表",
  "referral_history": "推荐历史",
  "total_earned": "总收入",
  "pending": "待办的",
  "completed_journeys": "已完成的旅程",
  "current_month": "本月",
  "previous_month": "上个月",
  "mail_settings": "邮件设置",
  "send_mail": "发送邮件",
  "trip_reports_title": "旅行报告（已完成计数）",
  "subscription_reports_title": "订阅报告",
  "driver_shift_reports_title": "驾驶员换班报告",
  "location_queue_title": "位置队列（驱动程序计数）",
  "email_settings": "电子邮件设置",
  "emails": "电子邮件",
  "emails_helper_text": "电子邮件应类似于 abc@gmail.com,xyz@gmail.com",
  "time_to_send": "发送电子邮件的时间",
  "start_time": "开始时间",
  "this_field_is_required": "此字段是必需的",
  "success": "成功",
  "error_message": "发生意外错误。",
  "mobile_b2c_now_booking": "移动 B2C - 立即预订",
  "mobile_b2c_later_booking": "移动 B2C - 稍后预订",
  "admin_b2c_now_booking": "管理员 - B2C 立即预订",
  "admin_b2c_later_booking": "管理员 - B2C 稍后预订",
  "admin_b2b_now_booking": "管理员 - B2B 立即预订",
  "admin_b2b_later_booking": "管理员 - B2B 稍后预订",
  "corporate_b2b_now_booking": "企业 - B2B 立即预订",
  "corporate_b2b_later_booking": "企业 - B2B 稍后预订",
  "walkin_trip": "步行旅行",
  "driver_booking": "司机预订",
  "location_trip": "地点旅行",
  "website": "网站",
  "total_trips": "总行程",
  "tooltip_completed_transaction": "转到已完成的交易行程报告",
  "requested": "已请求",
  "subscribed": "已订阅",
  "cancelled": "取消",
  "unsubscribed": "取消订阅",
  "unsubscription_pending": "取消订阅待处理",
  "on_hold": "等候接听",
  "total_active_subscriber_count": "活跃订阅者总数",
  "tooltip": "前往出租车订阅列表",
  "0_8_hours": "0-8小时",
  "8_16_hours": "8-16小时",
  "16_24_hours": "16-24小时",
  "go_to_dashboard": "转到仪表板驾驶员轮班报告",
  "go_to_manage_location_queue": "转到管理位置队列",
  "driver_details": "司机详情",
  "car_model": "车型",
  "new_booking": "新预订",
  "search_trip_id": "按行程 ID 搜索",
  "clear": "清除",
  "upcoming_booking": "即将预订",
  "ongoing_booking": "持续预订",
  "past_booking": "过去的预订",
  "cancelled_booking": "取消预订",
  "notification_status": "通知状态",
  "something_went_wrong": "出了点问题",
  "mail_preview": "邮件预览",
  "send": "发送",
  "close": "关闭",
  "send_mail_to_corporate": "发送邮件至公司",
  "navigate_to_trip": "导航至行程",
  "passenger_log": "乘客日志",
  "pickup_location": "接送地点",
  "pickup_landmark": "皮卡地标",
  "drop_location": "掉落地点",
  "drop_landmark": "掉落地标",
  "booking_type": "预订类型",
  "pickup_time": "接载时间",
  "payment_method": "付款方式",
  "note_to_driver": "司机注意事项",
  "flight_number": "航班",
  "reference_number": "参考编号",
  "promo_code": "促销代码",
  "customer_price": "客户价格",
  "customer_hourly_rate": "客户每小时费率",
  "motor_info": "电机信息",
  "model_name": "型号名称",
  "passenger_info": "乘客信息",
  "passenger_email": "旅客邮箱",
  "country_code": "国家/地区代码",
  "passenger_mobile": "客运手机",
  "passenger": "乘客",
  "corporate": "公司的",
  "passenger_list": "旅客名单",
  "corporate_list": "企业名单",
  "select_passenger_or_corporate_details": "请选择乘客或公司详细信息",
  "guest_passenger": "贵宾乘客",
  "edit_passenger": "编辑乘客",
  "customer_details": "客户详情",
  "personal_info": "个人信息",
  "name": "姓名",
  "phone": "电话",
  "clear_passenger": "清晰的乘客",
  "add_passenger": "添加乘客",
  "please_enter_name": "请输入姓名",
  "please_enter_email": "请输入电子邮件地址",
  "please_enter_valid_email": "请输入有效的电子邮件地址",
  "please_enter_phone": "请输入电话号码",
  "guest_name": "宾客姓名",
  "please_enter_guest_name": "请输入客人姓名",
  "guest_email": "访客电子邮件",
  "guest_phone": "访客电话",
  "please_enter_guest_phone": "请输入客人电话",
  "stop_1": "站 1",
  "select_pickup_location": "请选择取货地点",
  "select_drop_location": "请选择投放地点",
  "enable_paste_map_link": "启用此功能可以粘贴 Google 地图链接，而不是在接送地点中输入地址",
  "pickup_location_google_map": "接送地点（谷歌地图链接）",
  "drop_location_google_map": "掉落地点（谷歌地图链接）",
  "stop": "停止{{stopNumber}}",
  "previous": "以前的",
  "next": "下一个",
  "select_booking_type": "选择预订类型",
  "normal_booking": "正常预订",
  "package_booking": "套餐预订",
  "as_soon_as_possible": "尽快地",
  "now_booking_tooltip": "立即预订",
  "later_booking_tooltip": "稍后预订",
  "now": "现在",
  "please_select_date": "请选择日期",
  "select_package_type": "选择套餐类型",
  "hours": "时间",
  "hrs": "小时",
  "days": "天",
  "minutes": "分钟",
  "select_package": "选择套餐",
  "please_select_valid_package_type": "请选择有效的包裹类型",
  "km": "知识管理",
  "aed": "自动体外除颤器",
  "please_select_valid_package": "请选择有效的套餐",
  "payment_type": "付款方式",
  "select_payment_type": "选择付款方式",
  "cash": "现金",
  "bill": "账单",
  "complimentary": "免费",
  "pos": "销售点",
  "select_valid_payment_method": "请选择有效的付款方式",
  "additional_elements": "附加元素",
  "custom_pricing": "定制定价",
  "payment_by": "付款方式",
  "customer": "顾客",
  "note_to_driver_optional": "司机注意事项（可选）",
  "flight_number_optional": "航班号（可选）",
  "reference_number_optional": "参考号（可选）",
  "promo_code_optional": "促销代码（可选）",
  "assign_to_driver_optional": "分配给驱动程序（可选）",
  "note_to_admin_optional": "管理员注意事项（可选）",
  "operational_supervisor_optional": "运营主管（可选）",
  "room_no_optional": "房间号（可选）",
  "driver_share": "司机分享",
  "rsl_share": "RSL 分享",
  "corporate_share": "企业股份",
  "extra_charge": "额外收费",
  "extra_charge_error_message": "额外费用不能高于客户价格",
  "trip_duration": "行程持续时间",
  "remarks": "评论",
  "trip_type": "行程类型",
  "normal_trip": "正常行程",
  "round_trip": "往返",
  "round_trip_fare": "往返票价",
  "single": "单身的",
  "double": "双倍的",
  "do_join": "加入",
  "b2b": "企业对企业",
  "b2c": "企业对消费者",
  "booking_status": "预订状态",
  "all": "全部",
  "yet_to_start": "尚未开始",
  "waiting_for_passenger": "等待乘客",
  "reassign": "重新分配",
  "driver_assigned": "分配司机",
  "driver_on_the_way": "司机在路上",
  "type_of_booking": "预订类型",
  "later": "之后",
  "package": "包裹",
  "booking_from": "预订自",
  "passenger_app": "乘客应用程序",
  "dispatcher_passenger": "调度员 - 乘客",
  "dispatcher_corporate": "调度员 - 企业",
  "supervisor_app": "主管应用程序",
  "walk_in_trip": "走进旅行",
  "booking_from_new": "从新开始预订",
  "mobile_app": "手机应用程序",
  "driver_trip": "司机行程",
  "location": "地点",
  "company": "公司",
  "car_type": "车型",
  "type_of_car": "汽车类型",
  "search_by_booking_or_group_id": "按预订或团体 ID 搜索",
  "search_by_reference_number": "按参考号搜索",
  "search_without_date_range": "没有日期范围的搜索",
  "search_with_date_range": "按日期范围搜索",
  "to_date": "迄今为止",
  "booking_id": "预订ID",
  "type": "类型",
  "corporate_name": "公司名称",
  "status": "地位",
  "from": "从",
  "dojoin": "道加入",
  "custom_zone_name": "自定义区域名称",
  "driver": "司机",
  "car_no": "车号",
  "others": "其他的",
  "assign_to_driver": "分配给驱动程序",
  "reference_code": "参考代码",
  "note_to_admin": "管理员注意事项",
  "estimated_customer_price": "预计客户价格",
  "car_company": "汽车公司",
  "operational_supervisor": "运营主管",
  "staff_number": "员工人数",
  "room_number": "房间号",
  "company_booking_type": "公司预订类型",
  "transfer": "转移",
  "full_day": "全天",
  "half_day": "半天",
  "assign_driver": "分配司机",
  "unassign": "取消分配",
  "no_trip": "没有可用的行程",
  "booking_cancellation": "取消预订",
  "are_you_sure_you_want_to_cancel_this_booking": "您确定要取消此预订吗？",
  "cancel_reason": "取消原因",
  "yes": "是的",
  "no": "不",
  "driver_unassign": "驱动程序取消分配",
  "send_sms_to_driver": "发送短信给司机",
  "send_whatsapp_to_corporate": "发送 Whatsapp 至公司",
  "are_you_sure_you_want_to_unassign": "您确定要取消分配吗？",
  "unassign_reason": "取消分配原因",
  "trip_details": "行程详情",
  "date_time_picker": "日期时间选择器",
  "check_in": "报到",
  "check_out": "查看",
  "to": "到",
  "outlined": "概述",
  "date_time_range": "日期时间范围",
  "date_time": "日期时间",
  "time": "时间",
  "save": "节省",
  "payment_options": "付款方式",
  "select_payment_method": "选择付款方式",
  "card": "卡片",
  "toggle_visibility": "切换可见性",
  "no_permission_to_change_fare": "您没有更改票价的管理员权限",
  "error_message_rsl_customer_price": "客户价格无效",
  "extra_charge_error": "额外费用不能高于客户价格",
  "select_driver": "选择驱动程序",
  "customer_rate_optional": "客户价格（可选）",
  "please_select_drop_location": "请选择投放地点",
  "back": "后退",
  "alert_message": "成功！你的行动已经完成。",
  "choose_the_driver_from_list": "从列表中选择驱动程序",
  "driver_list": "司机名单",
  "rating_for_drivers": "驾驶员评级",
  "accepted_driver": "接受的司机",
  "rejected_driver": "被拒绝的司机",
  "booking_info": "预订信息",
  "now_single_stop": "现在（单站）",
  "united_arab_emirates": "阿拉伯联合酋长国 阿联酋",
  "guest_info": "宾客信息",
  "fare_details": "票价详情",
  "base_fare": "基本票价",
  "distance_fare": "距离票价",
  "waiting_cost": "等待成本",
  "night_charge": "夜间收费",
  "evening_charge": "晚间收费",
  "toll_fare": "通行费",
  "used_wallet_amount": "已用钱包金额",
  "promo_discount_amount": "促销折扣金额",
  "actual_trip_fare": "实际行程票价",
  "payment_mode": "付款方式",
  "trip_route": "出行路线",
  "driver_route": "司机路线",
  "trip_log": "行程日志",
  "driver_rejection_log": "司机拒绝日志",
  "pickup_gmap": "捡起",
  "drop_gmap": "降低",
  "okay": "好的",
  "are_you_sure_to_end_trip": "您确定结束旅行吗？",
  "trip_in_progress": "行程进行中",
  "waiting_for_payment": "等待付款",
  "trip_start_time": "行程开始时间",
  "trip": "旅行",
  "booking_price": "预订价格",
  "additional_booking_price": "额外预订价格",
  "car_number": "车号",
  "end_trip": "结束行程",
  "toggle_dropdown_visibility": "切换密码可见性",
  "parking_charge": "停车费",
  "total_fare": "总票价",
  "trip_end_time": "行程结束时间",
  "verify_status": "验证状态",
  "verified": "已验证",
  "need_to_verify": "需要验证",
  "phone_number": "电话号码",
  "emirates_id": "阿联酋身份证",
  "dropoff": "下车",
  "room_no": "房间号",
  "Hrs": "小时",
  "mins": "分钟",
  "secs": "秒",
  "trip_distance": "行程距离",
  "waiting_time": "等待时间",
  "past_trips_cancellation_fare": "过去行程取消票价",
  "trip_completed": "行程已完成",
  "receipt": "收据",
  "verify": "核实",
  "passenger_cancelled": "乘客取消",
  "dispatcher_cancelled": "调度员已取消",
  "driver_cancelled": "司机取消",
  "cancellation_reason": "取消原因",
  "no_show": "缺席",
  "manage_custom_zone": "管理自定义区域",
  "keyword": "关键词",
  "search_by_zone_name_location": "按区域名称、区域位置搜索",
  "status_update": "状态更新",
  "reason": "原因",
  "please_enter_reason": "请输入原因",
  "save_changes": "保存更改",
  "add": "添加",
  "active": "积极的",
  "block": "堵塞",
  "trash": "垃圾",
  "zone_name": "区域名称",
  "zone_location": "区域位置",
  "action": "行动",
  "model_fare": "型号票价（添加/编辑）",
  "driver_list_add_edit": "驱动程序列表（添加/编辑）",
  "push_notification": "推送通知",
  "custom_zone_add": "添加自定义区域",
  "custom_zone_edit": "编辑自定义区域",
  "zone_amount": "区域数量",
  "priority": "优先事项",
  "additional_information": "附加信息",
  "key": "钥匙",
  "value": "价值",
  "remove_polygon": "删除多边形",
  "add_polygon_required": "请添加多边形，多边形为必填项！",
  "address": "地址",
  "customzone_fare": "海关区票价",
  "minimum_kilometers": "最小公里数",
  "minimum_fare": "最低票价",
  "cancellation_fare": "取消票价",
  "below_above_kilometers": "公里以下/公里以上",
  "below_kilometers": "公里以下",
  "above_kilometers": "公里以上",
  "fare_per_minute": "每分钟票价",
  "morning_charge": "早上充电",
  "morning_timing_from": "早上时间从",
  "evening_timing_from": "晚上时间从",
  "evening_timing_to": "晚上时间到",
  "admin_commission": "行政委员会",
  "company_commission": "公司佣金",
  "driver_commission": "司机佣金",
  "corporate_commission": "公司佣金",
  "night_timing_from": "夜间计时从",
  "night_timing_to": "夜间定时至",
  "no1": "不",
  "yes1": "是的",
  "custom_zone_drivers": "自定义区域驱动程序",
  "add_driver_custom_zone": "在该自定义区域下添加驱动程序",
  "zone_list": "区域列表",
  "manage_offer_zone": "管理优惠区",
  "offer_zone_add": "添加优惠区",
  "offer_zone_edit": "编辑优惠区",
  "manage_divisions": "管理部门",
  "search_by_division_name": "按部门名称搜索",
  "division_name": "部门名称",
  "edit_division": "编辑部",
  "add_division": "添加部门",
  "manage_departments": "管理部门",
  "search_by_department_name": "按部门名称搜索",
  "department_name": "部门名称",
  "edit_department": "编辑部门",
  "add_department": "添加部门",
  "division": "分配",
  "invalid_email": "电子邮件格式无效",
  "phone_must_7_digits": "电话号码必须是 7 位数字",
  "total_drivers": "总驱动力",
  "unassigned": "未分配",
  "new_drivers": "新司机",
  "drivers_shift_status": "司机轮班状态",
  "shift_in": "移入",
  "shift_out": "移出",
  "offline": "离线",
  "sign_out": "登出",
  "trip_assigned": "行程分配",
  "busy_in_trip": "旅途忙碌",
  "driver_search": "按姓名、电子邮件、公司名称、电话、唯一 ID 搜索",
  "previous_reason": "之前的原因",
  "referral_poster": "推荐海报",
  "valid": "有效的",
  "expired": "已到期！",
  "open": "打开",
  "driverInfo": "司机信息",
  "driver_unique_id": "驾驶员唯一 ID",
  "attached_vehicle": "附属车辆",
  "photo": "照片",
  "driver_current_version": "驱动程序当前版本",
  "location_status": "位置状态",
  "created_date": "创建日期",
  "expiry_status": "到期状态",
  "manage_driver_record": "管理驾驶记录",
  "profile_picture": "个人资料图片",
  "created_date_and_time": "创建日期和时间",
  "taxi_no": "出租车号码",
  "manufacturer_and_make_name": "制造商和品牌名称",
  "device_type": "设备类型",
  "no_of_rows": "行数",
  "export_data": "导出数据",
  "add_driver": "添加驱动程序",
  "gender": "性别",
  "male": "男性",
  "female": "女性",
  "please_enter_driver_unique_id": "请输入驾驶员唯一 ID",
  "please_enter_number": "请输入手机号码（最少7个字符）",
  "state_error": "请选择状态",
  "city_error": "请选择城市",
  "additional_info": "附加信息",
  "company_error": "请选择汽车公司",
  "driver_license_id": "驾驶执照 ID",
  "driver_license_expiry_date": "驾驶执照到期日",
  "rta_card_id": "RTA 卡 ID",
  "rta_card_expiry_date": "RTA 卡到期日",
  "driver_national_id": "司机国民身份证",
  "driver_national_id_expiry_date": "司机国民身份证有效期",
  "driver_iban_bank_account": "司机 IBAN 银行账户",
  "bank_name": "银行名称",
  "please_select_expiry_date": "请选择到期日期",
  "please_enter_booking_limit": "请输入每天的预订限额",
  "attach_single_taxi": "附加单人出租车",
  "assign_multiple_taxi": "分配多辆出租车",
  "password_not_matched": "密码不匹配",
  "documents": "文件",
  "emirates_id_front_image": "阿联酋身份证正面图片",
  "emirates_id_back_image": "阿联酋身份证背面图片",
  "rta_card_front_image": "RTA 卡正面图像",
  "rta_card_back_image": "RTA 卡背面图像",
  "driving_license_front_image": "驾驶执照正面图像",
  "driving_license_back_image": "驾驶执照背面图片",
  "edit_driver": "编辑驱动程序",
  "view_banner": "查看横幅",
  "download": "下载",
  "download_vertically": "垂直下载",
  "download_horizontally": "水平下载",
  "newly_registered_drivers": "新注册司机",
  "waiting_for_approval": "等待批准",
  "approve": "批准",
  "disapprove": "不赞成",
  "edit_cars": "编辑汽车",
  "disapprove_driver_confirmation": "您确定拒绝该司机吗？",
  "show_report_for": "显示报告",
  "export_driver_status": "按状态导出驱动程序",
  "all_driver_records": "所有驾驶员记录",
  "shift_in_driver_records": "转移驾驶员记录",
  "shift_out_driver_records": "移出驾驶员记录",
  "trip_assigned_driver_records": "行程分配的驾驶员记录",
  "busy_in_trip_driver_records": "繁忙行程司机记录",
  "sign_out_driver_records": "注销驾驶员记录",
  "offline_driver_records": "离线驾驶记录",
  "manage_loss_found_logs_records": "管理丢失/找到的日志记录",
  "comments": "评论",
  "driver_id": "驾驶员 ID",
  "subject": "主题",
  "description": "描述",
  "image": "文档",
  "logs": "日志",
  "no_logs": "无日志",
  "select_status": "选择状态",
  "approved": "得到正式认可的",
  "rejected": "被拒绝",
  "please_select_status": "请选择状态",
  "enter_your_commands": "输入您的命令",
  "driver_document_request_record": "驾驶员文件请求记录",
  "search_by_model_name": "按型号名称搜索",
  "add_model": "添加型号",
  "edit_model": "编辑模型",
  "enter_model_name": "输入型号名称。",
  "model_size": "型号尺寸",
  "enter_model_size": "输入模型尺寸。",
  "enter_base_fare": "输入基本票价",
  "min_km": "最小公里数",
  "min_km_error": "以数字格式输入最小公里数（例如：50,60）",
  "min_fare": "最低票价",
  "min_fare_error": "以数字格式输入最低票价（例如：50,60.45）",
  "cancellation_fee": "取消费用",
  "below_above_km": "低于和高于公里范围",
  "below_km_fare": "低于公里票价（每公里）",
  "above_km_fare": "以上公里票价（每公里）",
  "waiting_charge": "等候车费（每小时）",
  "taxi_speed": "出租车速度",
  "taxi_min_speed": "出租车最低速度",
  "car_waiting_charge": "候车费",
  "enter_cancellation_fee": "以数字格式输入取消费用（例如 30、34.56）",
  "enter_below_above_km": "以数字格式输入低于和高于公里范围（例如 50）",
  "enter_below_km_fare": "以数字格式输入以下公里票价（例如 7、6.50）",
  "enter_above_km_fare": "以数字格式输入上述公里票价（例如 12、15.50）",
  "enter_waiting_charge": "输入汽车等候费",
  "enter_fare_per_minute": "输入每分钟票价",
  "enter_taxi_speed": "输入每小时的汽车速度",
  "enter_taxi_min_speed": "以数字格式输入最低票价（例如 50、60.45）",
  "above_km": "以上公里票价（每公里）",
  "minutes_fare": "每分钟票价",
  "night_fare": "夜间票价",
  "evening_fare": "晚间票价",
  "enter_car_waiting_charge": "输入汽车等候费",
  "android_model_unfocus_image": "Android 模型未对焦图像（175x100 像素）",
  "ios_model_focus_image": "iOS 模型焦点图像（125x71 像素）",
  "ios_model_unfocus_image": "iOS 模型未对焦图像（125x71 像素）",
  "location_model_image": "位置模型图像（418x183 像素）",
  "taxi_number": "的士号码",
  "car_manufacturer_name": "汽车制造商名称",
  "car_make_name": "汽车品牌名称",
  "total_cars": "车辆总数",
  "search_by_taxi_no_company_name": "按出租车号码、公司名称搜索",
  "car_model_year": "车型年份",
  "car_owner_name": "车主姓名",
  "car_color": "汽车颜色",
  "taxi_motor_expiry_date": "的士汽车有效期",
  "add_car": "添加汽车",
  "edit_car": "编辑汽车",
  "taxi_unique_id": "出租车唯一 ID",
  "taxi_insurance_number": "出租车保险号",
  "car_insurance_expiry_date": "汽车保险到期日",
  "car_chassis_number": "汽车底盘号",
  "please_select_state": "请选择州",
  "please_select_city": "请选择城市",
  "car_image_340_pixel": "汽车图像（340x183 像素）",
  "select_car_company": "请选择汽车公司",
  "please_select_country": "请选择一个国家",
  "car": "车",
  "manage_assigned_car_records": "管理指定车辆记录",
  "search_by_driver_name": "按司机姓名搜索",
  "active_assigned_cars": "Active_AssignedCars",
  "block_assigned_cars": "Block_AssignedCars",
  "trash_assigned_cars": "Trash_AssignedCars",
  "unassigned_assigned_cars": "未分配_分配汽车",
  "all_assigned_cars": "All_AssignedCars",
  "total_subscription_list": "总订阅列表",
  "requested_date": "要求的日期",
  "subscribed_date": "订阅日期",
  "unsubscribe_cancel_date": "退订/取消日期",
  "duration_days_hours_minutes_seconds": "持续时间（天、小时、分钟、秒）",
  "subscription_status": "订阅状态",
  "user": "用户",
  "subscription_amount": "认购金额",
  "log_count": "日志计数",
  "total_amount": "总金额",
  "total_active": "总活跃度",
  "total_subscribe": "订阅总数",
  "total_unsubscribe": "全部取消订阅",
  "add_driver_to_subscription": "将驱动程序添加到订阅",
  "choose_driver_to_subscribe": "选择要订阅的驱动程序",
  "request_subscription": "请求订阅",
  "activate_subscription": "激活订阅",
  "subscription_setting": "订阅设置",
  "auto": "汽车",
  "manual": "手动的",
  "content": "内容",
  "hold": "抓住",
  "activate": "激活",
  "date_filter_type": "日期过滤器类型",
  "search_taxi_number": "按出租车号码搜索",
  "reason_for_hold_subscription": "暂停认购的原因",
  "reason_for_approve_subscription": "批准订阅的原因",
  "reason_for_cancel_subscription": "取消订阅的原因",
  "other_drivers": "其他司机",
  "subscription_date": "认购日期",
  "unsubscribe_date": "取消订阅日期",
  "duration": "期间",
  "all_taxi_subscription_records": "所有出租车订购记录",
  "active_taxi_subscription_records": "活跃的出租车订购记录",
  "subscribed_taxi_subscription_records": "已订阅出租车订阅记录",
  "unsubscribed_taxi_subscription_records": "未订阅的出租车订阅记录",
  "cancelled_taxi_subscription_records": "取消的士订购记录",
  "hold_taxi_subscription_records": "保留出租车订购记录",
  "pending_taxi_subscription_records": "待处理的出租车预订记录",
  "other_drivers_taxi_subscription_records": "其他司机的出租车订购记录",
  "manufacturer_list": "制造商清单",
  "edit_manufacturer": "编辑制造商",
  "add_manufacturer": "添加制造商",
  "car_make_list": "汽车制造清单",
  "search_by_car_make_name": "按汽车品牌名称搜索...",
  "edit_car_make": "编辑汽车品牌",
  "add_car_make": "添加汽车品牌",
  "upload_car_make_image": "上传汽车制作图片",
  "car_make_image_allowed_format": "允许的格式：.jpeg、.jpg、.png、.webp",
  "download_document": "下载文件",
  "total_company": "公司总计",
  "manage_company_record": "管理公司记录",
  "search_by_company_name_user_name_email": "按公司名称、用户名、电子邮件 ID 搜索",
  "no_of_cars": "汽车数量",
  "no_of_drivers": "司机人数",
  "add_company": "添加公司",
  "personal_information": "个人信息",
  "last_name": "姓",
  "company_image": "公司形象（340x183像素）",
  "choose_theme": "选择主题",
  "primary_color": "原色 *",
  "secondary_color": "次要颜色 *",
  "primary_color_required": "需要原色",
  "secondary_color_required": "需要辅助颜色",
  "document_required": "需要文件",
  "shift_timing_from": "转移时间从",
  "shift_timing_to": "时间转移至",
  "shift_hours": "轮班时间",
  "edit_company": "编辑公司",
  "company_info": "公司信息",
  "company_car": "公司用车",
  "company_driver": "公司司机",
  "promo_code_discount": "促销代码折扣 (%)",
  "start_date": "开始日期",
  "expiry_date": "到期日",
  "mobile_app_visibility": "移动应用程序可见性",
  "promocode_limit": "促销代码限制",
  "offer_type": "报价类型",
  "promocode_generation_type": "促销代码生成类型",
  "start_date_from": "开始日期",
  "start_date_to": "开始日期至",
  "expiry_date_from": "到期日期自",
  "expiry_date_to": "到期日至",
  "search_by_promocode": "按促销代码搜索",
  "manage_promocode_record": "管理促销代码记录",
  "promocode": "促销代码",
  "promocode_discount": "促销代码折扣",
  "enabled": "启用",
  "disabled": "残疾人",
  "promocode_flow": "促销代码流程",
  "lifestyle_promotion_flow": "生活方式推广流程",
  "dynamic": "动态的",
  "used_promocode_count": "使用的促销代码计数",
  "unused_promocode_count": "未使用的促销代码计数",
  "add_promocode": "添加促销代码",
  "select": "选择",
  "all_passenger": "所有乘客",
  "select_passenger": "选择乘客",
  "new_passengers": "新乘客",
  "categories": "类别",
  "limit_per_passenger": "每名乘客的限制",
  "promocode_maximum_limit": "促销代码最大限额",
  "discount_percentage": "百分比",
  "discount_flat": "平坦的",
  "ticket": "票",
  "discount_type": "折扣类型",
  "percentage": "百分比",
  "flat": "平坦的",
  "ticket_count": "票数",
  "discount_in_percent": "折扣百分比",
  "maximum_discount": "最大折扣",
  "flat_amount": "固定金额",
  "select_activity_packages": "选择活动套餐",
  "select_staycation_packages": "选择住宿套餐",
  "select_bus_rental_packages": "选择巴士租赁套餐",
  "select_holipackages": "选择度假套餐",
  "select_car_rental_packages": "选择租车套餐",
  "select_packages": "选择套餐",
  "offer_for_other_services": "其他服务报价*",
  "offer_zone": "优惠区 *",
  "min_KM": "分钟公里",
  "max_km": "最大公里数",
  "edit_promocode": "编辑促销代码",
  "view_dynamic_promocode": "查看动态促销代码历史记录",
  "dynamic_promocode_history": "动态促销代码历史记录",
  "user_details": "用户详细信息",
  "not_used": "未使用",
  "used": "用过的",
  "total_corporate": "企业总计",
  "manage_corporate_record": "管理公司记录",
  "search_by_name_email": "按姓名、电子邮件搜索",
  "add_edit_zone_group": "添加/编辑区域组",
  "zone_group_fare_mapping_new": "新区域团体票价映射（汽车制造商）",
  "active_corporates": "活跃企业",
  "block_corporates": "区块公司",
  "trash_corporates": "垃圾企业",
  "all_corporates": "所有企业",
  "add_corporate": "添加公司",
  "edit_corporate": "编辑公司",
  "fare_visibility": "司机票价可见性",
  "fare_settings": "票价设置",
  "zone_to_zone_fare": "区域间票价",
  "company_fare": "公司票价",
  "trade_license_image": "贸易许可证图像（340x183 像素）",
  "corporate_logo": "公司徽标（340x183 像素）",
  "contract_document": "合同文件",
  "below_above_km_range": "低于和高于公里范围",
  "file_allowed_format": "允许的格式 .jpeg、.jpg、.png、.pdf、.doc、.docx",
  "img_allowed_format": "允许的格式 .jpeg、.jpg、.png",
  "do_you_want_to_add_new_password": "您想添加新密码吗？",
  "password_cannot_be_empty": "密码不能为空",
  "download_trade_license": "下载营业执照",
  "download_contract_document": "下载合同文件",
  "inspection_alert_duration": "检查警报持续时间",
  "inspection_setting": "检验设定",
  "duration_cannot_be_zero": "持续时间不能为 0",
  "manage_zone_group": "管理区域组",
  "search_by_zone_group_name": "按区域组名称搜索",
  "manage_zone_group_record": "管理区域组记录",
  "zone_group_name": "区域组名称",
  "zone_names": "区域名称",
  "add_zone_group": "添加区域组",
  "add_zone_under_this_group": "在该区域组下添加区域",
  "edit_zone_group": "编辑区域组",
  "active_zone_groups": "活动区域组",
  "block_zone_groups": "区块区域组",
  "trash_zone_groups": "垃圾区组",
  "all_zone_groups": "所有区域组",
  "corporate_amount": "法人金额",
  "driver_amount": "司机金额",
  "company_amount": "公司金额",
  "admin_amount": "管理金额",
  "end_passenger_amount": "最终乘客量",
  "remove_confirmation": "您确定要删除吗？",
  "delete_confirmation": "您确定要删除吗？",
  "zone_group_fare_mapping": "区域团体票价映射（汽车制造商）",
  "zone_from": "区域来自",
  "zone_to": "区域至",
  "zone_group_from": "区域组来自",
  "zone_group_to": "区域组至",
  "remove": "消除",
  "corporate_id": "企业编号",
  "package_type": "封装类型",
  "add_package": "添加套餐",
  "package_hrs_days": "套餐小时/天",
  "package_hours_days": "套餐时间/天数",
  "search_by_zone_name": "按区域名称搜索",
  "package_distance": "包裹距离",
  "package_amount": "套餐金额",
  "edit_zone": "编辑区",
  "add_zone": "添加区域",
  "zone_name_required": "区域名称为必填项",
  "polygon_required": "请添加多边形，多边形为必填项！",
  "active_zones": "活跃区",
  "block_zones": "区块区",
  "trash_zones": "垃圾区",
  "all_zones": "所有区域",
  "update_wallet_amount": "更新钱包金额",
  "amount": "数量",
  "enter_amount": "请输入金额",
  "enter_password": "请输入密码",
  "enter_comments": "请输入评论",
  "manage_passengers_record": "管理乘客记录",
  "active_passengers": "活跃乘客",
  "block_passengers": "阻止乘客",
  "trash_passengers": "垃圾乘客",
  "all_passengers": "所有乘客",
  "wallet_amount": "钱包金额",
  "user_information": "用户信息",
  "otp_count": "一次性密码计数",
  "reset_otp_count": "重置 OTP 计数",
  "reset_otp_count_confirmation": "您确定要重置 OTP 计数吗？",
  "package_additional_fare_per_km": "套餐附加费（每公里）",
  "package_additional_fare_per_hrs": "套餐附加费（每小时）",
  "short_description": "简短描述",
  "long_description": "详细说明",
  "edit_package": "编辑包",
  "multiple_dropoff_locations_add_edit": "多个下车地点（添加/编辑）",
  "customize_fare": "定制票价",
  "total_location": "总位置",
  "manage_location_record": "管理位置记录",
  "search_by_name_email_company": "按姓名、电子邮件、公司名称搜索",
  "active_location": "活动位置",
  "block_location": "街区位置",
  "trash_location": "垃圾位置",
  "all_location": "所有地点",
  "edit_location": "编辑位置",
  "add_location": "添加位置",
  "address_is_required": "地址为必填项",
  "location_name": "地点名称",
  "queue_limit": "队列限制",
  "drop_your_message": "留下您的留言",
  "commission_type": "佣金类型",
  "subscription": "订阅",
  "polygon_restriction": "多边形限制",
  "enable": "使能够",
  "disable": "禁用",
  "cancel_password_required": "取消密码 *",
  "cancel_password": "取消密码",
  "cancel_password_empty": "取消 密码不能为空",
  "confirm_cancel_password_required": "确认取消密码 *",
  "confirm_cancel_password": "确认 取消 密码",
  "driver_limit_start_supervisor_trip": "驾驶员启动主管行程的限制",
  "location_type": "位置类型",
  "general": "一般的",
  "hotel": "酒店",
  "target_trip_count": "目标行程计数",
  "restrict_driver_remove_from_queue": "限制驱动程序从队列中删除",
  "restriction_hours": "限制时间",
  "location_fare_edit": "地点 票价 编辑",
  "quick_trip_option_in_supervisor_app": "主管应用程序中的快速行程选项",
  "driver_app_visibility": "驾驶员应用程序可见性",
  "add_driver_to_queue": "将司机添加到队列",
  "queue_alert_notification": "队列警报通知",
  "queue_alert_notification_time": "队列警报通知时间（以分钟为单位）",
  "open_timing_from": "开放时间从",
  "open_timing_to": "开放时间至",
  "get_ready_queue_reorder_time_in_minutes": "准备队列重新排序时间（以分钟为单位）",
  "document_verification": "文件验证",
  "verification_type": "验证类型",
  "auto_verify": "自动验证",
  "manual_verify": "手动验证",
  "waiting_queue_n_drivers_to_reorder": "等待队列 - N 名需要重新订购的司机",
  "n_drivers_update": "N 个驱动程序（外部位置）更新当前时间",
  "waiting_queue_time_in_minutes": "等待队列 - 重新订购的时间（以分钟为单位）",
  "driver_time_update": "更新当前日期时间的时间（以分钟为单位 - 驱动程序 - 外部位置）",
  "queue_setting": "队列设置",
  "manual_setting": "手动 - 只有主管才能将司机添加到队列中",
  "driver_add_to_queue_restriction": "驱动程序添加到队列限制",
  "multiple": "多种的",
  "location_driver_details": "位置 司机详细信息",
  "add_driver_under_location": "在该位置区域下添加驱动程序",
  "location_list": "地点列表",
  "notes": "笔记",
  "multiple_dropoff_locations": "多个下车地点",
  "latitude": "纬度 *",
  "longitude": "经度 *",
  "fare": "票价*",
  "peak_hour_fare": "高峰时段票价",
  "contract_amount": "合同金额",
  "location_customized_fare": "地点定制票价",
  "models": "型号",
  "queue_location_list": "队列位置列表",
  "main_queue": "主队列",
  "waiting_queue": "等待队列",
  "queue_location_list_label": "队列位置列表",
  "model_list": "型号列表",
  "note_model_filter": "注意：如果应用了模型过滤器，则无法对队列重新排序",
  "car_plate_number": "车牌号码",
  "confirm_queue_change": "您确定要更改为",
  "confirm_remove_driver": "您确定从队列中删除该驱动程序吗？",
  "group_name": "团体名称",
  "kiosk_list": "信息亭列表",
  "sunday": "星期日",
  "monday": "周一",
  "tuesday": "周二",
  "wednesday": "周三",
  "thursday": "周四",
  "friday": "星期五",
  "saturday": "周六",
  "count": "数数",
  "count_exceeded": "通知计数不能超过",
  "location_queue_driver_logs_record": "位置队列驱动程序日志记录",
  "queue_position": "队列位置",
  "supervisor_name": "主管姓名",
  "supervisor_unique_id": "主管唯一 ID",
  "added_from": "添加自",
  "search_by_toll_name": "按收费名称搜索",
  "manage_toll_record": "管理收费记录",
  "toll_name": "收费名称",
  "toll_location": "收费地点",
  "toll_amount": "通行费金额",
  "add_toll": "添加通行费",
  "edit_toll": "编辑通行费",
  "toll_location_is_required": "收费站位置为必填项",
  "manage_supervisor": "管理主管",
  "manage_supervisor_record": "管理主管记录",
  "active_supervisors_report": "活跃的监管者报告",
  "block_supervisors_report": "被屏蔽的主管报告",
  "trash_supervisors_report": "垃圾主管报告",
  "all_supervisors_report": "全体监事报告",
  "edit_supervisor": "编辑主管",
  "add_supervisor": "添加主管",
  "supervisor_details": "主管详情",
  "view_supervisor": "查看主管",
  "send_push_notification": "发送推送通知",
  "supervisor_not_login_error": "看来这位主管还没有登录！",
  "supervisor_monitor_list": "主管监控列表",
  "push_id": "推送 ID",
  "video_link": "视频链接",
  "updated_date": "更新日期",
  "please_enter_remarks": "请输入备注",
  "supervisor_login_time": "主管登录时间",
  "supervisor_logout_time": "主管注销时间",
  "supervisor_login_location": "主管登录位置",
  "supervisor_logout_location": "主管注销位置",
  "login_image": "登录图片",
  "logout_image": "注销图片",
  "shift_in_duration_hh_mm_ss": "移入持续时间 (HH:MM:SS)",
  "login_duration": "登录持续时间 (HH:MM:SS)",
  "select_columns_you_need": "选择您需要的栏目",
  "export_selected_data": "导出选定的数据",
  "remove_all": "全部删除",
  "select_all": "选择全部",
  "alert": "警报"
    
  
  
  
  
};

export default cn;
