/* eslint-disable prettier/prettier */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vi = {
  "dashboard": "Trang tổng quan",
  "nds": "NDS",
  "dispatch_map": "Bản đồ điều phối",
  "orders": "Đơn đặt hàng",
  "manage_permission": "Quản lý quyền",
  "manage_handover": "Quản lý bàn giao",
  "custom_zone": "Vùng tùy chỉnh",
  "lifestyle_offer_zone": "Khu ưu đãi LifeStyle",
  "divisions": "Phân khu",
  "departments": "Phòng ban",
  "drivers": "Trình điều khiển",
  "manage_drivers": "Quản lý trình điều khiển",
  "manage_new_drivers": "Quản lý trình điều khiển mới",
  "export_driver_list": "Xuất danh sách trình điều khiển",
  "manage_lost_found_logs": "Quản lý nhật ký Thất lạc/Tìm thấy",
  "driver_document_request": "Yêu cầu tài liệu lái xe",
  "cars": "Ô tô",
  "manage_models": "Quản lý mô hình",
  "manage_cars": "Quản lý ô tô",
  "assign_car": "Gán xe",
  "manage_assigned_cars": "Quản lý xe được giao",
  "taxi_subscription_list": "Danh sách đăng ký taxi",
  "car_manufacturer": "Nhà sản xuất ô tô",
  "car_make": "Chế tạo ô tô",
  "companies": "Công ty",
  "manage_company": "Quản lý công ty",
  "manage_promocode": "Quản lý mã khuyến mại",
  "corporates": "Doanh nghiệp",
  "manage_corporate": "Quản lý doanh nghiệp",
  "corporate_packages": "Gói doanh nghiệp",
  "manage_zone": "Quản lý khu vực",
  "zone_map_view": "Chế độ xem bản đồ khu vực",
  "passengers": "hành khách",
  "manage_passengers": "Quản lý hành khách",
  "passenger_packages": "Gói hành khách",
  "locations": "Địa điểm",
  "known_locations": "Địa điểm đã biết",
  "known_location_country": "Quản lý quốc gia vị trí đã biết",
  "manage_category": "Quản lý danh mục",
  "manage_known_loc": "Quản lý vị trí đã biết",
  "manage_location": "Quản lý vị trí",
  "manage_queue_location": "Quản lý vị trí xếp hàng",
  "location_group": "Nhóm vị trí",
  "location_log": "Nhật ký vị trí",
  "location_queue_driver_log": "Nhật ký trình điều khiển hàng đợi vị trí",
  "tolls": "Phí cầu đường",
  "manage_tolls": "Quản lý phí cầu đường",
  "supervisor": "Người giám sát",
  "supervisor_trip_reports": "Báo cáo chuyến đi của người giám sát",
  "supervisor_logs": "Nhật ký giám sát",
  "discount": "Giảm giá",
  "mobile_promotion": "Khuyến mãi trên thiết bị di động",
  "marketing": "Tiếp thị",
  "lifestyle_location": "Phong cách sống Vị trí",
  "manage_referral_settings": "Quản lý cài đặt giới thiệu",
  "reports": "Báo cáo",
  "driver_shift_hours": "Giờ làm ca của tài xế",
  "settlement": "giải quyết",
  "driver_status": "Trạng thái trình điều khiển",
  "exit_record": "Thoát bản ghi",
  "geo_report": "Báo cáo địa lý",
  "location_queue_driver_logs": "Nhật ký trình điều khiển hàng đợi vị trí",
  "all_other_reports": "Tất cả các báo cáo khác",
  "driver_rejection_report": "Báo cáo từ chối tài xế",
  "trip_reports": "Báo cáo chuyến đi",
  "all_transactions": "Tất cả giao dịch",
  "completed_transactions": "Giao dịch đã hoàn tất",
  "cancelled_transactions": "Giao dịch bị hủy",
  "account_reports": "Báo cáo tài khoản",
  "activity_logs": "Nhật ký hoạt động",
  "historical_data": "Dữ liệu lịch sử",
  "settings": "Cài đặt",
  "site_settings": "Cài đặt trang web",
  "sms_templates": "Mẫu tin nhắn SMS",
  "admin_smtp_settings": "Cài đặt SMTP của quản trị viên",
  "company_smtp_settings": "Cài đặt SMTP của công ty",
  "admin_sms_settings": "Cài đặt SMS của quản trị viên",
  "company_sms_settings": "Cài đặt SMS của công ty",
  "manage_country": "Quản lý quốc gia",
  "manage_state": "Quản lý trạng thái",
  "manage_city": "Quản lý thành phố",
  "manage_social_network": "Quản lý mạng xã hội",
  "manage_google_settings": "Quản lý cài đặt Google",
  "rider": "kỵ sĩ",
  "this_report_displays_the_total_driver_shift_hours_for_the_selected_day": "Báo cáo này hiển thị tổng số giờ làm việc của tài xế cho ngày đã chọn!",
  "select_company": "Chọn công ty",
  "total_active_hours": "Tổng số giờ hoạt động",
  "active_drivers": "Trình điều khiển đang hoạt động",
  "total_vehicle": "Tổng số xe",
  "total_driver": "Tổng trình điều khiển",
  "total_shift_in_hours": "Tổng số ca làm việc theo giờ",
  "total_shift_out_hours": "Tổng Số Giờ Thay Đổi",
  "shift_list": "Danh sách ca",
  "export": "Xuất khẩu",
  "search": "Tìm kiếm",
  "device_id": "Id thiết bị",
  "unique_id": "Id duy nhất",
  "driver_name": "Tên tài xế",
  "vehicle_model": "Mẫu xe",
  "Company": "Công ty",
  "engine_hours": "Giờ động cơ",
  "idle_hours": "Giờ nhàn rỗi",
  "shift_in_hours": "Thay đổi theo giờ",
  "shift_out_hours": "Chuyển đổi số giờ",
  "rows_per_page": "Hàng trên mỗi trang",
  "driver_info": "Thông tin tài xế",
  "general_info": "Thông tin chung",
  "gps": "GPS",
  "software_info": "Thông tin phần mềm",
  "social": "Xã hội",
  "view_history": "Xem lịch sử",
  "from_date": "Từ ngày",
  "end_date": "Ngày kết thúc",
  "cancel": "Hủy bỏ",
  "shift_reports": "Báo cáo ca",
  "fare_update": "Cập nhật giá vé",
  "update": "Cập nhật",
  "no_data_found": "Không tìm thấy dữ liệu",
  "data_not_found": "Không tìm thấy dữ liệu",
  "file_upload": "Tải lên tệp",
  "password": "Mật khẩu",
  "edit": "Biên tập",
  "passenger_name": "Tên hành khách",
  "trip_fare": "Giá vé chuyến đi",
  "emirate_id": "ID tiểu vương quốc",
  "passenger_phone_number": "Số điện thoại của hành khách",
  "free": "Miễn phí",
  "s_no": "S.Không",
  "busy": "Bận",
  "date": "Ngày",
  "log_in_time": "Thời gian đăng nhập",
  "shift_in_duration": "Thay đổi thời lượng",
  "shift_out_duration": "Thời lượng chuyển đổi",
  "inmove": "di chuyển",
  "idle": "Nhàn rỗi",
  "lat_lng": "LatLng",
  "accuracy": "Sự chính xác",
  "altitude": "Độ cao",
  "bearing": "mang",
  "speed": "Tốc độ",
  "last_update": "Cập nhật lần cuối",
  "profile": "Hồ sơ",
  "android": "Android",
  "ios": "iOS",
  "user_profile_management": "Quản lý hồ sơ người dùng",
  "ok": "Được rồi",
  "change_password": "Thay đổi mật khẩu",
  "new_password": "Mật khẩu mới",
  "confirm_password": "Xác nhận mật khẩu",
  "please_enter_new_password": "Vui lòng nhập mật khẩu mới",
  "please_enter_confirm_password": "Vui lòng nhập xác nhận mật khẩu",
  "submit": "Nộp",
  "reset": "Cài lại",
  "view_more": "Xem thêm",
  "driver_information": "Thông tin tài xế",
  "first_name": "Tên",
  "email": "E-mail",
  "mobile_number": "Số điện thoại di động",
  "otp": "OTP",
  "date_of_birth": "Ngày sinh",
  "booking_limit_per_day": "Giới hạn đặt phòng mỗi ngày",
  "rating_points": "Điểm đánh giá",
  "out_of": "Ra khỏi",
  "referral_code": "Mã giới thiệu",
  "my_current_location": "Vị trí hiện tại của tôi",
  "company_name": "Tên công ty",
  "company_information": "Thông tin công ty",
  "company_address": "Địa chỉ công ty",
  "country": "Quốc gia",
  "state": "Tình trạng",
  "city": "Thành phố",
  "i_am_free_online": "Tôi rảnh - Trực tuyến",
  "shift_out_offline": "Chuyển ra ngoài - Ngoại tuyến",
  "i_am_busy_in_trip": "Tôi đang bận trong chuyến đi",
  "signed_out": "ĐĂNG KÝ",
  "trip_id": "Mã chuyến đi",
  "logout": "Đăng xuất",
  "refresh": "Làm cho khỏe lại",
  "last_updated": "Cập nhật lần cuối",
  "shift_history": "Lịch sử ca",
  "show_chart": "Hiển thị biểu đồ",
  "show_list": "Hiển thị danh sách",
  "referral_history": "Lịch sử giới thiệu",
  "total_earned": "Tổng số tiền kiếm được",
  "pending": "Chưa giải quyết",
  "completed_journeys": "Hành trình đã hoàn thành",
  "current_month": "Tháng hiện tại",
  "previous_month": "Tháng trước",
  "mail_settings": "Cài đặt thư",
  "send_mail": "Gửi thư",
  "trip_reports_title": "Báo cáo chuyến đi (Số lượng đã hoàn thành)",
  "subscription_reports_title": "Báo cáo đăng ký",
  "driver_shift_reports_title": "Báo cáo ca lái xe",
  "location_queue_title": "Hàng đợi vị trí (Số lượng tài xế)",
  "email_settings": "Cài đặt email",
  "emails": "Email",
  "emails_helper_text": "Email phải có dạng abc@gmail.com,xyz@gmail.com",
  "time_to_send": "Thời gian gửi email",
  "start_time": "Thời gian bắt đầu",
  "this_field_is_required": "Trường này là bắt buộc",
  "success": "Thành công",
  "error_message": "Đã xảy ra lỗi không mong muốn.",
  "mobile_b2c_now_booking": "B2C di động - Đang đặt chỗ",
  "mobile_b2c_later_booking": "B2C di động - Đặt chỗ sau",
  "admin_b2c_now_booking": "Quản trị viên - B2C Đang đặt chỗ",
  "admin_b2c_later_booking": "Quản trị viên - Đặt chỗ sau B2C",
  "admin_b2b_now_booking": "Quản trị viên - B2B Đang đặt chỗ",
  "admin_b2b_later_booking": "Quản trị viên - Đặt chỗ sau B2B",
  "corporate_b2b_now_booking": "Doanh nghiệp - B2B Đang đặt chỗ",
  "corporate_b2b_later_booking": "Doanh nghiệp - Đặt chỗ sau B2B",
  "walkin_trip": "Chuyến đi tham quan",
  "driver_booking": "Đặt chỗ tài xế",
  "location_trip": "Chuyến đi địa điểm",
  "website": "Trang web",
  "total_trips": "Tổng số chuyến đi",
  "tooltip_completed_transaction": "Chuyển đến Báo cáo chuyến đi giao dịch đã hoàn thành",
  "requested": "Đã yêu cầu",
  "subscribed": "Đã đăng ký",
  "cancelled": "Đã hủy",
  "unsubscribed": "Hủy đăng ký",
  "unsubscription_pending": "Đang chờ hủy đăng ký",
  "on_hold": "Đang chờ",
  "total_active_subscriber_count": "Tổng số người đăng ký hoạt động",
  "tooltip": "Đi tới Danh sách đăng ký Taxi",
  "0_8_hours": "0-8 giờ",
  "8_16_hours": "8-16 giờ",
  "16_24_hours": "16-24 giờ",
  "go_to_dashboard": "Chuyển đến Báo cáo ca lái xe trên bảng điều khiển",
  "go_to_manage_location_queue": "Đi tới Quản lý hàng đợi vị trí",
  "driver_details": "Chi tiết trình điều khiển",
  "car_model": "Mẫu xe ô tô",
  "new_booking": "Đặt phòng mới",
  "search_trip_id": "Tìm kiếm theo ID chuyến đi",
  "clear": "Thông thoáng",
  "upcoming_booking": "Đặt phòng sắp tới",
  "ongoing_booking": "Đặt chỗ liên tục",
  "past_booking": "Đặt phòng trước đây",
  "cancelled_booking": "Đã hủy đặt chỗ",
  "notification_status": "Trạng thái thông báo",
  "something_went_wrong": "Đã xảy ra lỗi",
  "mail_preview": "Xem trước thư",
  "send": "Gửi",
  "close": "Đóng",
  "send_mail_to_corporate": "Gửi thư tới công ty",
  "navigate_to_trip": "Điều hướng đến chuyến đi",
  "passenger_log": "Nhật ký hành khách",
  "pickup_location": "Địa điểm đón",
  "pickup_landmark": "Điểm đón khách",
  "drop_location": "Vị trí thả",
  "drop_landmark": "Cột mốc thả",
  "booking_type": "Loại đặt chỗ",
  "pickup_time": "Thời gian đón",
  "payment_method": "Phương thức thanh toán",
  "note_to_driver": "Lưu ý cho tài xế",
  "flight_number": "Số chuyến bay",
  "reference_number": "Số tham chiếu",
  "promo_code": "Mã khuyến mãi",
  "customer_price": "Giá khách hàng",
  "customer_hourly_rate": "Tỷ lệ hàng giờ của khách hàng",
  "motor_info": "Thông tin động cơ",
  "model_name": "Tên mẫu",
  "passenger_info": "Thông tin hành khách",
  "passenger_email": "Email hành khách",
  "country_code": "Mã nước",
  "passenger_mobile": "Điện thoại di động hành khách",
  "passenger": "Hành khách",
  "corporate": "Công ty",
  "passenger_list": "Danh sách hành khách",
  "corporate_list": "Danh sách công ty",
  "select_passenger_or_corporate_details": "Vui lòng chọn chi tiết hành khách hoặc công ty",
  "guest_passenger": "hành khách khách",
  "edit_passenger": "Chỉnh sửa Hành khách",
  "customer_details": "Chi tiết khách hàng",
  "personal_info": "Thông tin cá nhân",
  "name": "Tên",
  "phone": "Điện thoại",
  "clear_passenger": "Xóa hành khách",
  "add_passenger": "Thêm hành khách",
  "please_enter_name": "Vui lòng nhập tên",
  "please_enter_email": "Vui lòng nhập địa chỉ email",
  "please_enter_valid_email": "Vui lòng nhập địa chỉ email hợp lệ",
  "please_enter_phone": "Vui lòng nhập số điện thoại",
  "guest_name": "Tên khách",
  "please_enter_guest_name": "Vui lòng nhập tên khách",
  "guest_email": "Email của khách",
  "guest_phone": "Điện thoại của khách",
  "please_enter_guest_phone": "Vui lòng nhập số điện thoại của khách",
  "stop_1": "Dừng lại 1",
  "select_pickup_location": "Vui lòng chọn địa điểm đón",
  "select_drop_location": "Vui lòng chọn vị trí thả",
  "enable_paste_map_link": "Bật tính năng này để dán liên kết bản đồ Google thay vì nhập địa chỉ ở vị trí đón và trả khách",
  "pickup_location_google_map": "Địa điểm đón (Link Google Map)",
  "drop_location_google_map": "Vị trí thả (Liên kết Google Map)",
  "stop": "Dừng {{stopNumber}}",
  "previous": "Trước",
  "next": "Kế tiếp",
  "select_booking_type": "Chọn loại đặt chỗ",
  "normal_booking": "Đặt phòng thông thường",
  "package_booking": "Đặt gói",
  "as_soon_as_possible": "Càng sớm càng tốt",
  "now_booking_tooltip": "Đang đặt chỗ",
  "later_booking_tooltip": "Đặt phòng sau",
  "now": "Hiện nay",
  "please_select_date": "Vui lòng chọn ngày",
  "select_package_type": "Chọn loại gói",
  "hours": "Giờ",
  "hrs": "giờ",
  "days": "ngày",
  "minutes": "Phút",
  "select_package": "Chọn gói",
  "please_select_valid_package_type": "Vui lòng chọn loại gói hợp lệ",
  "km": "km",
  "aed": "AED",
  "please_select_valid_package": "Vui lòng chọn gói hợp lệ",
  "payment_type": "Loại thanh toán",
  "select_payment_type": "Chọn loại thanh toán",
  "cash": "Tiền mặt",
  "bill": "Hóa đơn",
  "complimentary": "miễn phí",
  "pos": "POS",
  "select_valid_payment_method": "Vui lòng chọn phương thức thanh toán hợp lệ",
  "additional_elements": "Các yếu tố bổ sung",
  "custom_pricing": "Giá tùy chỉnh",
  "payment_by": "Thanh toán bằng",
  "customer": "Khách hàng",
  "note_to_driver_optional": "Lưu ý cho Driver (Tùy chọn)",
  "flight_number_optional": "Số chuyến bay (Tùy chọn)",
  "reference_number_optional": "Số tham chiếu (Tùy chọn)",
  "promo_code_optional": "Mã khuyến mãi (Tùy chọn)",
  "assign_to_driver_optional": "Gán cho Driver (Tùy chọn)",
  "note_to_admin_optional": "Lưu ý cho quản trị viên (Tùy chọn)",
  "operational_supervisor_optional": "Giám sát vận hành (Tùy chọn)",
  "room_no_optional": "Số phòng (Tùy chọn)",
  "driver_share": "Chia sẻ tài xế",
  "rsl_share": "Chia sẻ RSL",
  "corporate_share": "Cổ phiếu doanh nghiệp",
  "extra_charge": "Phụ phí",
  "extra_charge_error_message": "Phí bổ sung không thể lớn hơn giá khách hàng",
  "trip_duration": "Thời lượng chuyến đi",
  "remarks": "Bình luận",
  "trip_type": "Loại chuyến đi",
  "normal_trip": "Chuyến đi bình thường",
  "round_trip": "Chuyến đi khứ hồi",
  "round_trip_fare": "Giá vé khứ hồi",
  "single": "Đơn",
  "double": "Gấp đôi",
  "do_join": "doJoin",
  "b2b": "B2B",
  "b2c": "B2C",
  "booking_status": "Tình trạng đặt chỗ",
  "all": "Tất cả",
  "yet_to_start": "Chưa bắt đầu",
  "waiting_for_passenger": "Đang chờ hành khách",
  "reassign": "Phân công lại",
  "driver_assigned": "Đã chỉ định tài xế",
  "driver_on_the_way": "Lái xe đang trên đường",
  "type_of_booking": "Loại đặt phòng",
  "later": "Sau đó",
  "package": "Bưu kiện",
  "booking_from": "Đặt chỗ từ",
  "passenger_app": "Ứng dụng hành khách",
  "dispatcher_passenger": "Điều phối viên - Hành khách",
  "dispatcher_corporate": "Điều phối viên - Doanh nghiệp",
  "supervisor_app": "Ứng dụng giám sát",
  "walk_in_trip": "Đi bộ trong chuyến đi",
  "booking_from_new": "Đặt chỗ từ mới",
  "mobile_app": "Ứng dụng di động",
  "driver_trip": "Chuyến đi của tài xế",
  "location": "Vị trí",
  "company": "Công ty",
  "car_type": "Loại xe",
  "type_of_car": "Loại xe",
  "search_by_booking_or_group_id": "Tìm kiếm theo Đặt chỗ hoặc ID nhóm",
  "search_by_reference_number": "Tìm kiếm theo số tham chiếu",
  "search_without_date_range": "tìm kiếm không có phạm vi ngày",
  "search_with_date_range": "tìm kiếm theo phạm vi ngày",
  "to_date": "Đến ngày",
  "booking_id": "Mã đặt chỗ",
  "type": "Kiểu",
  "corporate_name": "Tên công ty",
  "status": "Trạng thái",
  "from": "Từ",
  "dojoin": "Dojoin",
  "custom_zone_name": "Tên vùng tùy chỉnh",
  "driver": "Tài xế",
  "car_no": "Xe số",
  "others": "Người khác",
  "assign_to_driver": "Gán cho người lái xe",
  "reference_code": "Mã tham chiếu",
  "note_to_admin": "Lưu ý cho quản trị viên",
  "estimated_customer_price": "Giá ước tính của khách hàng",
  "car_company": "Công ty ô tô",
  "operational_supervisor": "Giám sát vận hành",
  "staff_number": "Mã Số Nhân Viên",
  "room_number": "Số phòng",
  "company_booking_type": "Loại đặt chỗ của công ty",
  "transfer": "Chuyển khoản",
  "full_day": "Cả ngày",
  "half_day": "Nửa ngày",
  "assign_driver": "Chỉ định trình điều khiển",
  "unassign": "Bỏ chỉ định",
  "no_trip": "Không có chuyến đi nào",
  "booking_cancellation": "Hủy đặt phòng",
  "are_you_sure_you_want_to_cancel_this_booking": "Bạn có chắc chắn muốn hủy đặt chỗ này không?",
  "cancel_reason": "Lý do hủy",
  "yes": "Đúng",
  "no": "KHÔNG",
  "driver_unassign": "Bỏ gán trình điều khiển",
  "send_sms_to_driver": "Gửi SMS cho tài xế",
  "send_whatsapp_to_corporate": "Gửi Whatsapp đến công ty",
  "are_you_sure_you_want_to_unassign": "Bạn có chắc chắn muốn bỏ chỉ định không?",
  "unassign_reason": "Bỏ chỉ định lý do",
  "trip_details": "Chi tiết chuyến đi",
  "date_time_picker": "Bộ chọn ngày giờ",
  "check_in": "Đăng ký vào",
  "check_out": "Trả phòng",
  "to": "ĐẾN",
  "outlined": "phác thảo",
  "date_time_range": "Phạm vi ngày giờ",
  "date_time": "Ngày giờ",
  "time": "Thời gian",
  "save": "Cứu",
  "payment_options": "Tùy chọn thanh toán",
  "select_payment_method": "Chọn phương thức thanh toán",
  "card": "Thẻ",
  "toggle_visibility": "Chuyển đổi chế độ hiển thị",
  "no_permission_to_change_fare": "Bạn không có quyền quản trị viên để thay đổi giá vé",
  "error_message_rsl_customer_price": "Giá khách hàng không hợp lệ",
  "extra_charge_error": "Phí bổ sung không thể lớn hơn giá khách hàng",
  "select_driver": "Chọn Trình điều khiển",
  "customer_rate_optional": "Giá khách hàng (Tùy chọn)",
  "please_select_drop_location": "Vui lòng chọn vị trí thả",
  "back": "Mặt sau",
  "alert_message": "Thành công! Hành động của bạn đã được hoàn thành.",
  "choose_the_driver_from_list": "Chọn trình điều khiển từ danh sách",
  "driver_list": "Danh sách tài xế",
  "rating_for_drivers": "Đánh giá dành cho người lái xe",
  "accepted_driver": "Trình điều khiển được chấp nhận",
  "rejected_driver": "Trình điều khiển bị từ chối",
  "booking_info": "Thông tin đặt chỗ",
  "now_single_stop": "Bây giờ (Dừng một lần)",
  "united_arab_emirates": "Các tiểu vương quốc Ả Rập thống nhất UAE",
  "guest_info": "Thông tin khách",
  "fare_details": "Chi tiết giá vé",
  "base_fare": "Giá vé cơ bản",
  "distance_fare": "Giá vé khoảng cách",
  "waiting_cost": "Chi phí chờ đợi",
  "night_charge": "Phí ban đêm",
  "evening_charge": "Phí buổi tối",
  "toll_fare": "Phí cầu đường",
  "used_wallet_amount": "Số tiền ví đã sử dụng",
  "promo_discount_amount": "Số tiền chiết khấu khuyến mãi",
  "actual_trip_fare": "Giá vé chuyến đi thực tế",
  "payment_mode": "Phương thức thanh toán",
  "trip_route": "Lộ trình chuyến đi",
  "driver_route": "Lộ trình lái xe",
  "trip_log": "Nhật ký chuyến đi",
  "driver_rejection_log": "Nhật ký từ chối trình điều khiển",
  "pickup_gmap": "Nhặt lên",
  "drop_gmap": "Làm rơi",
  "okay": "Đồng ý",
  "are_you_sure_to_end_trip": "Bạn có chắc chắn kết thúc chuyến đi?",
  "trip_in_progress": "Chuyến đi đang diễn ra",
  "waiting_for_payment": "Đang chờ thanh toán",
  "trip_start_time": "Thời gian bắt đầu chuyến đi",
  "trip": "Chuyến đi",
  "booking_price": "Giá đặt chỗ",
  "additional_booking_price": "Giá đặt chỗ bổ sung",
  "car_number": "Số xe",
  "end_trip": "Kết thúc chuyến đi",
  "toggle_dropdown_visibility": "chuyển đổi khả năng hiển thị mật khẩu",
  "parking_charge": "Phí đậu xe",
  "total_fare": "Tổng giá vé",
  "trip_end_time": "Thời gian kết thúc chuyến đi",
  "verify_status": "Xác minh trạng thái",
  "verified": "Đã xác minh",
  "need_to_verify": "Cần xác minh",
  "phone_number": "Số điện thoại",
  "emirates_id": "Mã Emirates",
  "dropoff": "Thả xuống",
  "room_no": "Phòng số",
  "Hrs": "giờ",
  "mins": "Phút",
  "secs": "Giây",
  "trip_distance": "Khoảng cách chuyến đi",
  "waiting_time": "Thời gian chờ đợi",
  "past_trips_cancellation_fare": "Giá vé hủy các chuyến đi trước đây",
  "trip_completed": "CHUYẾN ĐI ĐÃ HOÀN THÀNH",
  "receipt": "Biên lai",
  "verify": "Xác minh",
  "passenger_cancelled": "Hành khách bị hủy",
  "dispatcher_cancelled": "Người điều phối đã hủy",
  "driver_cancelled": "Trình điều khiển đã bị hủy",
  "cancellation_reason": "Lý do hủy",
  "no_show": "Không có mặt",
  "manage_custom_zone": "Quản lý vùng tùy chỉnh",
  "keyword": "Từ khóa",
  "search_by_zone_name_location": "Tìm kiếm theo tên khu vực, vị trí khu vực",
  "status_update": "Cập nhật trạng thái",
  "reason": "Lý do",
  "please_enter_reason": "Vui lòng nhập lý do",
  "save_changes": "Lưu thay đổi",
  "add": "Thêm vào",
  "active": "Tích cực",
  "block": "Khối",
  "trash": "Rác",
  "zone_name": "Tên khu vực",
  "zone_location": "Vị trí khu vực",
  "action": "Hoạt động",
  "model_fare": "Giá vé mẫu (Thêm/Chỉnh sửa)",
  "driver_list_add_edit": "Danh sách trình điều khiển (Thêm/Chỉnh sửa)",
  "push_notification": "Thông báo đẩy",
  "custom_zone_add": "Thêm vùng tùy chỉnh",
  "custom_zone_edit": "Chỉnh sửa vùng tùy chỉnh",
  "zone_amount": "Số lượng vùng",
  "priority": "Sự ưu tiên",
  "additional_information": "Thông tin bổ sung",
  "key": "Chìa khóa",
  "value": "Giá trị",
  "remove_polygon": "Xóa đa giác",
  "add_polygon_required": "Vui lòng thêm Đa giác, Đa giác là bắt buộc!",
  "address": "Địa chỉ",
  "customzone_fare": "Giá vé vùng tùy chỉnh",
  "minimum_kilometers": "Km tối thiểu",
  "minimum_fare": "Giá vé tối thiểu",
  "cancellation_fare": "Giá vé hủy",
  "below_above_kilometers": "Dưới/Trên km",
  "below_kilometers": "Dưới km",
  "above_kilometers": "Trên km",
  "fare_per_minute": "Giá vé mỗi phút",
  "morning_charge": "Phí buổi sáng",
  "morning_timing_from": "Thời gian buổi sáng từ",
  "evening_timing_from": "Thời gian buổi tối từ",
  "evening_timing_to": "Thời gian buổi tối đến",
  "admin_commission": "Ủy ban quản trị",
  "company_commission": "Hoa hồng công ty",
  "driver_commission": "hoa hồng lái xe",
  "corporate_commission": "Ủy ban doanh nghiệp",
  "night_timing_from": "Thời gian ban đêm từ",
  "night_timing_to": "Thời gian ban đêm đến",
  "no1": "KHÔNG",
  "yes1": "ĐÚNG",
  "custom_zone_drivers": "Trình điều khiển vùng tùy chỉnh",
  "add_driver_custom_zone": "Thêm trình điều khiển trong vùng tùy chỉnh này",
  "zone_list": "Danh sách khu vực",
  "manage_offer_zone": "Quản lý vùng ưu đãi",
  "offer_zone_add": "Thêm vùng ưu đãi",
  "offer_zone_edit": "Chỉnh sửa vùng ưu đãi",
  "manage_divisions": "Quản lý bộ phận",
  "search_by_division_name": "Tìm theo tên Phòng",
  "division_name": "Tên bộ phận",
  "edit_division": "Chỉnh sửa bộ phận",
  "add_division": "Thêm phân chia",
  "manage_departments": "Quản lý phòng ban",
  "search_by_department_name": "Tìm kiếm theo tên bộ phận",
  "department_name": "Tên bộ phận",
  "edit_department": "Chỉnh sửa bộ phận",
  "add_department": "Thêm bộ phận",
  "division": "Phân công",
  "invalid_email": "Định dạng email không hợp lệ",
  "phone_must_7_digits": "Số điện thoại phải có 7 chữ số",
  "total_drivers": "Tổng trình điều khiển",
  "unassigned": "Chưa được chỉ định",
  "new_drivers": "Trình điều khiển mới",
  "drivers_shift_status": "Trạng thái chuyển đổi của trình điều khiển",
  "shift_in": "Chuyển vào",
  "shift_out": "Chuyển ra ngoài",
  "offline": "Ngoại tuyến",
  "sign_out": "Đăng xuất",
  "trip_assigned": "Chuyến đi đã chỉ định",
  "busy_in_trip": "Bận rộn trong chuyến đi",
  "driver_search": "Tìm kiếm theo Tên, Email, Tên công ty, Điện thoại, ID duy nhất",
  "previous_reason": "Lý do trước đó",
  "referral_poster": "Áp phích giới thiệu",
  "valid": "Có hiệu lực",
  "expired": "Hết hạn!",
  "open": "Mở",
  "driverInfo": "Thông tin tài xế",
  "driver_unique_id": "ID duy nhất của trình điều khiển",
  "attached_vehicle": "Xe kèm theo",
  "photo": "Ảnh",
  "driver_current_version": "Phiên bản hiện tại của trình điều khiển",
  "location_status": "Trạng thái vị trí",
  "created_date": "Ngày tạo",
  "expiry_status": "Tình trạng hết hạn",
  "manage_driver_record": "Quản lý hồ sơ lái xe",
  "profile_picture": "Ảnh hồ sơ",
  "created_date_and_time": "Ngày và giờ tạo",
  "taxi_no": "Taxi số",
  "manufacturer_and_make_name": "Nhà sản xuất và đặt tên",
  "device_type": "Loại thiết bị",
  "no_of_rows": "Số hàng",
  "export_data": "Xuất dữ liệu",
  "add_driver": "Thêm trình điều khiển",
  "gender": "Giới tính",
  "male": "Nam giới",
  "female": "Nữ giới",
  "please_enter_driver_unique_id": "Vui lòng nhập id duy nhất của trình điều khiển",
  "please_enter_number": "Vui lòng nhập Số điện thoại di động (tối thiểu 7 ký tự)",
  "state_error": "Vui lòng chọn tiểu bang",
  "city_error": "Vui lòng chọn thành phố",
  "additional_info": "Thông tin bổ sung",
  "company_error": "Vui lòng chọn hãng xe",
  "driver_license_id": "ID giấy phép lái xe",
  "driver_license_expiry_date": "Ngày hết hạn của Giấy phép lái xe",
  "rta_card_id": "ID thẻ RTA",
  "rta_card_expiry_date": "Ngày hết hạn thẻ RTA",
  "driver_national_id": "ID quốc gia của tài xế",
  "driver_national_id_expiry_date": "Ngày hết hạn CMND của tài xế",
  "driver_iban_bank_account": "Tài xế Tài khoản ngân hàng IBAN",
  "bank_name": "Tên ngân hàng",
  "please_select_expiry_date": "Vui lòng chọn Ngày hết hạn",
  "please_enter_booking_limit": "Vui lòng nhập giới hạn đặt phòng mỗi ngày",
  "attach_single_taxi": "Đính kèm Taxi đơn",
  "assign_multiple_taxi": "Chỉ định nhiều Taxi",
  "password_not_matched": "Mật khẩu không khớp",
  "documents": "Tài liệu",
  "emirates_id_front_image": "Hình ảnh mặt trước ID Emirates",
  "emirates_id_back_image": "Hình ảnh mặt sau ID Emirates",
  "rta_card_front_image": "Hình ảnh mặt trước thẻ RTA",
  "rta_card_back_image": "Hình ảnh mặt sau thẻ RTA",
  "driving_license_front_image": "Hình ảnh mặt trước của giấy phép lái xe",
  "driving_license_back_image": "Hình ảnh mặt sau giấy phép lái xe",
  "edit_driver": "Chỉnh sửa trình điều khiển",
  "view_banner": "Xem biểu ngữ",
  "download": "Tải xuống",
  "download_vertically": "Tải xuống theo chiều dọc",
  "download_horizontally": "Tải xuống theo chiều ngang",
  "newly_registered_drivers": "Trình điều khiển mới được đăng ký",
  "waiting_for_approval": "Đang chờ phê duyệt",
  "approve": "Chấp thuận",
  "disapprove": "Không chấp thuận",
  "edit_cars": "Chỉnh sửa ô tô",
  "disapprove_driver_confirmation": "Bạn có chắc chắn từ chối trình điều khiển này?",
  "show_report_for": "Hiển thị báo cáo cho",
  "export_driver_status": "Xuất trình điều khiển theo trạng thái",
  "all_driver_records": "Tất cả hồ sơ lái xe",
  "shift_in_driver_records": "Thay đổi hồ sơ tài xế",
  "shift_out_driver_records": "Chuyển hồ sơ tài xế",
  "trip_assigned_driver_records": "Hồ sơ tài xế được chỉ định cho chuyến đi",
  "busy_in_trip_driver_records": "Hồ sơ tài xế bận rộn trong chuyến đi",
  "sign_out_driver_records": "Đăng xuất hồ sơ tài xế",
  "offline_driver_records": "Hồ sơ trình điều khiển ngoại tuyến",
  "manage_loss_found_logs_records": "Quản lý bản ghi nhật ký mất mát/tìm thấy",
  "comments": "Bình luận",
  "driver_id": "ID trình điều khiển",
  "subject": "Chủ thể",
  "description": "Sự miêu tả",
  "image": "Tài liệu",
  "logs": "Nhật ký",
  "no_logs": "Không có nhật ký",
  "select_status": "Chọn trạng thái",
  "approved": "Tán thành",
  "rejected": "Vật bị loại bỏ",
  "please_select_status": "Vui lòng chọn trạng thái",
  "enter_your_commands": "Nhập lệnh của bạn",
  "driver_document_request_record": "Bản ghi yêu cầu tài liệu lái xe",
  "search_by_model_name": "Tìm kiếm theo tên Model",
  "add_model": "Thêm mô hình",
  "edit_model": "Chỉnh sửa mô hình",
  "enter_model_name": "Nhập tên mô hình.",
  "model_size": "Kích thước mô hình",
  "enter_model_size": "Nhập kích thước mô hình.",
  "enter_base_fare": "Nhập giá vé cơ bản",
  "min_km": "KM tối thiểu",
  "min_km_error": "Nhập KM tối thiểu ở dạng số (Ví dụ: 50,60)",
  "min_fare": "Giá vé tối thiểu",
  "min_fare_error": "Nhập giá vé tối thiểu ở dạng số (Ví dụ: 50,60,45)",
  "cancellation_fee": "Phí hủy",
  "below_above_km": "Phạm vi KM Dưới và Trên",
  "below_km_fare": "Giá vé dưới KM ​​(mỗi KM)",
  "above_km_fare": "Giá vé trên KM (mỗi KM)",
  "waiting_charge": "Phí chờ xe (mỗi giờ)",
  "taxi_speed": "Tốc độ taxi",
  "taxi_min_speed": "Tốc độ tối thiểu của taxi",
  "car_waiting_charge": "Phí chờ xe",
  "enter_cancellation_fee": "Nhập phí hủy ở dạng số (ví dụ: 30, 34,56)",
  "enter_below_above_km": "Nhập phạm vi KM Dưới và Trên ở định dạng số (ví dụ: 50)",
  "enter_below_km_fare": "Nhập giá vé KM bên dưới ở định dạng số (ví dụ: 7, 6,50)",
  "enter_above_km_fare": "Nhập giá vé KM ở trên ở định dạng số (ví dụ: 12, 15,50)",
  "enter_waiting_charge": "Nhập xe chờ phí",
  "enter_fare_per_minute": "Nhập giá vé mỗi phút",
  "enter_taxi_speed": "Nhập tốc độ ô tô mỗi giờ",
  "enter_taxi_min_speed": "Nhập giá vé tối thiểu ở định dạng số (ví dụ: 50, 60,45)",
  "above_km": "Giá vé trên KM (mỗi KM)",
  "minutes_fare": "Giá vé mỗi phút",
  "night_fare": "Giá vé đêm",
  "evening_fare": "Giá vé buổi tối",
  "enter_car_waiting_charge": "Nhập phí chờ xe",
  "android_model_unfocus_image": "Hình ảnh không lấy nét của mẫu Android (175x100 pixel)",
  "ios_model_focus_image": "Hình ảnh tiêu điểm mô hình iOS (125x71 pixel)",
  "ios_model_unfocus_image": "Hình ảnh không lấy nét của mô hình iOS (125x71 pixel)",
  "location_model_image": "Hình ảnh mô hình vị trí (418x183 pixel)",
  "taxi_number": "Số taxi",
  "car_manufacturer_name": "Tên nhà sản xuất ô tô",
  "car_make_name": "Tên hãng xe",
  "total_cars": "Tổng số ô tô",
  "search_by_taxi_no_company_name": "Tìm theo Mã số Taxi, Tên Công ty",
  "car_model_year": "Mẫu xe năm",
  "car_owner_name": "Tên chủ xe",
  "car_color": "Màu xe",
  "taxi_motor_expiry_date": "Xe Taxi Ngày Hết Hạn",
  "add_car": "Thêm xe",
  "edit_car": "Chỉnh sửa ô tô",
  "taxi_unique_id": "Id duy nhất của taxi",
  "taxi_insurance_number": "Số bảo hiểm taxi",
  "car_insurance_expiry_date": "Ngày hết hạn bảo hiểm ô tô",
  "car_chassis_number": "Số khung xe",
  "please_select_state": "Vui lòng chọn tiểu bang",
  "please_select_city": "Vui lòng chọn thành phố",
  "car_image_340_pixel": "Hình ảnh ô tô (340x183 pixel)",
  "select_car_company": "Vui lòng chọn hãng xe",
  "please_select_country": "Vui lòng chọn một quốc gia",
  "car": "Xe hơi",
  "manage_assigned_car_records": "Quản lý hồ sơ xe được giao",
  "search_by_driver_name": "Tìm kiếm theo tên tài xế",
  "active_assigned_cars": "Active_AssignedÔ tô",
  "block_assigned_cars": "Block_AssignedÔ tô",
  "trash_assigned_cars": "Thùng rác_Ô tô được giao",
  "unassigned_assigned_cars": "Xe chưa được chỉ định_Đã chỉ định",
  "all_assigned_cars": "All_AssignedÔ tô",
  "total_subscription_list": "Tổng số danh sách đăng ký",
  "requested_date": "Ngày yêu cầu",
  "subscribed_date": "Ngày đăng ký",
  "unsubscribe_cancel_date": "Hủy đăng ký/Hủy ngày",
  "duration_days_hours_minutes_seconds": "Thời lượng (Ngày Giờ Phút Giây)",
  "subscription_status": "Trạng thái đăng ký",
  "user": "người dùng",
  "subscription_amount": "Số tiền đăng ký",
  "log_count": "Số lượng nhật ký",
  "total_amount": "Tổng số tiền",
  "total_active": "Tổng số hoạt động",
  "total_subscribe": "Tổng số lượt đăng ký",
  "total_unsubscribe": "Tổng số Hủy đăng ký",
  "add_driver_to_subscription": "Thêm trình điều khiển vào đăng ký",
  "choose_driver_to_subscribe": "Chọn Driver để đăng ký",
  "request_subscription": "Yêu cầu đăng ký",
  "activate_subscription": "Kích hoạt đăng ký",
  "subscription_setting": "Cài đặt đăng ký",
  "auto": "Tự động",
  "manual": "Thủ công",
  "content": "Nội dung",
  "hold": "Giữ",
  "activate": "Kích hoạt",
  "date_filter_type": "Loại bộ lọc ngày",
  "search_taxi_number": "Tìm kiếm theo số taxi",
  "reason_for_hold_subscription": "Lý do giữ đăng ký",
  "reason_for_approve_subscription": "Lý do phê duyệt đăng ký",
  "reason_for_cancel_subscription": "Lý do hủy đăng ký",
  "other_drivers": "Trình điều khiển khác",
  "subscription_date": "Ngày đăng ký",
  "unsubscribe_date": "Ngày hủy đăng ký",
  "duration": "Khoảng thời gian",
  "all_taxi_subscription_records": "Tất cả hồ sơ đăng ký taxi",
  "active_taxi_subscription_records": "Hồ sơ đăng ký taxi đang hoạt động",
  "subscribed_taxi_subscription_records": "Hồ sơ đăng ký taxi đã đăng ký",
  "unsubscribed_taxi_subscription_records": "Hồ sơ đăng ký taxi chưa đăng ký",
  "cancelled_taxi_subscription_records": "Hồ sơ đăng ký taxi đã bị hủy",
  "hold_taxi_subscription_records": "Giữ hồ sơ đăng ký taxi",
  "pending_taxi_subscription_records": "Hồ sơ đăng ký taxi đang chờ xử lý",
  "other_drivers_taxi_subscription_records": "Hồ sơ đăng ký taxi của các tài xế khác",
  "manufacturer_list": "Danh sách nhà sản xuất",
  "edit_manufacturer": "Chỉnh sửa nhà sản xuất",
  "add_manufacturer": "Thêm nhà sản xuất",
  "car_make_list": "Danh sách xe ô tô",
  "search_by_car_make_name": "Tìm kiếm theo tên hãng xe...",
  "edit_car_make": "Chỉnh sửa chế tạo ô tô",
  "add_car_make": "Thêm hãng xe",
  "upload_car_make_image": "Tải lên hình ảnh ô tô",
  "car_make_image_allowed_format": "Các định dạng được phép: .jpeg, .jpg, .png, .webp",
  "download_document": "Tải tài liệu",
  "total_company": "Tổng công ty",
  "manage_company_record": "Quản lý hồ sơ công ty",
  "search_by_company_name_user_name_email": "Tìm kiếm theo tên công ty, tên người dùng, ID email",
  "no_of_cars": "Số lượng ô tô",
  "no_of_drivers": "Số lượng trình điều khiển",
  "add_company": "Thêm công ty",
  "personal_information": "Thông tin cá nhân",
  "last_name": "Họ",
  "company_image": "Hình ảnh công ty (340x183 pixel)",
  "choose_theme": "Chọn chủ đề",
  "primary_color": "Màu Chính *",
  "secondary_color": "Màu phụ *",
  "primary_color_required": "Màu chính là bắt buộc",
  "secondary_color_required": "Màu phụ là bắt buộc",
  "document_required": "Tài liệu là bắt buộc",
  "shift_timing_from": "Chuyển thời gian từ",
  "shift_timing_to": "Chuyển thời gian sang",
  "shift_hours": "Số giờ làm ca",
  "edit_company": "Chỉnh sửa công ty",
  "company_info": "Thông tin công ty",
  "company_car": "Xe công ty",
  "company_driver": "Tài xế công ty",
  "promo_code_discount": "Mã khuyến mãi Giảm giá (%)",
  "start_date": "Ngày bắt đầu",
  "expiry_date": "Ngày hết hạn",
  "mobile_app_visibility": "Khả năng hiển thị ứng dụng di động",
  "promocode_limit": "Giới hạn mã khuyến mại",
  "offer_type": "Loại ưu đãi",
  "promocode_generation_type": "Loại tạo mã khuyến mại",
  "start_date_from": "Ngày bắt đầu từ",
  "start_date_to": "Ngày bắt đầu đến",
  "expiry_date_from": "Ngày hết hạn từ",
  "expiry_date_to": "Ngày hết hạn đến",
  "search_by_promocode": "Tìm kiếm theo Mã khuyến mại",
  "manage_promocode_record": "Quản lý bản ghi mã khuyến mại",
  "promocode": "Mã khuyến mại",
  "promocode_discount": "Giảm giá mã khuyến mãi",
  "enabled": "Đã bật",
  "disabled": "Tàn tật",
  "promocode_flow": "Luồng mã khuyến mại",
  "lifestyle_promotion_flow": "Luồng quảng cáo lối sống",
  "dynamic": "Năng động",
  "used_promocode_count": "Số lượng mã khuyến mãi đã sử dụng",
  "unused_promocode_count": "Số lượng mã khuyến mãi chưa sử dụng",
  "add_promocode": "Thêm mã khuyến mại",
  "select": "Lựa chọn",
  "all_passenger": "Tất cả hành khách",
  "select_passenger": "Chọn hành khách",
  "new_passengers": "Hành khách mới",
  "categories": "Thể loại",
  "limit_per_passenger": "Giới hạn cho mỗi hành khách",
  "promocode_maximum_limit": "Giới hạn tối đa của mã khuyến mại",
  "discount_percentage": "Tỷ lệ phần trăm",
  "discount_flat": "Phẳng",
  "ticket": "Vé",
  "discount_type": "Loại giảm giá",
  "percentage": "Tỷ lệ phần trăm",
  "flat": "Phẳng",
  "ticket_count": "Số lượng vé",
  "discount_in_percent": "Giảm giá theo %",
  "maximum_discount": "Giảm giá tối đa",
  "flat_amount": "Số tiền cố định",
  "select_activity_packages": "Chọn gói hoạt động",
  "select_staycation_packages": "Chọn gói lưu trú",
  "select_bus_rental_packages": "Chọn gói thuê xe buýt",
  "select_holipackages": "Chọn gói kỳ nghỉ",
  "select_car_rental_packages": "Chọn gói thuê xe",
  "select_packages": "Chọn gói",
  "offer_for_other_services": "Ưu đãi cho các dịch vụ khác *",
  "offer_zone": "Khu vực ưu đãi *",
  "min_KM": "KM tối thiểu",
  "max_km": "KM tối đa",
  "edit_promocode": "Chỉnh sửa mã khuyến mại",
  "view_dynamic_promocode": "Xem lịch sử mã khuyến mại động",
  "dynamic_promocode_history": "Lịch sử mã khuyến mãi động",
  "user_details": "Chi tiết người dùng",
  "not_used": "không được sử dụng",
  "used": "Đã sử dụng",
  "total_corporate": "Tổng doanh nghiệp",
  "manage_corporate_record": "Quản lý hồ sơ doanh nghiệp",
  "search_by_name_email": "Tìm kiếm theo Tên, Email",
  "add_edit_zone_group": "Thêm/Chỉnh sửa nhóm vùng",
  "zone_group_fare_mapping_new": "Bản đồ giá vé nhóm khu vực Mới (Hãng xe)",
  "active_corporates": "Doanh nghiệp đang hoạt động",
  "block_corporates": "khối doanh nghiệp",
  "trash_corporates": "Tập đoàn rác",
  "all_corporates": "Tất cả các công ty",
  "add_corporate": "Thêm công ty",
  "edit_corporate": "Chỉnh sửa công ty",
  "fare_visibility": "Tầm nhìn giá vé cho người lái xe",
  "fare_settings": "Cài đặt giá vé",
  "zone_to_zone_fare": "Giá vé từ vùng này sang vùng khác",
  "company_fare": "giá vé công ty",
  "trade_license_image": "Hình ảnh giấy phép thương mại (340x183 pixel)",
  "corporate_logo": "Logo doanh nghiệp (340x183 pixel)",
  "contract_document": "Văn bản hợp đồng",
  "below_above_km_range": "Dưới và Trên Phạm vi KM",
  "file_allowed_format": "Các định dạng được phép .jpeg,.jpg,.png,.pdf,.doc,.docx",
  "img_allowed_format": "Các định dạng được phép .jpeg,.jpg,.png",
  "do_you_want_to_add_new_password": "Bạn có muốn thêm mật khẩu mới?",
  "password_cannot_be_empty": "Mật khẩu không thể trống",
  "download_trade_license": "Tải Giấy phép Thương mại",
  "download_contract_document": "Tải tài liệu hợp đồng",
  "inspection_alert_duration": "Thời lượng cảnh báo kiểm tra",
  "inspection_setting": "Cài đặt kiểm tra",
  "duration_cannot_be_zero": "Thời lượng không thể là 0",
  "manage_zone_group": "Quản lý nhóm khu vực",
  "search_by_zone_group_name": "Tìm kiếm theo tên nhóm khu vực",
  "manage_zone_group_record": "Quản lý bản ghi nhóm khu vực",
  "zone_group_name": "Tên nhóm khu vực",
  "zone_names": "Tên khu vực",
  "add_zone_group": "Thêm nhóm khu vực",
  "add_zone_under_this_group": "Thêm vùng trong nhóm vùng này",
  "edit_zone_group": "Chỉnh sửa nhóm khu vực",
  "active_zone_groups": "Nhóm vùng hoạt động",
  "block_zone_groups": "Nhóm vùng chặn",
  "trash_zone_groups": "Nhóm vùng rác",
  "all_zone_groups": "Tất cả các nhóm khu vực",
  "corporate_amount": "Số tiền doanh nghiệp",
  "driver_amount": "Số tiền tài xế",
  "company_amount": "Số tiền của công ty",
  "admin_amount": "Số tiền quản trị",
  "end_passenger_amount": "Số lượng hành khách cuối cùng",
  "remove_confirmation": "Bạn có chắc chắn muốn xóa không?",
  "delete_confirmation": "Bạn có chắc chắn muốn xóa?",
  "zone_group_fare_mapping": "Bản đồ giá vé nhóm khu vực (Hãng ô tô)",
  "zone_from": "Vùng Từ",
  "zone_to": "Khu tới",
  "zone_group_from": "Nhóm khu vực từ",
  "zone_group_to": "Nhóm khu vực tới",
  "remove": "Di dời",
  "corporate_id": "Mã công ty",
  "package_type": "Loại gói",
  "add_package": "Thêm gói",
  "package_hrs_days": "Gói Số giờ/Ngày",
  "package_hours_days": "Số giờ/ngày trọn gói",
  "search_by_zone_name": "Tìm kiếm theo tên khu vực",
  "package_distance": "Khoảng cách gói hàng",
  "package_amount": "Số tiền gói",
  "edit_zone": "Chỉnh sửa vùng",
  "add_zone": "Thêm vùng",
  "zone_name_required": "Tên vùng là bắt buộc",
  "polygon_required": "Vui lòng thêm Đa giác, Đa giác là bắt buộc!",
  "active_zones": "Vùng hoạt động",
  "block_zones": "Khu khối",
  "trash_zones": "Khu rác",
  "all_zones": "Tất cả các khu vực",
  "update_wallet_amount": "Cập nhật số tiền trên ví",
  "amount": "Số lượng",
  "enter_amount": "Vui lòng nhập số tiền",
  "enter_password": "Vui lòng nhập mật khẩu",
  "enter_comments": "Vui lòng nhập Bình luận",
  "manage_passengers_record": "Quản lý hồ sơ hành khách",
  "active_passengers": "Hành khách đang hoạt động",
  "block_passengers": "Chặn hành khách",
  "trash_passengers": "Hành khách rác",
  "all_passengers": "Tất cả hành khách",
  "wallet_amount": "Số tiền trên ví",
  "user_information": "Thông tin người dùng",
  "otp_count": "Số lượng OTP",
  "reset_otp_count": "Đặt lại số lượng OTP",
  "reset_otp_count_confirmation": "Bạn có chắc chắn muốn đặt lại Số lượng OTP không?",
  "package_additional_fare_per_km": "Giá vé bổ sung trọn gói (mỗi KM )",
  "package_additional_fare_per_hrs": "Giá vé bổ sung theo gói (theo giờ)",
  "short_description": "Mô tả ngắn",
  "long_description": "Mô tả dài",
  "edit_package": "Chỉnh sửa gói",
  "multiple_dropoff_locations_add_edit": "Nhiều địa điểm giao hàng (Thêm/Chỉnh sửa)",
  "customize_fare": "Tùy chỉnh giá vé",
  "total_location": "Tổng số vị trí",
  "manage_location_record": "Quản lý bản ghi vị trí",
  "search_by_name_email_company": "Tìm kiếm theo Tên, Email, Tên Công ty",
  "active_location": "Vị trí hoạt động",
  "block_location": "Chặn vị trí",
  "trash_location": "Vị trí thùng rác",
  "all_location": "Tất cả vị trí",
  "edit_location": "Chỉnh sửa vị trí",
  "add_location": "Thêm vị trí",
  "address_is_required": "Địa chỉ là bắt buộc",
  "location_name": "Tên địa điểm",
  "queue_limit": "Giới hạn hàng đợi",
  "drop_your_message": "Gửi tin nhắn của bạn",
  "commission_type": "Loại hoa hồng",
  "subscription": "Đăng ký",
  "polygon_restriction": "Hạn chế đa giác",
  "enable": "Cho phép",
  "disable": "Vô hiệu hóa",
  "cancel_password_required": "Hủy mật khẩu *",
  "cancel_password": "Hủy mật khẩu",
  "cancel_password_empty": "Hủy bỏ Mật khẩu không được để trống",
  "confirm_cancel_password_required": "Xác nhận Hủy mật khẩu *",
  "confirm_cancel_password": "Xác nhận Hủy mật khẩu",
  "driver_limit_start_supervisor_trip": "Giới hạn tài xế để bắt đầu chuyến đi của người giám sát",
  "location_type": "Loại vị trí",
  "general": "Tổng quan",
  "hotel": "Khách sạn",
  "target_trip_count": "Số chuyến đi mục tiêu",
  "restrict_driver_remove_from_queue": "Hạn chế xóa trình điều khiển khỏi hàng đợi",
  "restriction_hours": "Số giờ hạn chế",
  "location_fare_edit": "Giá vé địa điểm Chỉnh sửa",
  "quick_trip_option_in_supervisor_app": "Tùy chọn chuyến đi nhanh trong ứng dụng giám sát",
  "driver_app_visibility": "Khả năng hiển thị ứng dụng trình điều khiển",
  "add_driver_to_queue": "Thêm trình điều khiển vào hàng đợi",
  "queue_alert_notification": "Thông báo cảnh báo hàng đợi",
  "queue_alert_notification_time": "Thời gian thông báo cảnh báo xếp hàng (Tính bằng phút)",
  "open_timing_from": "Mở thời gian từ",
  "open_timing_to": "Mở thời gian đến",
  "get_ready_queue_reorder_time_in_minutes": "Nhận thời gian sắp xếp lại hàng đợi sẵn sàng (Tính bằng phút)",
  "document_verification": "Xác minh tài liệu",
  "verification_type": "Loại xác minh",
  "auto_verify": "Tự động xác minh",
  "manual_verify": "Xác minh thủ công",
  "waiting_queue_n_drivers_to_reorder": "Hàng đợi - N số tài xế sắp xếp lại",
  "n_drivers_update": "N số lượng tài xế (Vị trí bên ngoài) để cập nhật thời gian hiện tại",
  "waiting_queue_time_in_minutes": "Hàng đợi - Thời gian tính bằng phút để sắp xếp lại",
  "driver_time_update": "Thời gian (phút - Lái xe - Vị trí bên ngoài) cập nhật ngày giờ hiện tại",
  "queue_setting": "Cài đặt hàng đợi",
  "manual_setting": "Thủ công - Chỉ người giám sát mới có thể thêm tài xế vào hàng đợi",
  "driver_add_to_queue_restriction": "Hạn chế thêm trình điều khiển vào hàng đợi",
  "multiple": "Nhiều",
  "location_driver_details": "Chi tiết về trình điều khiển vị trí",
  "add_driver_under_location": "Thêm trình điều khiển trong vùng vị trí này",
  "location_list": "Danh sách vị trí",
  "notes": "Ghi chú",
  "multiple_dropoff_locations": "Nhiều địa điểm trả hàng",
  "latitude": "Vĩ độ *",
  "longitude": "Kinh độ *",
  "fare": "Giá vé *",
  "peak_hour_fare": "Giá vé giờ cao điểm",
  "contract_amount": "Số tiền hợp đồng",
  "location_customized_fare": "Giá vé tùy chỉnh theo vị trí",
  "models": "Người mẫu",
  "queue_location_list": "Danh sách vị trí xếp hàng",
  "main_queue": "Hàng đợi chính",
  "waiting_queue": "Hàng đợi",
  "queue_location_list_label": "Danh sách vị trí xếp hàng",
  "model_list": "Danh sách mẫu",
  "note_model_filter": "Lưu ý: Bạn không thể sắp xếp lại hàng đợi nếu bạn áp dụng bộ lọc mô hình",
  "car_plate_number": "Biển số xe",
  "confirm_queue_change": "Bạn có chắc chắn muốn đổi thành",
  "confirm_remove_driver": "Bạn có chắc chắn xóa trình điều khiển khỏi Hàng đợi không?",
  "group_name": "Tên nhóm",
  "kiosk_list": "Danh sách ki-ốt",
  "sunday": "Chủ nhật",
  "monday": "Thứ hai",
  "tuesday": "Thứ ba",
  "wednesday": "Thứ Tư",
  "thursday": "Thứ năm",
  "friday": "Thứ sáu",
  "saturday": "Thứ bảy",
  "count": "Đếm",
  "count_exceeded": "Số lượng thông báo không thể kéo dài hơn",
  "location_queue_driver_logs_record": "Bản ghi nhật ký trình điều khiển hàng đợi vị trí",
  "queue_position": "Vị trí xếp hàng",
  "supervisor_name": "Tên người giám sát",
  "supervisor_unique_id": "Id duy nhất của người giám sát",
  "added_from": "Đã thêm từ",
  "search_by_toll_name": "Tìm kiếm theo tên thu phí",
  "manage_toll_record": "Quản lý hồ sơ thu phí",
  "toll_name": "Tên thu phí",
  "toll_location": "Địa điểm thu phí",
  "toll_amount": "Số tiền thu phí",
  "add_toll": "Thêm phí",
  "edit_toll": "Chỉnh sửa phí",
  "toll_location_is_required": "Địa điểm thu phí là bắt buộc",
  "manage_supervisor": "Quản lý giám sát",
  "manage_supervisor_record": "Quản lý hồ sơ giám sát",
  "active_supervisors_report": "Báo cáo giám sát hoạt động",
  "block_supervisors_report": "Báo cáo giám sát viên bị chặn",
  "trash_supervisors_report": "Báo cáo của người giám sát thùng rác",
  "all_supervisors_report": "Báo cáo của tất cả giám sát viên",
  "edit_supervisor": "Chỉnh sửa người giám sát",
  "add_supervisor": "Thêm người giám sát",
  "supervisor_details": "Chi tiết về người giám sát",
  "view_supervisor": "Xem người giám sát",
  "send_push_notification": "Gửi thông báo đẩy",
  "supervisor_not_login_error": "Có vẻ như người giám sát này chưa đăng nhập!",
  "supervisor_monitor_list": "Danh sách giám sát giám sát",
  "push_id": "Id đẩy",
  "video_link": "Liên kết video",
  "updated_date": "Ngày cập nhật",
  "please_enter_remarks": "Vui lòng nhập nhận xét",
  "supervisor_login_time": "Thời gian đăng nhập của người giám sát",
  "supervisor_logout_time": "Thời gian đăng xuất của người giám sát",
  "supervisor_login_location": "Vị trí đăng nhập của người giám sát",
  "supervisor_logout_location": "Vị trí đăng xuất của người giám sát",
  "login_image": "Hình ảnh đăng nhập",
  "logout_image": "Hình ảnh đăng xuất",
  "shift_in_duration_hh_mm_ss": "Thời lượng dịch chuyển IN (HH:MM:SS)",
  "login_duration": "Thời lượng đăng nhập (HH:MM:SS)",
  "select_columns_you_need": "Chọn cột bạn cần",
  "export_selected_data": "Xuất dữ liệu đã chọn",
  "remove_all": "Xóa tất cả",
  "select_all": "Chọn tất cả",
  "alert": "Báo động"
};

export default vi;
