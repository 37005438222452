/* eslint-disable prettier/prettier */

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  // themeLayout: 'vertical',
  themeLayout: 'mini', // for mini side nav - edited by dev2
  themeColorPresets: 'default',
  themeStretch: false,
};
