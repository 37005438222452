/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { PATH_APP, PATH_APP_CORP, PATH_APP_WEBBOOK } from 'src/routes/paths';
import { Redirect, useHistory } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { useEffect, useState } from 'react';
import { RSLUrl } from 'src/config';
import CryptoJS from 'crypto-js';
// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
 

  const history = useHistory();


  const { isLoading, isAuthenticated, user } = useAuth();

  const accessToken = localStorage.getItem('accessToken');
  const queryParams = new URLSearchParams(window.location.search);

  const encryptedData = queryParams.get('data');
  const [isAccess, setIsAccess] = useState(true);
  const [decryptedData, setDecryptedData] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const { commonAdmin_URL, secret_key } = RSLUrl;

  useEffect(() => {
    if (encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secret_key);
        const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setEmail(decryptedData?.email);
        setPassword(decryptedData?.password);
        setDecryptedData(originalData);
        
      } catch (error) {
        console.error("Decryption failed:", error);
      }
    }
    else{
      setIsAccess(false);
    }
  }, [encryptedData]);

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }


  // console.log("decryptedData",decryptedData);
  
  const urlParts = window.location.pathname.split('/');
  const desiredPart = urlParts[2];
  const autoLoginEmail = email;
 

  const autoLoginPassword = password;

  // console.log(desiredPart)
  // console.log(accessToken)
  // console.log(autoLoginPassword)
  // console.log(autoLoginEmail)

  if (
    !isAccess &&
     desiredPart === 'login' &&
    !accessToken &&
    !autoLoginPassword &&
    !autoLoginEmail
  ) {
    const removeActiveTabParam = 'removeactivetab';
    localStorage.setItem('isLoggedIn', '1');
    const logoutParam = 'logout'; // Add your logout parameter here
    const logoutUrl = `${commonAdmin_URL}?${removeActiveTabParam}&${logoutParam}`;
    // const logoutUrl = `http://localhost:3033/login?${removeActiveTabParam}&${logoutParam}`

    window.location.href = logoutUrl;
  } else if (desiredPart === 'corporateLogin') {
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loginType', 'CP');
  } else if (desiredPart === 'webBookerLogin') {
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loginType', 'WB');
  } else if (desiredPart === 'manualLogin') {
    // console.log("manual login comes")
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loginType', 'A');
  }

  // console.log('userDatas:', user);

  // if (isAuthenticated) {
  //   if (user?.userInfo?.userType === 'CP') {
  //     // console.log("Check:CP");
  //     return <Redirect to={PATH_APP_CORP.root} />;
  //   } else if (user?.userInfo?.userType === 'WB') {
  //     return <Redirect to={PATH_APP_WEBBOOK.root} />;
  //   } else {
  //     // console.log("Check:A");
  //     return <Redirect to={PATH_APP.root} />;
  //   }
  // }

  if (isAuthenticated) {
    if (user?.userInfo?.userType === 'CP') {
      // console.log("Check:CP");
      return <Redirect to={PATH_APP_CORP.root} />;
    } else if (user?.userInfo?.userType === 'WB') {
      return <Redirect to={PATH_APP_WEBBOOK.root} />;
    } else {
      // console.log("Check:A");
      return <Redirect to={PATH_APP.root} />;
    }
  }

  return <>{children}</>;
}

export default GuestProtect;
