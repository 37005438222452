/* eslint-disable prettier/prettier */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  "dashboard": "Tableau de bord",
    "nds": "NDS",
    "dispatch_map": "Carte de répartition",
    "orders": "Ordres",
    "manage_permission": "Gérer les autorisations",
    "manage_handover": "Gérer le transfert",
    "custom_zone": "Zone personnalisée",
    "lifestyle_offer_zone": "Zone d'offres de style de vie",
    "divisions": "Divisions",
    "departments": "Départements",
    "drivers": "Pilotes",
    "manage_drivers": "Gérer les pilotes",
    "manage_new_drivers": "Gérer les nouveaux pilotes",
    "export_driver_list": "Exporter la liste des pilotes",
    "manage_lost_found_logs": "Gérer les journaux des objets perdus/trouvés",
    "driver_document_request": "Demande de document de conducteur",
    "cars": "Voitures",
    "manage_models": "Gérer les modèles",
    "manage_cars": "Gérer les voitures",
    "assign_car": "Attribuer une voiture",
    "manage_assigned_cars": "Gérer les voitures attribuées",
    "taxi_subscription_list": "Liste d'abonnement aux taxis",
    "car_manufacturer": "Constructeur automobile",
    "car_make": "Marque de voiture",
    "companies": "Entreprises",
    "manage_company": "Gérer l'entreprise",
    "manage_promocode": "Gérer le code promotionnel",
    "corporates": "Entreprises",
    "manage_corporate": "Gérer l'entreprise",
    "corporate_packages": "Forfaits corporatifs",
    "manage_zone": "Gérer la zone",
    "zone_map_view": "Vue de la carte des zones",
    "passengers": "Passagers",
    "manage_passengers": "Gérer les passagers",
    "passenger_packages": "Forfaits passagers",
    "locations": "Emplacements",
    "known_locations": "Emplacements connus",
    "known_location_country": "Gérer le pays de localisation connu",
    "manage_category": "Gérer la catégorie",
    "manage_known_loc": "Gérer l'emplacement connu",
    "manage_location": "Gérer l'emplacement",
    "manage_queue_location": "Gérer l'emplacement de la file d'attente",
    "location_group": "Groupe d'emplacement",
    "location_log": "Journal de localisation",
    "location_queue_driver_log": "Journal du pilote de file d'attente d'emplacement",
    "tolls": "Péages",
    "manage_tolls": "Gérer les péages",
    "supervisor": "Superviseur",
    "supervisor_trip_reports": "Rapports de déplacement du superviseur",
    "supervisor_logs": "Journaux du superviseur",
    "discount": "Rabais",
    "mobile_promotion": "Promotion mobile",
    "marketing": "Commercialisation",
    "lifestyle_location": "Emplacement de style de vie",
    "manage_referral_settings": "Gérer les paramètres de parrainage",
    "reports": "Rapports",
    "driver_shift_hours": "Heures de travail des chauffeurs",
    "settlement": "Règlement",
    "driver_status": "Statut du conducteur",
    "exit_record": "Quitter l'enregistrement",
    "geo_report": "Rapport géographique",
    "location_queue_driver_logs": "Journaux du pilote de file d'attente d'emplacement",
    "all_other_reports": "Tous les autres rapports",
    "driver_rejection_report": "Rapport de rejet du conducteur",
    "trip_reports": "Rapports de voyage",
    "all_transactions": "toutes transactions",
    "completed_transactions": "Opérations terminées",
    "cancelled_transactions": "Transactions annulées",
    "account_reports": "Rapports de compte",
    "activity_logs": "Journaux d'activité",
    "historical_data": "Données historiques",
    "settings": "Paramètres",
    "site_settings": "Paramètres du site",
    "sms_templates": "Modèles de SMS",
    "admin_smtp_settings": "Paramètres SMTP de l'administrateur",
    "company_smtp_settings": "Paramètres SMTP de l'entreprise",
    "admin_sms_settings": "Paramètres SMS de l'administrateur",
    "company_sms_settings": "Paramètres SMS de l'entreprise",
    "manage_country": "Gérer le pays",
    "manage_state": "Gérer l'état",
    "manage_city": "Gérer la ville",
    "manage_social_network": "Gérer le réseau social",
    "manage_google_settings": "Gérer les paramètres Google",
    "rider": "Cavalier",
    "this_report_displays_the_total_driver_shift_hours_for_the_selected_day": "Ce rapport affiche le nombre total d'heures de travail du conducteur pour le jour sélectionné !",
    "select_company": "Sélectionnez une entreprise",
    "total_active_hours": "Nombre total d'heures d'activité",
    "active_drivers": "Pilotes actifs",
    "total_vehicle": "Véhicule total",
    "total_driver": "Pilote total",
    "total_shift_in_hours": "Changement total en heures",
    "total_shift_out_hours": "Nombre total d'heures de travail",
    "shift_list": "Liste des équipes",
    "export": "Exporter",
    "search": "Recherche",
    "device_id": "Identifiant de l'appareil",
    "unique_id": "Identifiant unique",
    "driver_name": "Nom du pilote",
    "vehicle_model": "Modèle de véhicule",
    "Company": "Entreprise",
    "engine_hours": "Heures de moteur",
    "idle_hours": "Heures d'inactivité",
    "shift_in_hours": "Changement d'heures",
    "shift_out_hours": "Horaires décalés",
    "rows_per_page": "Lignes par page",
    "driver_info": "Informations sur le conducteur",
    "general_info": "Informations générales",
    "gps": "GPS",
    "software_info": "Informations sur le logiciel",
    "social": "Sociale",
    "view_history": "Afficher l'historique",
    "from_date": "À partir de la date",
    "end_date": "Date de fin",
    "cancel": "Annuler",
    "shift_reports": "Rapports de quart de travail",
    "fare_update": "Mise à jour des tarifs",
    "update": "Mise à jour",
    "no_data_found": "Aucune donnée trouvée",
    "data_not_found": "Données introuvables",
    "file_upload": "Téléchargement de fichiers",
    "password": "Mot de passe",
    "edit": "Modifier",
    "passenger_name": "Nom du passager",
    "trip_fare": "Tarif du voyage",
    "emirate_id": "Carte d'identité de l'émirat",
    "passenger_phone_number": "Numéro de téléphone du passager",
    "free": "Gratuit",
    "s_no": "S. Non",
    "busy": "Occupé",
    "date": "Date",
    "log_in_time": "Heure de connexion",
    "shift_in_duration": "Changement de durée",
    "shift_out_duration": "Durée du décalage",
    "inmove": "Déménager",
    "idle": "Inactif",
    "lat_lng": "LatLng",
    "accuracy": "Précision",
    "altitude": "Altitude",
    "bearing": "Palier",
    "speed": "Vitesse",
    "last_update": "Dernière mise à jour",
    "profile": "Profil",
    "android": "Androïde",
    "ios": "IOS",
    "user_profile_management": "Gestion des profils utilisateur",
    "ok": "D'accord",
    "change_password": "Changer le mot de passe",
    "new_password": "Nouveau mot de passe",
    "confirm_password": "Confirmez le mot de passe",
    "please_enter_new_password": "Veuillez entrer un nouveau mot de passe",
    "please_enter_confirm_password": "Veuillez saisir le mot de passe confirmé",
    "submit": "Soumettre",
    "reset": "Réinitialiser",
    "view_more": "Voir plus",
    "driver_information": "Informations sur le conducteur",
    "first_name": "Prénom",
    "email": "E-mail",
    "mobile_number": "Numéro de portable",
    "otp": "Bureau du Procureur",
    "date_of_birth": "Date de naissance",
    "booking_limit_per_day": "Limite de réservation par jour",
    "rating_points": "Points de notation",
    "out_of": "De",
    "referral_code": "Code de référence",
    "my_current_location": "Ma position actuelle",
    "company_name": "Nom de l'entreprise",
    "company_information": "Informations sur l'entreprise",
    "company_address": "Adresse de l'entreprise",
    "country": "Pays",
    "state": "État",
    "city": "Ville",
    "i_am_free_online": "Je suis libre - En ligne",
    "shift_out_offline": "Shift Out - Hors ligne",
    "i_am_busy_in_trip": "je suis occupé en voyage",
    "signed_out": "DÉSIGNÉ",
    "trip_id": "Identifiant du voyage",
    "logout": "Déconnexion",
    "refresh": "Rafraîchir",
    "last_updated": "Dernière mise à jour",
    "shift_history": "Historique des quarts de travail",
    "show_chart": "Afficher le graphique",
    "show_list": "Afficher la liste",
    "referral_history": "Historique des références",
    "total_earned": "Total gagné",
    "pending": "En attente",
    "completed_journeys": "Voyages terminés",
    "current_month": "Mois en cours",
    "previous_month": "Mois précédent",
    "mail_settings": "Paramètres de messagerie",
    "send_mail": "Envoyer un courrier",
    "trip_reports_title": "Rapports de voyage (nombre terminé)",
    "subscription_reports_title": "Rapports d'abonnement",
    "driver_shift_reports_title": "Rapports de changement de conducteur",
    "location_queue_title": "File d'attente d'emplacement (nombre de pilotes)",
    "email_settings": "Paramètres de messagerie",
    "emails": "E-mails",
    "emails_helper_text": "Les e-mails doivent ressembler à abc@gmail.com,xyz@gmail.com",
    "time_to_send": "Il est temps d'envoyer un e-mail",
    "start_time": "Heure de début",
    "this_field_is_required": "Ce champ est obligatoire",
    "success": "Succès",
    "error_message": "Une erreur inattendue s'est produite.",
    "mobile_b2c_now_booking": "Mobile B2C - Réservation immédiate",
    "mobile_b2c_later_booking": "Mobile B2C - Réservation ultérieure",
    "admin_b2c_now_booking": "Administrateur - B2C Réservation Maintenant",
    "admin_b2c_later_booking": "Administrateur - Réservation ultérieure B2C",
    "admin_b2b_now_booking": "Administrateur - B2B Réservation Maintenant",
    "admin_b2b_later_booking": "Administrateur - Réservation ultérieure B2B",
    "corporate_b2b_now_booking": "Entreprise - B2B Réservation Maintenant",
    "corporate_b2b_later_booking": "Entreprise - Réservation ultérieure B2B",
    "walkin_trip": "Voyage sans rendez-vous",
    "driver_booking": "Réservation de chauffeur",
    "location_trip": "Localisation Voyage",
    "website": "Site web",
    "total_trips": "Total des voyages",
    "tooltip_completed_transaction": "Accédez aux rapports de transactions terminées",
    "requested": "Demandé",
    "subscribed": "Abonné",
    "cancelled": "Annulé",
    "unsubscribed": "Désabonné",
    "unsubscription_pending": "Désabonnement en attente",
    "on_hold": "En attente",
    "total_active_subscriber_count": "Nombre total d'abonnés actifs",
    "tooltip": "Accédez à la liste d'abonnement aux taxis",
    "0_8_hours": "0-8 heures",
    "8_16_hours": "8-16 heures",
    "16_24_hours": "16-24 heures",
    "go_to_dashboard": "Accédez aux rapports de changement de conducteur du tableau de bord",
    "go_to_manage_location_queue": "Accédez à Gérer la file d'attente des emplacements",
    "driver_details": "Détails du pilote",
    "car_model": "Modèle de voiture",
    "new_booking": "Nouvelle réservation",
    "search_trip_id": "Recherche par ID de voyage",
    "clear": "Clair",
    "upcoming_booking": "Réservation à venir",
    "ongoing_booking": "Réservation en cours",
    "past_booking": "Réservation passée",
    "cancelled_booking": "Réservation annulée",
    "notification_status": "Statut des notifications",
    "something_went_wrong": "Quelque chose s'est mal passé",
    "mail_preview": "Aperçu du courrier",
    "send": "Envoyer",
    "close": "Fermer",
    "send_mail_to_corporate": "Envoyer un courrier à l'entreprise",
    "navigate_to_trip": "Accédez au voyage",
    "passenger_log": "Journal des passagers",
    "pickup_location": "Lieu de prise en charge",
    "pickup_landmark": "Point de repère de ramassage",
    "drop_location": "Emplacement de dépôt",
    "drop_landmark": "Déposer un point de repère",
    "booking_type": "Type de réservation",
    "pickup_time": "Heure de ramassage",
    "payment_method": "Mode de paiement",
    "note_to_driver": "Note au conducteur",
    "flight_number": "Numéro du vol",
    "reference_number": "Numéro de référence",
    "promo_code": "Code promotionnel",
    "customer_price": "Prix ​​client",
    "customer_hourly_rate": "Tarif horaire client",
    "motor_info": "Informations sur le moteur",
    "model_name": "Nom du modèle",
    "passenger_info": "Informations passagers",
    "passenger_email": "E-mail du passager",
    "country_code": "Code du pays",
    "passenger_mobile": "Mobile pour passagers",
    "passenger": "Passager",
    "corporate": "Entreprise",
    "passenger_list": "Liste des passagers",
    "corporate_list": "Liste d'entreprise",
    "select_passenger_or_corporate_details": "Veuillez sélectionner les détails du passager ou de l'entreprise",
    "guest_passenger": "Passager invité",
    "edit_passenger": "Modifier le passager",
    "customer_details": "Détails du client",
    "personal_info": "Informations personnelles",
    "name": "Nom",
    "phone": "Téléphone",
    "clear_passenger": "Passager clair",
    "add_passenger": "Ajouter un passager",
    "please_enter_name": "Veuillez entrer le nom",
    "please_enter_email": "Veuillez entrer l'adresse e-mail",
    "please_enter_valid_email": "S'il vous plaît, mettez une adresse email valide",
    "please_enter_phone": "Veuillez entrer le numéro de téléphone",
    "guest_name": "Nom de l'invité",
    "please_enter_guest_name": "Veuillez entrer le nom de l'invité",
    "guest_email": "E-mail invité",
    "guest_phone": "Téléphone invité",
    "please_enter_guest_phone": "Veuillez entrer le téléphone de l'invité",
    "stop_1": "Arrêt 1",
    "select_pickup_location": "Veuillez sélectionner le lieu de prise en charge",
    "select_drop_location": "Veuillez sélectionner le lieu de dépôt",
    "enable_paste_map_link": "Activez cette option pour coller le lien de la carte Google au lieu de saisir l'adresse dans le lieu de prise en charge et de dépôt.",
    "pickup_location_google_map": "Lieu de prise en charge (lien Google Map)",
    "drop_location_google_map": "Emplacement de dépôt (lien Google Map)",
    "stop": "Arrêtez {{stopNumber}}",
    "previous": "Précédent",
    "next": "Suivant",
    "select_booking_type": "Sélectionnez le type de réservation",
    "normal_booking": "Réservation normale",
    "package_booking": "Réservation de forfait",
    "as_soon_as_possible": "Dès que possible",
    "now_booking_tooltip": "Réservation maintenant",
    "later_booking_tooltip": "Réservation ultérieure",
    "now": "Maintenant",
    "please_select_date": "Veuillez sélectionner une date",
    "select_package_type": "Sélectionnez le type de forfait",
    "hours": "Heures",
    "hrs": "heures",
    "days": "Jours",
    "minutes": "Minutes",
    "select_package": "Sélectionnez le forfait",
    "please_select_valid_package_type": "Veuillez sélectionner un type de forfait valide",
    "km": "KM",
    "aed": "DEA",
    "please_select_valid_package": "Veuillez sélectionner un forfait valide",
    "payment_type": "Type de paiement",
    "select_payment_type": "Sélectionnez le type de paiement",
    "cash": "Espèces",
    "bill": "Facture",
    "complimentary": "Gratuit",
    "pos": "PDV",
    "select_valid_payment_method": "Veuillez sélectionner un mode de paiement valide",
    "additional_elements": "Éléments supplémentaires",
    "custom_pricing": "Tarification personnalisée",
    "payment_by": "Paiement par",
    "customer": "Client",
    "note_to_driver_optional": "Note au conducteur (facultatif)",
    "flight_number_optional": "Numéro de vol (facultatif)",
    "reference_number_optional": "Numéro de référence (facultatif)",
    "promo_code_optional": "Code promotionnel (facultatif)",
    "assign_to_driver_optional": "Attribuer au chauffeur (facultatif)",
    "note_to_admin_optional": "Note à l'administrateur (facultatif)",
    "operational_supervisor_optional": "Superviseur opérationnel (facultatif)",
    "room_no_optional": "Numéro de chambre (facultatif)",
    "driver_share": "Partage de pilotes",
    "rsl_share": "Partager RSL",
    "corporate_share": "Part d'entreprise",
    "extra_charge": "Frais supplémentaires",
    "extra_charge_error_message": "Le supplément ne peut pas être supérieur au prix client",
    "trip_duration": "Durée du voyage",
    "remarks": "Remarques",
    "trip_type": "Type de voyage",
    "normal_trip": "Voyage normal",
    "round_trip": "Aller-retour",
    "round_trip_fare": "Tarif aller-retour",
    "single": "Célibataire",
    "double": "Double",
    "do_join": "faireRejoindre",
    "b2b": "B2B",
    "b2c": "B2C",
    "booking_status": "Statut de la réservation",
    "all": "Tous",
    "yet_to_start": "Reste à commencer",
    "waiting_for_passenger": "En attente du passager",
    "reassign": "Réaffecter",
    "driver_assigned": "Chauffeur assigné",
    "driver_on_the_way": "Chauffeur en route",
    "type_of_booking": "Type de réservation",
    "later": "Plus tard",
    "package": "Emballer",
    "booking_from": "Réservation à partir de",
    "passenger_app": "Application passager",
    "dispatcher_passenger": "Répartiteur - Passager",
    "dispatcher_corporate": "Répartiteur - Corporatif",
    "supervisor_app": "Application Superviseur",
    "walk_in_trip": "Promenade en voyage",
    "booking_from_new": "Réservation à partir de nouveau",
    "mobile_app": "Application mobile",
    "driver_trip": "Voyage du chauffeur",
    "location": "Emplacement",
    "company": "Entreprise",
    "car_type": "Type de voiture",
    "type_of_car": "Type de voiture",
    "search_by_booking_or_group_id": "Rechercher par réservation ou ID de groupe",
    "search_by_reference_number": "Recherche par numéro de référence",
    "search_without_date_range": "recherche sans plage de dates",
    "search_with_date_range": "recherche avec plage de dates",
    "to_date": "À ce jour",
    "booking_id": "Numéro de réservation",
    "type": "Taper",
    "corporate_name": "Raison sociale",
    "status": "Statut",
    "from": "Depuis",
    "dojoin": "Rejoindre",
    "custom_zone_name": "Nom de zone personnalisé",
    "driver": "Conducteur",
    "car_no": "Numéro de voiture",
    "others": "Autres",
    "assign_to_driver": "Attribuer au conducteur",
    "reference_code": "Code de référence",
    "note_to_admin": "Note à l'administrateur",
    "estimated_customer_price": "Prix ​​client estimé",
    "car_company": "Entreprise automobile",
    "operational_supervisor": "Superviseur opérationnel",
    "staff_number": "Effectif",
    "room_number": "Numéro de chambre",
    "company_booking_type": "Type de réservation de l'entreprise",
    "transfer": "Transfert",
    "full_day": "Journée complète",
    "half_day": "Demi-journée",
    "assign_driver": "Attribuer un chauffeur",
    "unassign": "Annuler l'attribution",
    "no_trip": "Aucun voyage disponible",
    "booking_cancellation": "Annulation de réservation",
    "are_you_sure_you_want_to_cancel_this_booking": "Êtes-vous sûr de vouloir annuler cette réservation ?",
    "cancel_reason": "Raison de l'annulation",
    "yes": "Oui",
    "no": "Non",
    "driver_unassign": "Désattribution du pilote",
    "send_sms_to_driver": "Envoyer un SMS au chauffeur",
    "send_whatsapp_to_corporate": "Envoyer Whatsapp à l'entreprise",
    "are_you_sure_you_want_to_unassign": "Etes-vous sûr de vouloir annuler l'attribution ?",
    "unassign_reason": "Annuler l'attribution du motif",
    "trip_details": "Détails du voyage",
    "date_time_picker": "DateHeurePicker",
    "check_in": "Enregistrement",
    "check_out": "Vérifier",
    "to": "à",
    "outlined": "Décrit",
    "date_time_range": "Date Plage horaire",
    "date_time": "Date Heure",
    "time": "Temps",
    "save": "Sauvegarder",
    "payment_options": "Options de paiement",
    "select_payment_method": "Sélectionnez le mode de paiement",
    "card": "Carte",
    "toggle_visibility": "Basculer la visibilité",
    "no_permission_to_change_fare": "Vous n'avez pas l'autorisation d'administrateur pour modifier le tarif",
    "error_message_rsl_customer_price": "Prix ​​client invalide",
    "extra_charge_error": "Le supplément ne peut pas être supérieur au prix client",
    "select_driver": "Sélectionnez le pilote",
    "customer_rate_optional": "Tarif client (Facultatif)",
    "please_select_drop_location": "Veuillez sélectionner le lieu de dépôt",
    "back": "Dos",
    "alert_message": "Succès! Votre action est terminée.",
    "choose_the_driver_from_list": "Choisissez le pilote dans la liste",
    "driver_list": "Liste des pilotes",
    "rating_for_drivers": "Évaluation pour les conducteurs",
    "accepted_driver": "Chauffeur accepté",
    "rejected_driver": "Conducteur rejeté",
    "booking_info": "Informations de réservation",
    "now_single_stop": "Maintenant (arrêt unique)",
    "united_arab_emirates": "Émirats arabes unis Émirats arabes unis",
    "guest_info": "Informations clients",
    "fare_details": "Détails du tarif",
    "base_fare": "Tarif de base",
    "distance_fare": "Tarif à distance",
    "waiting_cost": "Coût d'attente",
    "night_charge": "Charge de nuit",
    "evening_charge": "Frais de soirée",
    "toll_fare": "Tarif payant",
    "used_wallet_amount": "Montant du portefeuille utilisé",
    "promo_discount_amount": "Montant de la remise promotionnelle",
    "actual_trip_fare": "Tarif réel du voyage",
    "payment_mode": "Mode de paiement",
    "trip_route": "Itinéraire du voyage",
    "driver_route": "Itinéraire du conducteur",
    "trip_log": "Journal de voyage",
    "driver_rejection_log": "Journal de rejet du conducteur",
    "pickup_gmap": "Ramasser",
    "drop_gmap": "Baisse",
    "okay": "D'accord",
    "are_you_sure_to_end_trip": "Êtes-vous sûr de terminer le voyage ?",
    "trip_in_progress": "Voyage en cours",
    "waiting_for_payment": "En attente de paiement",
    "trip_start_time": "Heure de début du voyage",
    "trip": "Voyage",
    "booking_price": "Prix ​​de réservation",
    "additional_booking_price": "Prix ​​de réservation supplémentaire",
    "car_number": "Numéro de voiture",
    "end_trip": "Fin du voyage",
    "toggle_dropdown_visibility": "basculer la visibilité du mot de passe",
    "parking_charge": "Frais de stationnement",
    "total_fare": "Tarif total",
    "trip_end_time": "Heure de fin du voyage",
    "verify_status": "Vérifier le statut",
    "verified": "Vérifié",
    "need_to_verify": "Besoin de vérifier",
    "phone_number": "Numéro de téléphone",
    "emirates_id": "Identifiant des Émirats",
    "dropoff": "Dépôt",
    "room_no": "Numéro de chambre",
    "Hrs": "Heures",
    "mins": "Minutes",
    "secs": "Secondes",
    "trip_distance": "Distance du trajet",
    "waiting_time": "Temps d'attente",
    "past_trips_cancellation_fare": "Tarif d’annulation de voyages antérieurs",
    "trip_completed": "VOYAGE TERMINÉ",
    "receipt": "Reçu",
    "verify": "Vérifier",
    "passenger_cancelled": "Passager annulé",
    "dispatcher_cancelled": "Répartiteur annulé",
    "driver_cancelled": "Pilote annulé",
    "cancellation_reason": "Raison de l'annulation",
    "no_show": "Pas de présentation",
    "manage_custom_zone": "Gérer la zone personnalisée",
    "keyword": "Mot-clé",
    "search_by_zone_name_location": "Recherche par nom de zone, emplacement de zone",
    "status_update": "Mise à jour du statut",
    "reason": "Raison",
    "please_enter_reason": "Veuillez entrer la raison",
    "save_changes": "Enregistrer les modifications",
    "add": "Ajouter",
    "active": "Actif",
    "block": "Bloc",
    "trash": "Poubelle",
    "zone_name": "Nom de la zone",
    "zone_location": "Emplacement des zones",
    "action": "Action",
    "model_fare": "Tarif modèle (Ajouter/Modifier)",
    "driver_list_add_edit": "Liste des pilotes (Ajouter/Modifier)",
    "push_notification": "Notification poussée",
    "custom_zone_add": "Ajouter une zone personnalisée",
    "custom_zone_edit": "Modifier la zone personnalisée",
    "zone_amount": "Montant de la zone",
    "priority": "Priorité",
    "additional_information": "Informations Complémentaires",
    "key": "Clé",
    "value": "Valeur",
    "remove_polygon": "Supprimer le polygone",
    "add_polygon_required": "Veuillez ajouter un polygone, un polygone est requis !",
    "address": "Adresse",
    "customzone_fare": "Tarif zone personnalisée",
    "minimum_kilometers": "Kilomètres minimaux",
    "minimum_fare": "Tarif minimum",
    "cancellation_fare": "Tarif d'annulation",
    "below_above_kilometers": "En dessous/au-dessus des kilomètres",
    "below_kilometers": "En dessous des kilomètres",
    "above_kilometers": "Au-dessus des kilomètres",
    "fare_per_minute": "Tarif par minute",
    "morning_charge": "Frais du matin",
    "morning_timing_from": "Heure du matin à partir de",
    "evening_timing_from": "Horaires du soir à partir de",
    "evening_timing_to": "Horaires du soir",
    "admin_commission": "Commission d'administration",
    "company_commission": "Commission d'entreprise",
    "driver_commission": "Commission des conducteurs",
    "corporate_commission": "Commission d'entreprise",
    "night_timing_from": "Horaire de nuit",
    "night_timing_to": "Synchronisation de nuit vers",
    "no1": "NON",
    "yes1": "OUI",
    "custom_zone_drivers": "Pilotes de zone personnalisés",
    "add_driver_custom_zone": "Ajouter un pilote sous cette zone personnalisée",
    "zone_list": "Liste des zones",
    "manage_offer_zone": "Gérer la zone d'offre",
    "offer_zone_add": "Ajouter une zone d'offre",
    "offer_zone_edit": "Modifier la zone d'offre",
    "manage_divisions": "Gérer les divisions",
    "search_by_division_name": "Recherche par nom de division",
    "division_name": "Nom du département",
    "edit_division": "Modifier la division",
    "add_division": "Ajouter une division",
    "manage_departments": "Gérer les départements",
    "search_by_department_name": "Recherche par nom de département",
    "department_name": "Nom du département",
    "edit_department": "Modifier le département",
    "add_department": "Ajouter un département",
    "division": "Division",
    "invalid_email": "Format d'e-mail invalide",
    "phone_must_7_digits": "Le numéro de téléphone doit comporter 7 chiffres",
    "total_drivers": "Nombre total de conducteurs",
    "unassigned": "Non attribué",
    "new_drivers": "Nouveaux pilotes",
    "drivers_shift_status": "Statut de changement de conducteur",
    "shift_in": "Passer en",
    "shift_out": "Sortir",
    "offline": "Hors ligne",
    "sign_out": "Se déconnecter",
    "trip_assigned": "Voyage assigné",
    "busy_in_trip": "Occupé en voyage",
    "driver_search": "Recherche par nom, e-mail, nom de l'entreprise, téléphone, identifiant unique",
    "previous_reason": "Raison précédente",
    "referral_poster": "Affiche de référence",
    "valid": "Valide",
    "expired": "Expiré!",
    "open": "Ouvrir",
    "driverInfo": "Informations sur le conducteur",
    "driver_unique_id": "ID unique du conducteur",
    "attached_vehicle": "Véhicule attaché",
    "photo": "Photo",
    "driver_current_version": "Version actuelle du pilote",
    "location_status": "Statut de localisation",
    "created_date": "Date de création",
    "expiry_status": "Statut d'expiration",
    "manage_driver_record": "Gérer le dossier du conducteur",
    "profile_picture": "Photo de profil",
    "created_date_and_time": "Date et heure de création",
    "taxi_no": "Numéro de taxi",
    "manufacturer_and_make_name": "Fabricant et nom de la marque",
    "device_type": "Type d'appareil",
    "no_of_rows": "Nombre de lignes",
    "export_data": "Exporter des données",
    "add_driver": "Ajouter un pilote",
    "gender": "Genre",
    "male": "Mâle",
    "female": "Femelle",
    "please_enter_driver_unique_id": "Veuillez saisir l'identifiant unique du conducteur",
    "please_enter_number": "Veuillez saisir votre numéro de mobile (minimum 7 caractères)",
    "state_error": "Veuillez sélectionner l'état",
    "city_error": "Veuillez sélectionner la ville",
    "additional_info": "Informations supplémentaires",
    "company_error": "Veuillez sélectionner la compagnie automobile",
    "driver_license_id": "Identifiant du permis de conduire",
    "driver_license_expiry_date": "Date d'expiration du permis de conduire",
    "rta_card_id": "Identifiant de la carte RTA",
    "rta_card_expiry_date": "Date d'expiration de la carte RTA",
    "driver_national_id": "Carte d'identité nationale du conducteur",
    "driver_national_id_expiry_date": "Date d'expiration de la carte d'identité nationale du conducteur",
    "driver_iban_bank_account": "Compte bancaire IBAN du conducteur",
    "bank_name": "Nom de la banque",
    "please_select_expiry_date": "Veuillez sélectionner la date d'expiration",
    "please_enter_booking_limit": "Veuillez entrer la limite de réservation par jour",
    "attach_single_taxi": "Attacher un taxi unique",
    "assign_multiple_taxi": "Attribuer plusieurs taxis",
    "password_not_matched": "Le mot de passe ne correspond pas",
    "documents": "Documents",
    "emirates_id_front_image": "Image avant de la carte d'identité Emirates",
    "emirates_id_back_image": "Image arrière de la carte d'identité Emirates",
    "rta_card_front_image": "Image avant de la carte RTA",
    "rta_card_back_image": "Image arrière de la carte RTA",
    "driving_license_front_image": "Image avant du permis de conduire",
    "driving_license_back_image": "Image arrière du permis de conduire",
    "edit_driver": "Modifier le pilote",
    "view_banner": "Voir la bannière",
    "download": "Télécharger",
    "download_vertically": "Télécharger verticalement",
    "download_horizontally": "Télécharger horizontalement",
    "newly_registered_drivers": "Conducteurs nouvellement enregistrés",
    "waiting_for_approval": "En attente d'approbation",
    "approve": "Approuver",
    "disapprove": "Désapprouver",
    "edit_cars": "Modifier les voitures",
    "disapprove_driver_confirmation": "Êtes-vous sûr de désapprouver ce pilote ?",
    "show_report_for": "Afficher le rapport pour",
    "export_driver_status": "Exporter le pilote par statut",
    "all_driver_records": "Tous les dossiers de conducteur",
    "shift_in_driver_records": "Changement dans les dossiers des conducteurs",
    "shift_out_driver_records": "Supprimer les dossiers des conducteurs",
    "trip_assigned_driver_records": "Dossiers du conducteur assigné au voyage",
    "busy_in_trip_driver_records": "Occupé dans les dossiers des chauffeurs de voyage",
    "sign_out_driver_records": "Déconnexion des dossiers de conducteur",
    "offline_driver_records": "Dossiers de conducteur hors ligne",
    "manage_loss_found_logs_records": "Gérer les enregistrements de journaux de perte/trouvé",
    "comments": "Commentaires",
    "driver_id": "ID du conducteur",
    "subject": "Sujet",
    "description": "Description",
    "image": "Document",
    "logs": "Journaux",
    "no_logs": "Aucun journal",
    "select_status": "Sélectionnez le statut",
    "approved": "Approuvé",
    "rejected": "Rejeté",
    "please_select_status": "Veuillez sélectionner le statut",
    "enter_your_commands": "Entrez vos commandes",
    "driver_document_request_record": "Dossier de demande de document de conducteur",
    "search_by_model_name": "Rechercher par nom de modèle",
    "add_model": "Ajouter un modèle",
    "edit_model": "Modifier le modèle",
    "enter_model_name": "Entrez le nom du modèle.",
    "model_size": "Taille du modèle",
    "enter_model_size": "Entrez la taille du modèle.",
    "enter_base_fare": "Entrez le tarif de base",
    "min_km": "Kilométrage minimum",
    "min_km_error": "Entrez le KM minimum au format numérique (par exemple : 50,60)",
    "min_fare": "Tarif minimum",
    "min_fare_error": "Entrez le tarif minimum au format numérique (par exemple : 50,60,45)",
    "cancellation_fee": "Frais d'annulation",
    "below_above_km": "Plage KM inférieure et supérieure",
    "below_km_fare": "Tarif inférieur au KM (par KM)",
    "above_km_fare": "Tarif supérieur au KM (par KM)",
    "waiting_charge": "Frais d'attente de voiture (par heure)",
    "taxi_speed": "Vitesse de taxi",
    "taxi_min_speed": "Vitesse minimale des taxis",
    "car_waiting_charge": "Frais d'attente de voiture",
    "enter_cancellation_fee": "Saisissez les frais d'annulation sous forme de chiffres (par exemple, 30, 34,56)",
    "enter_below_above_km": "Entrez la plage de km inférieure et supérieure au format numérique (par exemple, 50)",
    "enter_below_km_fare": "Entrez le tarif KM ci-dessous au format numérique (par exemple, 7, 6,50)",
    "enter_above_km_fare": "Entrez le tarif KM ci-dessus sous forme de chiffres (par exemple, 12, 15,50)",
    "enter_waiting_charge": "Entrez les frais d'attente de la voiture",
    "enter_fare_per_minute": "Entrez le tarif par minute",
    "enter_taxi_speed": "Entrez la vitesse de la voiture par heure",
    "enter_taxi_min_speed": "Entrez le tarif minimum sous forme de chiffres (par exemple, 50, 60,45)",
    "above_km": "Tarif supérieur au KM (par KM)",
    "minutes_fare": "Tarif par minute",
    "night_fare": "Tarif de nuit",
    "evening_fare": "Tarif du soir",
    "enter_car_waiting_charge": "Entrez les frais d'attente de la voiture",
    "android_model_unfocus_image": "Image floue du modèle Android (175 x 100 pixels)",
    "ios_model_focus_image": "Image de mise au point du modèle iOS (125 x 71 pixels)",
    "ios_model_unfocus_image": "Image floue du modèle iOS (125 x 71 pixels)",
    "location_model_image": "Image du modèle de localisation (418 x 183 pixels)",
    "taxi_number": "Numéro de taxi",
    "car_manufacturer_name": "Nom du constructeur automobile",
    "car_make_name": "Nom de la marque de la voiture",
    "total_cars": "Total des voitures",
    "search_by_taxi_no_company_name": "Recherche par numéro de taxi, nom de l'entreprise",
    "car_model_year": "Année modèle de voiture",
    "car_owner_name": "Nom du propriétaire de la voiture",
    "car_color": "Couleur de la voiture",
    "taxi_motor_expiry_date": "Date d'expiration du moteur de taxi",
    "add_car": "Ajouter une voiture",
    "edit_car": "Modifier la voiture",
    "taxi_unique_id": "Identifiant unique du taxi",
    "taxi_insurance_number": "Numéro d'assurance taxi",
    "car_insurance_expiry_date": "Date d’expiration de l’assurance automobile",
    "car_chassis_number": "Numéro de châssis de voiture",
    "please_select_state": "Veuillez sélectionner l'état",
    "please_select_city": "Veuillez sélectionner la ville",
    "car_image_340_pixel": "Image de voiture (340 x 183 pixels)",
    "select_car_company": "Veuillez sélectionner une compagnie automobile",
    "please_select_country": "Veuillez sélectionner un pays",
    "car": "Voiture",
    "manage_assigned_car_records": "Gérer l'enregistrement des voitures attribuées",
    "search_by_driver_name": "Rechercher par nom de conducteur",
    "active_assigned_cars": "Active_AssignedCars",
    "block_assigned_cars": "Block_AssignedCars",
    "trash_assigned_cars": "Trash_AssignedCars",
    "unassigned_assigned_cars": "UnAssigned_AssignedCars",
    "all_assigned_cars": "All_AssignedCars",
    "total_subscription_list": "Liste totale des abonnements",
    "requested_date": "Date demandée",
    "subscribed_date": "Date d'abonnement",
    "unsubscribe_cancel_date": "Date de désabonnement/annulation",
    "duration_days_hours_minutes_seconds": "Durée (jours heures minutes secondes)",
    "subscription_status": "Statut de l'abonnement",
    "user": "Utilisateur",
    "subscription_amount": "Montant de l'abonnement",
    "log_count": "Nombre de journaux",
    "total_amount": "Montant total",
    "total_active": "Actif total",
    "total_subscribe": "Abonnement total",
    "total_unsubscribe": "Désabonnement total",
    "add_driver_to_subscription": "Ajouter un pilote à l'abonnement",
    "choose_driver_to_subscribe": "Choisissez le pilote pour vous abonner",
    "request_subscription": "Demander un abonnement",
    "activate_subscription": "Activer l'abonnement",
    "subscription_setting": "Paramètre d'abonnement",
    "auto": "Auto",
    "manual": "Manuel",
    "content": "Contenu",
    "hold": "Prise",
    "activate": "Activer",
    "date_filter_type": "Type de filtre de dates",
    "search_taxi_number": "Recherche par numéro de taxi",
    "reason_for_hold_subscription": "Raison de la suspension de l'abonnement",
    "reason_for_approve_subscription": "Raison de l'approbation de l'abonnement",
    "reason_for_cancel_subscription": "Raison de l'annulation de l'abonnement",
    "other_drivers": "Autres pilotes",
    "subscription_date": "Date de souscription",
    "unsubscribe_date": "Date de désabonnement",
    "duration": "Durée",
    "all_taxi_subscription_records": "Tous les enregistrements d'abonnement aux taxis",
    "active_taxi_subscription_records": "Dossiers d'abonnement aux taxis actifs",
    "subscribed_taxi_subscription_records": "Dossiers d'abonnement aux taxis souscrits",
    "unsubscribed_taxi_subscription_records": "Dossiers d'abonnement aux taxis non abonnés",
    "cancelled_taxi_subscription_records": "Dossiers d'abonnement aux taxis annulés",
    "hold_taxi_subscription_records": "Conserver les dossiers d'abonnement aux taxis",
    "pending_taxi_subscription_records": "Dossiers d'abonnement aux taxis en attente",
    "other_drivers_taxi_subscription_records": "Dossiers d'abonnement aux taxis pour autres conducteurs",
    "manufacturer_list": "Liste des fabricants",
    "edit_manufacturer": "Modifier le fabricant",
    "add_manufacturer": "Ajouter un fabricant",
    "car_make_list": "Liste des marques de voitures",
    "search_by_car_make_name": "Recherche par nom de marque de voiture...",
    "edit_car_make": "Modifier la marque de la voiture",
    "add_car_make": "Ajouter une marque de voiture",
    "upload_car_make_image": "Télécharger une image de marque de voiture",
    "car_make_image_allowed_format": "Formats autorisés : .jpeg, .jpg, .png, .webp",
    "download_document": "Télécharger le document",
    "total_company": "Entreprise totale",
    "manage_company_record": "Gérer le dossier de l'entreprise",
    "search_by_company_name_user_name_email": "Recherche par nom d'entreprise, nom d'utilisateur, identifiant de messagerie",
    "no_of_cars": "Nombre de voitures",
    "no_of_drivers": "Nombre de pilotes",
    "add_company": "Ajouter une entreprise",
    "personal_information": "Informations personnelles",
    "last_name": "Nom de famille",
    "company_image": "Image de l'entreprise (340x183 pixels)",
    "choose_theme": "Choisir un thème",
    "primary_color": "Couleur primaire *",
    "secondary_color": "Couleur secondaire *",
    "primary_color_required": "La couleur primaire est requise",
    "secondary_color_required": "La couleur secondaire est requise",
    "document_required": "Un document est requis",
    "shift_timing_from": "Changement de temps à partir de",
    "shift_timing_to": "Décaler le timing vers",
    "shift_hours": "Heures de travail",
    "edit_company": "Modifier la société",
    "company_info": "Informations sur l'entreprise",
    "company_car": "Voiture de société",
    "company_driver": "Chauffeur d'entreprise",
    "promo_code_discount": "Code promotionnel Remise (%)",
    "start_date": "Date de début",
    "expiry_date": "Date d'expiration",
    "mobile_app_visibility": "Visibilité des applications mobiles",
    "promocode_limit": "Limite du code promotionnel",
    "offer_type": "Type d'offre",
    "promocode_generation_type": "Type de génération de code promotionnel",
    "start_date_from": "Date de début à partir de",
    "start_date_to": "Date de début à",
    "expiry_date_from": "Date d'expiration du",
    "expiry_date_to": "Date d'expiration à",
    "search_by_promocode": "Rechercher par code promotionnel",
    "manage_promocode_record": "Gérer l'enregistrement du code promotionnel",
    "promocode": "Code promotionnel",
    "promocode_discount": "Remise sur le code promotionnel",
    "enabled": "Activé",
    "disabled": "Désactivé",
    "promocode_flow": "Flux de codes promotionnels",
    "lifestyle_promotion_flow": "Flux de promotion du style de vie",
    "dynamic": "Dynamique",
    "used_promocode_count": "Nombre de codes promotionnels utilisés",
    "unused_promocode_count": "Nombre de codes promotionnels inutilisés",
    "add_promocode": "Ajouter un code promotionnel",
    "select": "Sélectionner",
    "all_passenger": "Tous les passagers",
    "select_passenger": "Sélectionner un passager",
    "new_passengers": "Nouveaux passagers",
    "categories": "Catégories",
    "limit_per_passenger": "Limite par passager",
    "promocode_maximum_limit": "Limite maximale du code promotionnel",
    "discount_percentage": "Pourcentage",
    "discount_flat": "Plat",
    "ticket": "Billet",
    "discount_type": "Type de remise",
    "percentage": "Pourcentage",
    "flat": "Plat",
    "ticket_count": "Nombre de billets",
    "discount_in_percent": "Remise en %",
    "maximum_discount": "Remise maximale",
    "flat_amount": "Montant forfaitaire",
    "select_activity_packages": "Sélectionnez les forfaits d'activités",
    "select_staycation_packages": "Sélectionnez les forfaits Staycation",
    "select_bus_rental_packages": "Sélectionnez les forfaits de location de bus",
    "select_holipackages": "Sélectionnez les forfaits vacances",
    "select_car_rental_packages": "Sélectionnez les forfaits de location de voiture",
    "select_packages": "Sélectionnez les forfaits",
    "offer_for_other_services": "Offre pour d'autres services *",
    "offer_zone": "Zone d'offre *",
    "min_KM": "Kilométrage minimum",
    "max_km": "Kilométrage maximum",
    "edit_promocode": "Modifier le code promotionnel",
    "view_dynamic_promocode": "Afficher l'historique des codes promotionnels dynamiques",
    "dynamic_promocode_history": "Historique des codes promotionnels dynamiques",
    "user_details": "Détails de l'utilisateur",
    "not_used": "Non utilisé",
    "used": "Utilisé",
    "total_corporate": "Total Entreprise",
    "manage_corporate_record": "Gérer le dossier de l'entreprise",
    "search_by_name_email": "Recherche par nom, email",
    "add_edit_zone_group": "Ajouter/Modifier un groupe de zones",
    "zone_group_fare_mapping_new": "Cartographie tarifaire des groupes de zones Nouveau (marque de voiture)",
    "active_corporates": "Entreprises actives",
    "block_corporates": "Bloquer les entreprises",
    "trash_corporates": "Entreprises poubelles",
    "all_corporates": "Toutes les entreprises",
    "add_corporate": "Ajouter une entreprise",
    "edit_corporate": "Modifier l'entreprise",
    "fare_visibility": "Visibilité des tarifs pour le conducteur",
    "fare_settings": "Paramètres tarifaires",
    "zone_to_zone_fare": "Tarif zone à zone",
    "company_fare": "Tarif de l'entreprise",
    "trade_license_image": "Image de licence commerciale (340 x 183 pixels)",
    "corporate_logo": "Logo d'entreprise (340x183 pixels)",
    "contract_document": "Document contractuel",
    "below_above_km_range": "En dessous et au-dessus de la plage KM",
    "file_allowed_format": "Formats autorisés .jpeg,.jpg,.png,.pdf,.doc,.docx",
    "img_allowed_format": "Formats autorisés .jpeg,.jpg,.png",
    "do_you_want_to_add_new_password": "Voulez-vous ajouter un nouveau mot de passe ?",
    "password_cannot_be_empty": "Le mot de passe ne peut pas être vide",
    "download_trade_license": "Télécharger la licence commerciale",
    "download_contract_document": "Télécharger le document contractuel",
    "inspection_alert_duration": "Durée de l'alerte d'inspection",
    "inspection_setting": "Paramètre d'inspection",
    "duration_cannot_be_zero": "La durée ne peut pas être 0",
    "manage_zone_group": "Gérer le groupe de zones",
    "search_by_zone_group_name": "Rechercher par nom de groupe de zones",
    "manage_zone_group_record": "Gérer l'enregistrement du groupe de zones",
    "zone_group_name": "Nom du groupe de zones",
    "zone_names": "Noms des zones",
    "add_zone_group": "Ajouter un groupe de zones",
    "add_zone_under_this_group": "Ajouter une zone sous ce groupe de zones",
    "edit_zone_group": "Modifier un groupe de zones",
    "active_zone_groups": "Groupes de zones actives",
    "block_zone_groups": "Groupes de zones de blocage",
    "trash_zone_groups": "Groupes de zones de corbeille",
    "all_zone_groups": "Tous les groupes de zones",
    "corporate_amount": "Montant corporatif",
    "driver_amount": "Montant du conducteur",
    "company_amount": "Montant de l'entreprise",
    "admin_amount": "Montant administratif",
    "end_passenger_amount": "Montant du passager final",
    "remove_confirmation": "Etes-vous sûr de vouloir supprimer ?",
    "delete_confirmation": "Etes-vous sûr de vouloir supprimer ?",
    "zone_group_fare_mapping": "Cartographie tarifaire des groupes de zones (marque de voiture)",
    "zone_from": "Zone De",
    "zone_to": "Zone vers",
    "zone_group_from": "Groupe de zones De",
    "zone_group_to": "Groupe de zones vers",
    "remove": "Retirer",
    "corporate_id": "Identifiant d'entreprise",
    "package_type": "Type de colis",
    "add_package": "Ajouter un paquet",
    "package_hrs_days": "Forfait heures/jours",
    "package_hours_days": "Heures/jours du forfait",
    "search_by_zone_name": "Rechercher par nom de zone",
    "package_distance": "Distance du colis",
    "package_amount": "Montant du forfait",
    "edit_zone": "Modifier la zone",
    "add_zone": "Ajouter une zone",
    "zone_name_required": "Le nom de la zone est requis",
    "polygon_required": "Veuillez ajouter un polygone, un polygone est requis !",
    "active_zones": "Zones actives",
    "block_zones": "Zones de blocage",
    "trash_zones": "Zones poubelles",
    "all_zones": "Toutes les zones",
    "update_wallet_amount": "Mettre à jour le montant du portefeuille",
    "amount": "Montant",
    "enter_amount": "Veuillez entrer le montant",
    "enter_password": "Veuillez entrer le mot de passe",
    "enter_comments": "Veuillez entrer des commentaires",
    "manage_passengers_record": "Gérer le dossier des passagers",
    "active_passengers": "Passagers actifs",
    "block_passengers": "Bloquer les passagers",
    "trash_passengers": "Passagers poubelles",
    "all_passengers": "Tous les passagers",
    "wallet_amount": "Montant du portefeuille",
    "user_information": "Informations utilisateur",
    "otp_count": "Nombre d'OTP",
    "reset_otp_count": "Réinitialiser le nombre d'OTP",
    "reset_otp_count_confirmation": "Êtes-vous sûr de vouloir réinitialiser le nombre d'OTP ?",
    "package_additional_fare_per_km": "Tarif supplémentaire du forfait (par KM)",
    "package_additional_fare_per_hrs": "Tarif supplémentaire du forfait (par heure)",
    "short_description": "Brève description",
    "long_description": "Description longue",
    "edit_package": "Modifier le package",
    "multiple_dropoff_locations_add_edit": "Plusieurs emplacements de dépôt (Ajouter/Modifier)",
    "customize_fare": "Personnaliser le tarif",
    "total_location": "Emplacement total",
    "manage_location_record": "Gérer l'enregistrement de localisation",
    "search_by_name_email_company": "Recherche par nom, e-mail, nom de l'entreprise",
    "active_location": "Emplacement actif",
    "block_location": "Emplacement du bloc",
    "trash_location": "Emplacement de la poubelle",
    "all_location": "Tous les emplacements",
    "edit_location": "Modifier l'emplacement",
    "add_location": "Ajouter un emplacement",
    "address_is_required": "L'adresse est requise",
    "location_name": "Nom du lieu",
    "queue_limit": "Limite de file d'attente",
    "drop_your_message": "Déposez votre message",
    "commission_type": "Type de commissions",
    "subscription": "Abonnement",
    "polygon_restriction": "Restriction de polygone",
    "enable": "Activer",
    "disable": "Désactiver",
    "cancel_password_required": "Annuler le mot de passe *",
    "cancel_password": "Annuler le mot de passe",
    "cancel_password_empty": "Annuler Le mot de passe ne peut pas être vide",
    "confirm_cancel_password_required": "Confirmer Annuler le mot de passe *",
    "confirm_cancel_password": "Confirmer Annuler le mot de passe",
    "driver_limit_start_supervisor_trip": "Limite du conducteur pour démarrer le voyage du superviseur",
    "location_type": "Type d'emplacement",
    "general": "Général",
    "hotel": "Hôtel",
    "target_trip_count": "Nombre de voyages cible",
    "restrict_driver_remove_from_queue": "Restreindre la suppression du pilote de la file d'attente",
    "restriction_hours": "Heures restreintes",
    "location_fare_edit": "Tarif de localisation Modifier",
    "quick_trip_option_in_supervisor_app": "Option de voyage rapide dans l'application Supervisor",
    "driver_app_visibility": "Visibilité de l'application du pilote",
    "add_driver_to_queue": "Ajouter un pilote à la file d'attente",
    "queue_alert_notification": "Notification d'alerte de file d'attente",
    "queue_alert_notification_time": "Temps de notification d'alerte de file d'attente (en minutes)",
    "open_timing_from": "Date d'ouverture à partir de",
    "open_timing_to": "Calendrier d'ouverture vers",
    "get_ready_queue_reorder_time_in_minutes": "Temps de réorganisation de la file d'attente de préparation (en minutes)",
    "document_verification": "Vérification des documents",
    "verification_type": "Type de vérification",
    "auto_verify": "Vérification automatique",
    "manual_verify": "Vérification manuelle",
    "waiting_queue_n_drivers_to_reorder": "File d'attente – N nombre de conducteurs à réorganiser",
    "n_drivers_update": "N nombre de pilotes (emplacement extérieur) pour mettre à jour l'heure actuelle",
    "waiting_queue_time_in_minutes": "File d'attente – Durée en minutes pour réorganiser",
    "driver_time_update": "Heure (en minutes - Pilotes - Emplacement extérieur) pour mettre à jour la date et l'heure actuelles",
    "queue_setting": "Paramétrage de la file d'attente",
    "manual_setting": "Manuel - Seul le superviseur peut ajouter un pilote à la file d'attente",
    "driver_add_to_queue_restriction": "Restriction d'ajout de pilote à la file d'attente",
    "multiple": "Multiple",
    "location_driver_details": "Détails du pilote de localisation",
    "add_driver_under_location": "Ajouter un pilote sous cette zone de localisation",
    "location_list": "Liste des emplacements",
    "notes": "Remarques",
    "multiple_dropoff_locations": "Plusieurs lieux de dépôt",
    "latitude": "Latitude*",
    "longitude": "Longitude*",
    "fare": "Tarif *",
    "peak_hour_fare": "Tarif aux heures de pointe",
    "contract_amount": "Montant du contrat",
    "location_customized_fare": "Tarif personnalisé",
    "models": "Modèles",
    "queue_location_list": "Liste des emplacements de file d'attente",
    "main_queue": "File d'attente principale",
    "waiting_queue": "File d'attente",
    "queue_location_list_label": "Liste des emplacements de file d'attente",
    "model_list": "Liste des modèles",
    "note_model_filter": "Remarque : Vous ne pouvez pas réorganiser la file d'attente si vous avez appliqué un filtre de modèle",
    "car_plate_number": "Numéro de plaque d'immatriculation de la voiture",
    "confirm_queue_change": "Etes-vous sûr de vouloir passer à",
    "confirm_remove_driver": "Êtes-vous sûr de supprimer le pilote de la file d'attente ?",
    "group_name": "Nom du groupe",
    "kiosk_list": "Liste des kiosques",
    "sunday": "Dimanche",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "count": "Compter",
    "count_exceeded": "Le nombre de notifications ne peut pas dépasser",
    "location_queue_driver_logs_record": "Enregistrement des journaux du pilote de file d'attente d'emplacement",
    "queue_position": "Position dans la file d'attente",
    "supervisor_name": "Nom du superviseur",
    "supervisor_unique_id": "Identifiant unique du superviseur",
    "added_from": "Ajouté à partir de",
    "search_by_toll_name": "Recherche par nom de péage",
    "manage_toll_record": "Gérer l'enregistrement des péages",
    "toll_name": "Nom du péage",
    "toll_location": "Emplacement du péage",
    "toll_amount": "Montant du péage",
    "add_toll": "Ajouter un péage",
    "edit_toll": "Modifier le péage",
    "toll_location_is_required": "L'emplacement du péage est requis",
    "manage_supervisor": "Gérer le superviseur",
    "manage_supervisor_record": "Gérer le dossier du superviseur",
    "active_supervisors_report": "Rapport des superviseurs actifs",
    "block_supervisors_report": "Rapport des superviseurs bloqués",
    "trash_supervisors_report": "Rapport des superviseurs des déchets",
    "all_supervisors_report": "Rapport de tous les superviseurs",
    "edit_supervisor": "Modifier le superviseur",
    "add_supervisor": "Ajouter un superviseur",
    "supervisor_details": "Détails du superviseur",
    "view_supervisor": "Voir le superviseur",
    "send_push_notification": "Envoyer une notification push",
    "supervisor_not_login_error": "On dirait que ce superviseur n'est pas connecté !",
    "supervisor_monitor_list": "Liste des moniteurs des superviseurs",
    "push_id": "Pousser l'identifiant",
    "video_link": "Lien vidéo",
    "updated_date": "Date de mise à jour",
    "please_enter_remarks": "Veuillez entrer des remarques",
    "supervisor_login_time": "Heure de connexion du superviseur",
    "supervisor_logout_time": "Temps de déconnexion du superviseur",
    "supervisor_login_location": "Emplacement de connexion du superviseur",
    "supervisor_logout_location": "Emplacement de déconnexion du superviseur",
    "login_image": "Image de connexion",
    "logout_image": "Image de déconnexion",
    "shift_in_duration_hh_mm_ss": "Durée du décalage IN (HH:MM:SS)",
    "login_duration": "Durée de connexion (HH:MM:SS)",
    "select_columns_you_need": "Sélectionnez les colonnes dont vous avez besoin",
    "export_selected_data": "Exporter les données sélectionnées",
    "remove_all": "Supprimer tout",
    "select_all": "Sélectionner tout",
    "alert": "Alerte"
};

export default fr;
