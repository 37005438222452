/* eslint-disable prettier/prettier */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  //side nav bar

  dashboard: 'Dashboard',
  nds: 'NDS',
  dispatch_map: 'Dispatch Map',
  orders: 'Orders',
  manage_permission: 'Manage Permission',
  manage_handover: 'Manage Handover',
  custom_zone: 'Custom Zone',
  lifestyle_offer_zone: 'LifeStyle Offer Zone',
  divisions: 'Divisions',
  departments: 'Departments',
  drivers: 'Drivers',
  manage_drivers: 'Manage Drivers',
  manage_new_drivers: 'Manage New Drivers',
  export_driver_list: 'Export Driver List',
  manage_lost_found_logs: 'Manage Lost / Found logs',
  driver_document_request: 'Driver Document Request',
  cars: 'Cars',
  manage_models: 'Manage Models',
  manage_cars: 'Manage Cars',
  assign_car: 'Assign Car',
  manage_assigned_cars: 'Manage Assigned Cars',
  taxi_subscription_list: 'Taxi Subscription List',
  car_manufacturer: 'Car Manufacturer',
  car_make: 'Car Make',
  companies: 'Companies',
  manage_company: 'Manage Company',
  manage_promocode: 'Manage Promocode',
  corporates: 'Corporates',
  manage_corporate: 'Manage Corporate',
  corporate_packages: 'Corporate Packages',
  manage_zone: 'Manage Zone',
  zone_map_view: 'Zone Map View',
  passengers: 'Passengers',
  manage_passengers: 'Manage Passengers',
  passenger_packages: 'Passenger Packages',
  locations: 'Locations',
  known_locations: 'Known Locations',
  known_location_country: 'Manage Known Location Country',
  manage_category: 'Manage Category',
  manage_known_loc: 'Manage Known Location',
  manage_location: 'Manage Location',
  manage_queue_location: 'Manage Queue Location',
  location_group: 'Location Group',
  location_log: 'Location Log',
  location_queue_driver_log: 'Location Queue Driver Log',
  tolls: 'Tolls',
  manage_tolls: 'Manage Tolls',
  supervisor: 'Supervisor',
  supervisor_trip_reports: 'Supervisor Trip Reports',
  supervisor_logs: 'Supervisor Logs',
  discount: 'Discount',
  mobile_promotion: 'Mobile Promotion',
  marketing: 'Marketing',
  lifestyle_location: 'Lifestyle Location',
  manage_referral_settings: 'Manage Referral Settings',
  reports: 'Reports',
  driver_shift_hours: 'Driver Shift Hours',
  settlement: 'Settlement',
  driver_status: 'Driver Status',
  exit_record: 'Exit Record',
  geo_report: 'Geo Report',
  location_queue_driver_logs: 'Location Queue Driver Logs',
  all_other_reports: 'All Other Reports',
  driver_rejection_report: 'Driver Rejection Report',
  trip_reports: 'Trip Reports',
  all_transactions: 'All Transactions',
  completed_transactions: 'Completed Transactions',
  cancelled_transactions: 'Cancelled Transactions',
  account_reports: 'Account Reports',
  activity_logs: 'Activity Logs',
  historical_data: 'Historical Data',
  settings: 'Settings',
  site_settings: 'Site Settings',
  sms_templates: 'SMS Templates',
  admin_smtp_settings: 'Admin SMTP Settings',
  company_smtp_settings: 'Company SMTP Settings',
  admin_sms_settings: 'Admin SMS Settings',
  company_sms_settings: 'Company SMS Settings',
  manage_country: 'Manage Country',
  manage_state: 'Manage State',
  manage_city: 'Manage City',
  manage_social_network: 'Manage Social Network',
  manage_google_settings: 'Manage Google Settings',
  rider: 'Rider',

  //Dashboard

  this_report_displays_the_total_driver_shift_hours_for_the_selected_day:
    'This report displays the total driver shift hours for the selected day!',
  select_company: 'Select Company',
  total_active_hours: 'Total Active Hours',
  active_drivers: 'Active Drivers',
  total_vehicle: 'Total Vehicle',
  total_driver: 'Total Driver',
  total_shift_in_hours: 'Total Shift In Hours',
  total_shift_out_hours: 'Total Shift Out Hours',
  shift_list: 'Shift List',
  export: 'Export',
  search: 'Search',
  device_id: 'Device Id',
  unique_id: 'Unique Id',
  driver_name: 'Driver Name',
  vehicle_model: 'Vehicle Model',
  Company: 'Company',
  engine_hours: 'Engine Hours',
  idle_hours: 'Idle Hours',
  shift_in_hours: 'Shift In Hours',
  shift_out_hours: 'Shift Out Hours',
  rows_per_page: 'Rows per page',

  //profile

  driver_info: 'Driver Info',
  general_info: 'General Info',
  gps: 'GPS',
  software_info: 'Software Info',
  social: 'Social',
  view_history: 'View History',
  from_date: 'From Date',
  end_date: 'End Date',
  cancel: 'Cancel',
  shift_reports: 'Shift Reports',
  fare_update: 'Fare Update',
  update: 'Update',
  no_data_found: 'No Data Found',
  data_not_found: 'Data not Found',
  file_upload: 'File Upload',
  password: 'Password',
  edit: 'Edit',
  passenger_name: 'Passenger Name',
  trip_fare: 'Trip Fare',
  emirate_id: 'Emirate ID',
  passenger_phone_number: 'Passenger Phone Number',
  free: 'Free',
  s_no: 'S.No',
  busy: 'Busy',
  date: 'Date',
  log_in_time: 'Log In Time',
  shift_in_duration: 'Shift In Duration',
  shift_out_duration: 'Shift Out Duration',
  inmove: 'Inmove',
  idle: 'Idle',
  lat_lng: 'LatLng',
  accuracy: 'Accuracy',
  altitude: 'Altitude',
  bearing: 'Bearing',
  speed: 'Speed',
  last_update: 'Last Update',
  profile: 'Profile',
  android: 'Android',
  ios: 'iOS',

  //driver info
  user_profile_management: 'User Profile Management',
  ok: 'Ok',
  change_password: 'Change Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  please_enter_new_password: 'Please enter new password',
  please_enter_confirm_password: 'Please enter confirm password',
  submit: 'Submit',
  reset: 'Reset',
  view_more: 'View More',
  driver_information: 'Driver Information',
  first_name: 'First Name',
  email: 'Email',
  mobile_number: 'Mobile Number',
  otp: 'OTP',
  date_of_birth: 'Date of Birth',
  booking_limit_per_day: 'Booking limit per day',
  rating_points: 'Rating points',
  out_of: 'Out of',
  referral_code: 'Referral code',
  my_current_location: 'My Current Location',
  company_name: 'Company Name',
  company_information: 'Company Information',
  company_address: 'Company Address',
  country: 'Country',
  state: 'State',
  city: 'City',
  i_am_free_online: 'I am free - Online',
  shift_out_offline: 'Shift Out - Offline',
  i_am_busy_in_trip: 'I am busy in trip',
  signed_out: 'SIGNED OUT',
  trip_id: 'Trip id',
  logout: 'Logout',
  refresh: 'Refresh',
  last_updated: 'Last Updated',
  shift_history: 'Shift History',
  show_chart: 'Show Chart',
  show_list: 'Show List',
  referral_history: 'Referral History',
  total_earned: 'Total earned',
  pending: 'Pending',
  completed_journeys: 'Completed Journeys',
  current_month: 'Current Month',
  previous_month: 'Previous Month',

  //NDS
  mail_settings: 'Mail Settings',
  send_mail: 'Send Mail',
  trip_reports_title: 'Trip Reports (Completed Count)',
  subscription_reports_title: 'Subscription Reports',
  driver_shift_reports_title: 'Driver Shift Reports',
  location_queue_title: 'Location Queue (Driver Count)',
  email_settings: 'Email Settings',
  emails: 'Emails',
  emails_helper_text: 'Emails should be like abc@gmail.com,xyz@gmail.com',
  time_to_send: 'Time to send email',
  start_time: 'Start time',
  this_field_is_required: 'This Field is Required',
  success: 'Success',
  error_message: 'An unexpected error occurred.',
  mobile_b2c_now_booking: 'Mobile B2C - Now Booking',
  mobile_b2c_later_booking: 'Mobile B2C - Later Booking',
  admin_b2c_now_booking: 'Admin - B2C Now Booking',
  admin_b2c_later_booking: 'Admin - B2C Later Booking',
  admin_b2b_now_booking: 'Admin - B2B Now Booking',
  admin_b2b_later_booking: 'Admin - B2B Later Booking',
  corporate_b2b_now_booking: 'Corporate - B2B Now Booking',
  corporate_b2b_later_booking: 'Corporate - B2B Later Booking',
  walkin_trip: 'Walk-in Trip',
  driver_booking: 'Driver Booking',
  location_trip: 'Location Trip',
  website: 'Website',
  total_trips: 'Total Trips',
  tooltip_completed_transaction: 'Go to Completed Transaction Trip Reports',
  requested: 'Requested',
  subscribed: 'Subscribed',
  cancelled: 'Cancelled',
  unsubscribed: 'Unsubscribed',
  unsubscription_pending: 'Unsubscription Pending',
  on_hold: 'On Hold',
  total_active_subscriber_count: 'Total Active Subscriber Count',
  tooltip: 'Go to Taxi Subscription List',
  '0_8_hours': '0-8 Hours',
  '8_16_hours': '8-16 Hours',
  '16_24_hours': '16-24 Hours',
  go_to_dashboard: 'Go to Dashboard Driver Shift Reports',
  go_to_manage_location_queue: 'Go to Manage Location Queue',

  //dispatch

  driver_details: 'Driver Details',
  car_model: 'Car Model',

  //Orders

  new_booking: 'New Booking',
  search_trip_id: 'Search by Trip ID',
  clear: 'Clear',
  upcoming_booking: 'Upcoming Booking',
  ongoing_booking: 'Ongoing Booking',
  past_booking: 'Past Booking',
  cancelled_booking: 'Cancelled Booking',

  //New Booking
  notification_status: 'Notification Status',
  something_went_wrong: 'Something went wrong',
  mail_preview: 'Mail Preview',
  send: 'Send',
  close: 'Close',
  send_mail_to_corporate: 'Send Mail to Corporate',
  navigate_to_trip: 'Navigate to trip',
  passenger_log: 'Passenger Log',
  pickup_location: 'Pickup Location',
  pickup_landmark: 'Pickup Landmark',
  drop_location: 'Drop Location',
  drop_landmark: 'Drop Landmark',
  booking_type: 'Booking Type',
  pickup_time: 'Pickup Time',
  payment_method: 'Payment Method',
  note_to_driver: 'Note to driver',
  flight_number: 'Flight Number',
  reference_number: 'Reference Number',
  promo_code: 'Promo code',
  customer_price: 'Customer Price',
  customer_hourly_rate: 'Customer Hourly Rate',
  motor_info: 'Motor Info',
  model_name: 'Model Name',
  passenger_info: 'Passenger Info',
  passenger_email: 'Passenger Email',
  country_code: 'Country Code',
  passenger_mobile: 'Passenger Mobile',
  passenger: 'Passenger',
  corporate: 'Corporate',
  passenger_list: 'Passenger List',
  corporate_list: 'Corporate List',
  select_passenger_or_corporate_details:
    'Please select passenger or corporate details',
  guest_passenger: 'Guest Passenger',
  edit_passenger: 'Edit Passenger',
  customer_details: 'Customer Details',
  personal_info: 'Personal Info',
  name: 'Name',
  phone: 'Phone',
  clear_passenger: 'Clear Passenger',
  add_passenger: 'Add Passenger',
  please_enter_name: 'Please enter name',
  please_enter_email: 'Please enter email address',
  please_enter_valid_email: 'Please enter a valid email address',
  please_enter_phone: 'Please enter phone number',
  guest_name: 'Guest Name',
  please_enter_guest_name: 'Please enter guest name',
  guest_email: 'Guest Email',
  guest_phone: 'Guest Phone',
  please_enter_guest_phone: 'Please enter guest phone',
  stop_1: 'Stop 1',
  select_pickup_location: 'Please select pickup location',
  select_drop_location: 'Please select drop location',
  enable_paste_map_link:
    'Enable this to paste Google map link instead of typing the address in pickup and drop off location',
  pickup_location_google_map: 'Pickup Location (Google Map Link)',
  drop_location_google_map: 'Drop Location (Google Map Link)',
  stop: 'Stop {{stopNumber}}',
  previous: 'Previous',
  next: 'Next',
  select_booking_type: 'Select Booking Type',
  normal_booking: 'Normal Booking',
  package_booking: 'Package Booking',
  as_soon_as_possible: 'As soon as possible',
  now_booking_tooltip: 'Now Booking',
  later_booking_tooltip: 'Later Booking',
  now: 'Now',
  please_select_date: 'Please select date',
  select_package_type: 'Select Package Type',
  hours: 'Hours',
  hrs: 'hrs',
  days: 'Days',
  minutes: 'Minutes',
  select_package: 'Select Package',
  please_select_valid_package_type: 'Please select valid package type',
  km: 'KM',
  aed: 'AED',
  please_select_valid_package: 'Please select valid package',
  payment_type: 'Payment Type',
  select_payment_type: 'Select Payment Type',
  cash: 'Cash',
  bill: 'Bill',
  complimentary: 'Complimentary',
  pos: 'POS',
  select_valid_payment_method: 'Please select a valid payment method',
  additional_elements: 'Additional Elements',
  custom_pricing: 'Custom Pricing',
  payment_by: 'Payment By',
  customer: 'Customer',
  note_to_driver_optional: 'Note to Driver (Optional)',
  flight_number_optional: 'Flight Number (Optional)',
  reference_number_optional: 'Reference Number (Optional)',
  promo_code_optional: 'Promo Code (Optional)',
  assign_to_driver_optional: 'Assign to Driver (Optional)',
  note_to_admin_optional: 'Note to Admin (Optional)',
  operational_supervisor_optional: 'Operational Supervisor (Optional)',
  room_no_optional: 'Room No (Optional)',
  driver_share: 'Driver Share',
  rsl_share: 'RSL Share',
  corporate_share: 'Corporate Share',
  extra_charge: 'Extra Charge',
  extra_charge_error_message:
    'Extra charge cannot be greater than customer price',
  trip_duration: 'Trip Duration',
  remarks: 'Remarks',
  trip_type: 'Trip Type',
  normal_trip: 'Normal Trip',
  round_trip: 'Round Trip',
  round_trip_fare: 'Round Trip Fare',
  single: 'Single',
  double: 'Double',

  //Upcoming

  do_join: 'doJoin',
  b2b: 'B2B',
  b2c: 'B2C',
  booking_status: 'Booking Status',
  all: 'All',
  yet_to_start: 'Yet to start',
  waiting_for_passenger: 'Waiting for Passenger',
  reassign: 'Reassign',
  driver_assigned: 'Driver Assigned',
  driver_on_the_way: 'Driver on the way',
  type_of_booking: 'Type of booking',
  later: 'Later',
  package: 'Package',
  booking_from: 'Booking From',
  passenger_app: 'Passenger App',
  dispatcher_passenger: 'Dispatcher - Passenger',
  dispatcher_corporate: 'Dispatcher - Corporate',
  supervisor_app: 'Supervisor App',
  walk_in_trip: 'Walk in trip',
  booking_from_new: 'Booking From New',
  mobile_app: 'Mobile App',
  driver_trip: 'Driver Trip',
  location: 'Location',
  company: 'Company',
  car_type: 'Car Type',
  type_of_car: 'Type of car',
  search_by_booking_or_group_id: 'Search by Booking or Group ID',
  search_by_reference_number: 'Search by Reference Number',
  search_without_date_range: 'search without date range',
  search_with_date_range: 'search with date range',
  to_date: 'To Date',
  booking_id: 'Booking ID',
  type: 'Type',
  corporate_name: 'Corporate Name',
  status: 'Status',
  from: 'From',
  dojoin: 'Dojoin',
  custom_zone_name: 'Custom Zone Name',
  driver: 'Driver',
  car_no: 'Car No',
  others: 'Others',
  assign_to_driver: 'Assign To Driver',
  reference_code: 'Reference Code',
  note_to_admin: 'Note to Admin',
  estimated_customer_price: 'Estimated Customer Price',
  car_company: 'Car Company',
  operational_supervisor: 'Operational Supervisor',
  staff_number: 'Staff Number',
  room_number: 'Room Number',
  company_booking_type: 'Company Booking Type',
  transfer: 'Transfer',
  full_day: 'Full Day',
  half_day: 'Half Day',
  assign_driver: 'Assign Driver',
  unassign: 'Unassign',
  no_trip: 'No trip available',
  booking_cancellation: 'Booking Cancellation',
  are_you_sure_you_want_to_cancel_this_booking:
    'Are you sure you want to cancel this booking?',
  cancel_reason: 'Cancel Reason',
  yes: 'Yes',
  no: 'No',
  driver_unassign: 'Driver Unassign',
  send_sms_to_driver: 'Send SMS to Driver',
  send_whatsapp_to_corporate: 'Send Whatsapp to Corporate',
  are_you_sure_you_want_to_unassign: 'Are you sure you want to unassign?',
  unassign_reason: 'Unassign Reason',
  trip_details: 'Trip Details',
  date_time_picker: 'DateTimePicker',
  check_in: 'Check-in',
  check_out: 'Check-out',
  to: 'to',
  outlined: 'Outlined',
  date_time_range: 'Date Time range',
  date_time: 'Date Time',
  time: 'Time',
  save: 'Save',
  payment_options: 'Payment Options',
  select_payment_method: 'Select payment method',
  card: 'Card',
  toggle_visibility: 'Toggle visibility',
  no_permission_to_change_fare:
    "You don't have the admin permission to change the fare",
  error_message_rsl_customer_price: 'Invalid customer price',
  extra_charge_error: 'Extra charge cannot be greater than customer price',
  select_driver: 'Select Driver',
  customer_rate_optional: 'Customer rate (Optional)',
  please_select_drop_location: 'Please select drop location',
  back: 'Back',
  alert_message: 'Success! Your action has been completed.',
  choose_the_driver_from_list: 'Choose The Driver From List',
  driver_list: 'Driver List',
  rating_for_drivers: 'Rating For Drivers',
  accepted_driver: 'Accepted Driver',
  rejected_driver: 'Rejected Driver',
  include_corporate: 'Include Corporate', //new
  exclude_corporate: 'Exclude Corporate',

  booking_info: 'Booking Info',
  now_single_stop: 'Now (Single Stop)',
  united_arab_emirates: 'United Arab Emirates UAE',
  guest_info: 'Guest Info',
  fare_details: 'Fare Details',
  base_fare: 'Base Fare',
  distance_fare: 'Distance Fare',
  waiting_cost: 'Waiting Cost',
  night_charge: 'Night Charge',
  evening_charge: 'Evening Charge',
  toll_fare: 'Toll Fare',
  used_wallet_amount: 'Used Wallet Amount',
  promo_discount_amount: 'Promo Discount Amount',
  actual_trip_fare: 'Actual Trip Fare',
  payment_mode: 'Payment Mode',
  trip_route: 'Trip route',
  driver_route: 'Driver route',
  trip_log: 'Trip Log',
  driver_rejection_log: 'Driver Rejection Log',
  pickup_gmap: 'Pickup',
  drop_gmap: 'Drop',

  //ongoing booking
  okay: 'Okay',
  are_you_sure_to_end_trip: 'Are you sure to end trip?',
  trip_in_progress: 'Trip in progress',
  waiting_for_payment: 'Waiting for Payment',
  trip_start_time: 'Trip Start Time',
  trip: 'Trip',
  booking_price: 'Booking Price',
  additional_booking_price: 'Additional Booking Price',
  car_number: 'Car Number',
  end_trip: 'End Trip',

  //past Boooking
  toggle_dropdown_visibility: 'toggle password visibility',
  parking_charge: 'Parking Charge',
  total_fare: 'Total Fare',
  trip_end_time: 'Trip End Time',
  verify_status: 'Verify Status',
  verified: 'Verified',
  need_to_verify: 'Need to Verify',
  phone_number: 'Phone Number',
  emirates_id: 'Emirates Id',
  dropoff: 'Dropoff',
  room_no: 'Room No',
  Hrs: 'Hrs',
  mins: 'Mins',
  secs: 'Secs',
  trip_distance: 'Trip Distance',
  waiting_time: 'Waiting Time',
  past_trips_cancellation_fare: 'Past Trips Cancellation Fare',
  trip_completed: 'TRIP COMPLETED',
  receipt: 'Receipt',
  verify: 'Verify',

  //cancelled booking
  passenger_cancelled: 'Passenger Cancelled',
  dispatcher_cancelled: 'Dispatcher Cancelled',
  driver_cancelled: 'Driver Cancelled',
  cancellation_reason: 'Cancellation Reason',
  no_show: 'No Show',

  //Manage Custom Zone

  manage_custom_zone: 'Manage Custom Zone',
  keyword: 'Keyword',
  search_by_zone_name_location: 'Search by Zone Name, Zone Location',
  status_update: 'Status Update',
  reason: 'Reason',
  please_enter_reason: 'Please enter reason',
  save_changes: 'Save changes',
  add: 'Add',
  active: 'Active',
  block: 'Block',
  trash: 'Trash',
  zone_name: 'Zone Name',
  zone_location: 'Zone Location',
  action: 'Action',
  model_fare: 'Model fare (Add/Edit)',
  driver_list_add_edit: 'Driver List (Add/Edit)',
  push_notification: 'Push Notification',

  //add ,edit custom zone
  custom_zone_add: 'Add Custom Zone',
  custom_zone_edit: 'Edit Custom Zone',
  zone_amount: 'Zone Amount',
  priority: 'Priority',
  additional_information: 'Additional Information',
  key: 'Key',
  value: 'Value',
  remove_polygon: 'Remove Polygon',
  add_polygon_required: 'Please Add Polygon, Polygon is required!',
  address: 'Address',

  ///Manage Custom Zone -Customzone Fare
  customzone_fare: 'Customzone Fare',
  minimum_kilometers: 'Minimum Kilometers',
  minimum_fare: 'Minimum Fare',
  cancellation_fare: 'Cancellation Fare',
  below_above_kilometers: 'Below/Above Kilometers',
  below_kilometers: 'Below Kilometers',
  above_kilometers: 'Above Kilometers',
  fare_per_minute: 'Fare Per Minute',
  morning_charge: 'Morning Charge',
  morning_timing_from: 'Morning Timing From',
  evening_timing_from: 'Evening Timing From',
  evening_timing_to: 'Evening Timing To',
  admin_commission: 'Admin Commission',
  company_commission: 'Company Commission',
  driver_commission: 'Driver Commission',
  corporate_commission: 'Corporate Commission',
  night_timing_from: 'Night Timing From',
  night_timing_to: 'Night Timing To',
  no1: 'NO',
  yes1: 'YES',

  //Manage custom zone -Custom Zone Drivers
  custom_zone_drivers: 'Custom Zone Drivers',
  add_driver_custom_zone: 'Add Driver Under This Custom Zone',
  zone_list: 'Zone List',

  //Manage Offer Zone

  manage_offer_zone: 'Manage Offer Zone',
  offer_zone_add: 'Add  Offer Zone',
  offer_zone_edit: 'Edit Offer Zone',

  //manage Division

  manage_divisions: 'Manage Divisions',
  search_by_division_name: 'Search by Division name',
  division_name: 'Division Name',
  edit_division: 'Edit Division',
  add_division: 'Add Division',

  //Manage Department

  manage_departments: 'Manage Departments',
  search_by_department_name: 'Search by Department Name',
  department_name: 'Department Name',
  edit_department: 'Edit Department',
  add_department: 'Add Department',
  division: 'Division',
  invalid_email: 'Invalid email format',
  phone_must_7_digits: 'Phone number must be 7 digits',

  //manage Driver
  //dashboard
  total_drivers: 'Total Drivers',
  unassigned: 'Unassigned',
  new_drivers: 'New Drivers',
  drivers_shift_status: 'Drivers Shift Status',
  shift_in: 'Shift In',
  shift_out: 'Shift Out',
  offline: 'Offline',
  sign_out: 'Sign Out',
  trip_assigned: 'Trip Assigned',
  busy_in_trip: 'Busy in trip',
  driver_search: 'Search by Name, Email, Company Name, Phone, Unique ID',
  previous_reason: 'Previous Reason',
  referral_poster: 'Referral Poster',
  valid: 'Valid',
  expired: 'Expired!',
  open: 'Open',
  driverInfo: 'Driver Info',
  driver_unique_id: 'Driver Unique ID',
  attached_vehicle: 'Attached Vehicle',
  photo: 'Photo',
  driver_current_version: 'Driver Current Version',
  location_status: 'Location Status',
  created_date: 'Created Date',
  expiry_status: 'Expiry Status',

  //export
  manage_driver_record: 'Manage Driver Record',
  profile_picture: 'Profile Picture',
  created_date_and_time: 'Created Date and Time',
  taxi_no: 'Taxi No',
  manufacturer_and_make_name: 'Manufacturer and Make Name',
  device_type: 'Device Type',
  no_of_rows: 'No of rows',
  export_data: 'Export Data',

  //add driver

  add_driver: 'Add Driver',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  please_enter_driver_unique_id: 'Please enter driver unique id',
  please_enter_number: 'Please enter Mobile number (minimum 7 characters)',
  state_error: 'Please, select state',
  city_error: 'Please, select city',
  additional_info: 'Additional Info',
  company_error: 'Please, select car company',
  driver_license_id: 'Driver license ID',
  driver_license_expiry_date: 'Driver License Expiry Date',
  rta_card_id: 'RTA Card ID',
  rta_card_expiry_date: 'RTA Card Expiry Date',
  driver_national_id: 'Driver National ID',
  driver_national_id_expiry_date: 'Driver National ID Expiry Date',
  driver_iban_bank_account: 'Driver IBAN Bank Account',
  bank_name: 'Bank Name',
  please_select_expiry_date: 'Please, select Expiry Date',
  please_enter_booking_limit: 'Please enter booking limit per day',
  attach_single_taxi: 'Attach Single Taxi',
  assign_multiple_taxi: 'Assign Multiple Taxi',
  password_not_matched: 'Password not matched',
  documents: 'Documents',
  emirates_id_front_image: 'Emirates ID Front Image',
  emirates_id_back_image: 'Emirates ID Back Image',
  rta_card_front_image: 'RTA Card Front Image',
  rta_card_back_image: 'RTA Card Back Image',
  driving_license_front_image: 'Driving License Front Image',
  driving_license_back_image: 'Driving License Back Image',
  edit_driver: 'Edit Driver',
  view_banner: 'View Banner',
  download: 'Download',
  download_vertically: 'Download Vertically',
  download_horizontally: 'Download Horizontally',

  //newly registered drivers
  title: 'Title', //new
  newly_registered_drivers: 'Newly Registered Drivers',
  waiting_for_approval: 'Waiting For Approval',
  approve: 'Approve',
  disapprove: 'Disapprove',
  edit_cars: 'Edit Cars',
  disapprove_driver_confirmation: 'Are you sure to disapprove this driver?',
  show_report_for: 'Show report for',

  //Export Driver By Status
  export_driver_status: 'Export Driver By Status',
  all_driver_records: 'All Driver Records',
  shift_in_driver_records: 'Shift In Driver Records',
  shift_out_driver_records: 'Shift Out Driver Records',
  trip_assigned_driver_records: 'Trip Assigned Driver Records',
  busy_in_trip_driver_records: 'Busy In Trip Driver Records',
  sign_out_driver_records: 'Sign Out Driver Records',
  offline_driver_records: 'Offline Driver Records',

  // manage lost found logs
  manage_loss_found_logs_records: 'Manage Loss/ Found Logs Records',

  //Driver Document request
  comments: 'Comments',
  driver_id: 'Driver ID',
  subject: 'Subject',
  description: 'Description',
  image: 'Document',
  logs: 'Logs',
  no_logs: 'No Logs',
  select_status: 'Select status',
  approved: 'Approved',
  rejected: 'Rejected',
  please_select_status: 'Please select status',
  enter_your_commands: 'Enter your Commands',
  driver_document_request_record: 'Driver Document Request Record',

  //Manage model

  search_by_model_name: 'Search by Model name',
  add_model: 'Add Model',
  edit_model: 'Edit Model',
  enter_model_name: 'Enter the model name.',
  model_size: 'Model Size',
  enter_model_size: 'Enter the model size.',
  enter_base_fare: 'Enter Base Fare',
  min_km: 'Minimum KM',
  min_km_error: 'Enter Minimum KM in number format (For Example: 50,60)',
  min_fare: 'Minimum Fare',
  min_fare_error:
    'Enter the minimum fare in number format (For Example: 50,60.45)',
  cancellation_fee: 'Cancellation Fee',
  below_above_km: 'Below and Above KM range',
  below_km_fare: 'Below KM Fare (per KM)',
  above_km_fare: 'Above KM Fare (per KM)',
  waiting_charge: 'Car Waiting Charge (per Hour)',
  taxi_speed: 'Taxi Speed',
  taxi_min_speed: 'Taxi Minimum Speed',
  car_waiting_charge: 'Car waiting charge',
  enter_cancellation_fee:
    'Enter the cancellation fee in number format (e.g., 30, 34.56)',
  enter_below_above_km:
    'Enter the Below and Above KM range in number format (e.g., 50)',
  enter_below_km_fare:
    'Enter the below KM fare in number format (e.g., 7, 6.50)',
  enter_above_km_fare:
    'Enter the above KM fare in number format (e.g., 12, 15.50)',
  enter_waiting_charge: 'Enter the car waiting charge',
  enter_fare_per_minute: 'Enter fare per minute',
  enter_taxi_speed: 'Enter the car speed per hour',
  enter_taxi_min_speed:
    'Enter the minimum fare in number format (e.g., 50, 60.45)',
  above_km: 'Above KM Fare (per KM)',
  minutes_fare: 'Fare per Minute',
  night_fare: 'Night Fare',
  evening_fare: 'Evening Fare',
  enter_car_waiting_charge: 'Enter car waiting charge',
  android_model_unfocus_image: 'Android model unfocus image (175x100 pixel)',
  ios_model_focus_image: 'iOS model focus image (125x71 pixel)',
  ios_model_unfocus_image: 'iOS model unfocus image (125x71 pixel)',
  location_model_image: 'Location Model Image (418x183 pixel)',

  //manage cars
  taxi_number: 'Taxi Number',
  car_manufacturer_name: 'Car Manufacturer Name',
  car_make_name: 'Car Make Name',
  total_cars: 'Total Cars',
  search_by_taxi_no_company_name: 'Search by Taxi No, Company Name',
  car_model_year: 'Car Model Year',
  car_owner_name: 'Car Owner Name',
  car_color: 'Car Color',
  taxi_motor_expiry_date: 'Taxi Motor Expiry Date',
  add_car: 'Add Car',
  edit_car: 'Edit Car',
  taxi_unique_id: 'Taxi Unique Id',
  taxi_insurance_number: 'Taxi Insurance Number',
  car_insurance_expiry_date: 'Car Insurance Expiry Date',
  car_chassis_number: 'Car Chassis Number',
  please_select_state: 'Please select state',
  please_select_city: 'Please select city',
  car_image_340_pixel: 'Car image (340x183 pixel)',
  manufacturer_name: 'Manufacturer Name',
  active_cars: 'Active_Cars', //new
  block_cars: 'Block_Cars',
  trash_cars: 'Trash_Cars',
  unassigned_cars: 'UnAssigned_Cars',
  all_cars: 'All_Cars',

  //assign car

  select_car_company: 'Please select a car company',
  please_select_country: 'Please select a country',
  car: 'Car',
  manage_assigned_car_records: 'Manage Assigned Cars Record',
  search_by_driver_name: 'Search by driver name',
  active_assigned_cars: 'Active_AssignedCars',
  block_assigned_cars: 'Block_AssignedCars',
  trash_assigned_cars: 'Trash_AssignedCars',
  unassigned_assigned_cars: 'UnAssigned_AssignedCars',
  all_assigned_cars: 'All_AssignedCars',

  //Taxi Subscription List
  total_subscription_list: 'Total Subscription List',
  requested_date: 'Requested Date',
  subscribed_date: 'Subscribed Date',
  unsubscribe_cancel_date: 'Unsubscribe/Cancel Date',
  duration_days_hours_minutes_seconds: 'Duration (Days Hours Minutes Seconds)',
  subscription_status: 'Subscription Status',
  user: 'User',
  subscription_amount: 'Subscription Amount',
  log_count: 'Log Count',
  total_amount: 'Total Amount',
  total_active: 'Total Active',
  total_subscribe: 'Total Subscribe',
  total_unsubscribe: 'Total Unsubscribe',
  add_driver_to_subscription: 'Add Driver to Subscription',
  choose_driver_to_subscribe: 'Choose Driver to Subscribe',
  request_subscription: 'Request Subscription',
  activate_subscription: 'Activate Subscription',
  subscription_setting: 'Subscription Setting',
  auto: 'Auto',
  manual: 'Manual',
  content: 'Content',
  hold: 'Hold',
  activate: 'Activate',
  date_filter_type: 'Date Filter Type',
  search_taxi_number: 'Search by Taxi Number',
  reason_for_hold_subscription: 'Reason For Hold Subscription',
  reason_for_approve_subscription: 'Reason For Approve Subscription',
  reason_for_cancel_subscription: 'Reason For Cancel Subscription',
  other_drivers: 'Other Drivers',
  subscription_date: 'Subscription Date',
  unsubscribe_date: 'Unsubscribe Date',
  duration: 'Duration',
  all_taxi_subscription_records: 'All Taxi Subscription Records',
  active_taxi_subscription_records: 'Active Taxi Subscription Records',
  subscribed_taxi_subscription_records: 'Subscribed Taxi Subscription Records',
  unsubscribed_taxi_subscription_records:
    'UnSubscribed Taxi Subscription Records',
  cancelled_taxi_subscription_records: 'Cancelled Taxi Subscription Records',
  hold_taxi_subscription_records: 'Hold Taxi Subscription Records',
  pending_taxi_subscription_records: 'Pending Taxi Subscription Records',
  other_drivers_taxi_subscription_records:
    'Other Drivers Taxi Subscription Records',
  employee_id: 'EMPLOYEE ID',
  rsl_number: 'RSL Number',
  log_date: 'Log Date',
  taxi_subscription_logs:'Taxi Subscription Logs',

  //Car Manufacturer
  manufacturer_list: 'Manufacturer List',
  edit_manufacturer: 'Edit Manufacturer',
  add_manufacturer: 'Add Manufacturer',

  //Car Make
  car_make_list: 'Car Make List',
  search_by_car_make_name: 'Search by Car Make Name...',
  edit_car_make: 'Edit Car Make',
  add_car_make: 'Add Car Make',
  upload_car_make_image: 'Upload Car Make Image',
  car_make_image_allowed_format: 'Allowed Formats: .jpeg, .jpg, .png, .webp',
  download_document: 'Download Document',

  //manage company

  total_company: 'Total Company',
  manage_company_record: 'Manage Company Record',
  search_by_company_name_user_name_email:
    'Search by Company Name, User Name, Email ID',
  no_of_cars: 'No of Cars',
  no_of_drivers: 'No of Drivers',

  //add edit company
  add_company: 'Add Company',
  personal_information: 'Personal Information',
  last_name: 'Last Name',
  company_image: 'Company image (340x183 pixel)',
  choose_theme: 'Choose Theme',
  primary_color: 'Primary Color *',
  secondary_color: 'Secondary Color *',
  primary_color_required: 'Primary Color is required',
  secondary_color_required: 'Secondary Color is required',
  document_required: 'Document is required',
  shift_timing_from: 'Shift Timing From',
  shift_timing_to: 'Shift Timing To',
  shift_hours: 'Shift Hours',
  edit_company: 'Edit Company',
  company_info: 'Company Info',
  company_car: 'Company Car',
  company_driver: 'Company Driver',

  //manage promo code

  promo_code_discount: 'Promo code Discount (%)',
  start_date: 'Start Date',
  expiry_date: 'Expiry Date',
  mobile_app_visibility: 'Mobile App Visibility',
  promocode_limit: 'Promocode Limit',
  offer_type: 'Offer Type',
  promocode_generation_type: 'Promocode Generation Type',
  start_date_from: 'Start Date From',
  start_date_to: 'Start Date To',
  expiry_date_from: 'Expiry Date From',
  expiry_date_to: 'Expiry Date To',
  search_by_promocode: 'Search by Promocode',
  manage_promocode_record: 'Manage Promocode Record',
  promocode: 'Promocode',
  promocode_discount: 'Promocode Discount',
  enabled: 'Enabled',
  disabled: 'Disabled',
  promocode_flow: 'Promocode Flow',
  lifestyle_promotion_flow: 'Lifestyle Promotion Flow',
  dynamic: 'Dynamic',
  used_promocode_count: 'Used Promocode Count',
  unused_promocode_count: 'Unused Promocode Count',

  //add promocode

  add_promocode: 'Add Promocode',
  select: 'Select',
  all_passenger: 'All Passenger',
  select_passenger: 'Select Passenger',
  new_passengers: 'New Passengers',
  categories: 'Categories',
  limit_per_passenger: 'Limit Per Passenger',
  promocode_maximum_limit: 'Promocode Maximum Limit',
  discount_percentage: 'Percentage',
  discount_flat: 'Flat',
  ticket: 'Ticket',
  discount_type: 'Discount Type',
  percentage: 'Percentage',
  flat: 'Flat',
  ticket_count: 'Ticket Count',
  discount_in_percent: 'Discount in %',
  maximum_discount: 'Maximum Discount',
  flat_amount: 'Flat Amount',
  select_activity_packages: 'Select Activity Packages',
  select_staycation_packages: 'Select Staycation Packages',
  select_bus_rental_packages: 'Select Bus Rental Packages',
  select_holipackages: 'Select Holiday Packages',
  select_car_rental_packages: 'Select Car Rental Packages',
  select_packages: 'Select Packages',
  offer_for_other_services: 'Offer For Other Services *',
  offer_zone: 'Offer Zone *',
  min_KM: 'Min KM',
  max_km: 'Max KM',
  edit_promocode: 'Edit Promocode',
  view_dynamic_promocode: 'View Dynamic Promocode History',
  dynamic_promocode_history: 'Dynamic Promocodes History',
  user_details: 'User Details',
  not_used: 'Not Used',
  used: 'Used',

  //corporate
  total_corporate: 'Total Corporate',
  manage_corporate_record: 'Manage Corporate Record',
  search_by_name_email: 'Search by Name , Email',
  add_edit_zone_group: 'Add/Edit Zone Group',
  zone_group_fare_mapping_new: 'Zone Group Fare Mapping New (Car Make)',
  active_corporates: 'Active Corporates',
  block_corporates: 'Block Corporates',
  trash_corporates: 'Trash Corporates',
  all_corporates: 'All Corporates',
  add_corporate: 'Add Corporate',
  edit_corporate: 'Edit Corporate',
  fare_visibility: 'Fare Visibility For Driver',
  fare_settings: 'Fare Settings',
  zone_to_zone_fare: 'Zone to Zone Fare',
  company_fare: 'company Fare',
  trade_license_image: 'Trade License Image (340x183 pixel)',
  corporate_logo: 'Corporate Logo (340x183 pixel)',
  contract_document: 'Contract Document',
  below_above_km_range: 'Below and Above KM Range',
  file_allowed_format: 'Allowed formats .jpeg,.jpg,.png,.pdf,.doc,.docx',
  img_allowed_format: 'Allowed formats .jpeg,.jpg,.png',
  do_you_want_to_add_new_password: 'Do you want to add new password?',
  password_cannot_be_empty: 'Password cannot be empty',
  download_trade_license: 'Download Trade License',
  download_contract_document: 'Download Contract Document',
  inspection_alert_duration: 'Inspection Alert Duration',
  inspection_setting: 'Inspection Setting',
  duration_cannot_be_zero: 'Duration cannot be 0',

  //Manage Zone Group
  manage_zone_group: 'Manage Zone Group',
  search_by_zone_group_name: 'Search by Zone Group Name',
  manage_zone_group_record: 'Manage Zone Group Record',
  zone_group_name: 'Zone Group Name',
  zone_names: 'Zone Names',
  add_zone_group: 'Add Zone Group',
  add_zone_under_this_group: 'Add zone under this zone group',
  edit_zone_group: 'Edit Zone Group',
  active_zone_groups: 'Active Zone Groups',
  block_zone_groups: 'Block Zone Groups',
  trash_zone_groups: 'Trash Zone Groups',
  all_zone_groups: 'All Zone Groups',
  corporate_amount: 'Corporate Amount',
  driver_amount: 'Driver Amount',
  company_amount: 'Company Amount',
  admin_amount: 'Admin Amount',
  end_passenger_amount: 'End Passenger Amount',
  remove_confirmation: 'Are you sure want to remove?',
  delete_confirmation: 'Are you sure want to delete?',

  //zone Group Fare Mapping
  zone_group_fare_mapping: 'Zone Group Fare Mapping (Car Make)',
  zone_from: 'Zone From',
  zone_to: 'Zone To',
  zone_group_from: 'Zone Group From',
  zone_group_to: 'Zone Group To',
  remove: 'Remove',
  corporate_id: 'Corporate Id',

  //Corporate Package
  package_type: 'Package Type',
  add_package: 'Add Package',
  package_hrs_days: 'Package Hrs/Days',
  package_hours_days: 'Package Hours/Days',
  search_by_zone_name: 'Search by Zone Name',
  package_distance: 'Package Distance',
  package_amount: 'Package Amount',
  edit_zone: 'Edit Zone',
  add_zone: 'Add Zone',
  zone_name_required: 'Zone Name is required',
  polygon_required: 'Please Add Polygon, Polygon is required!',
  active_zones: 'Active Zones',
  block_zones: 'Block Zones',
  trash_zones: 'Trash Zones',
  all_zones: 'All Zones',

  //Passenger
  update_wallet_amount: 'Update Wallet Amount',
  amount: 'Amount',
  enter_amount: 'Please enter amount',
  enter_password: 'Please enter password',
  enter_comments: 'Please enter Comments',
  manage_passengers_record: 'Manage Passengers Record',
  active_passengers: 'Active Passengers',
  block_passengers: 'Block Passengers',
  trash_passengers: 'Trash Passengers',
  all_passengers: 'All Passengers',
  wallet_amount: 'Wallet Amount',

  //passenger info
  user_information: 'User Information',
  otp_count: 'OTP Count',
  reset_otp_count: 'Reset OTP Count',
  reset_otp_count_confirmation: 'Are you sure want to reset OTP Count?',

  //passenger package
  package_additional_fare_per_km: 'Package Additional Fare (per KM )',
  package_additional_fare_per_hrs: 'Package Additional Fare(per Hrs)',
  short_description: 'Short description',
  long_description: 'Long Description',
  edit_package: 'Edit Package',

  //Manage Location

  multiple_dropoff_locations_add_edit: 'Multiple DropOff Locations (Add/Edit)',
  customize_fare: 'Customize Fare',
  total_location: 'Total Location',
  manage_location_record: 'Manage Location Record',
  search_by_name_email_company: 'Search by Name, Email, Company Name',
  active_location: 'Active Location',
  block_location: 'Block Location',
  trash_location: 'Trash Location',
  all_location: 'All Locations',
  edit_location: 'Edit Location',
  add_location: 'Add Location',
  address_is_required: 'Address is required',
  location_name: 'Location Name',
  queue_limit: 'Queue Limit',
  drop_your_message: 'Drop Your Message',
  commission_type: 'Commision Type',
  subscription: 'Subscription',
  polygon_restriction: 'Polygon Restriction',
  enable: 'Enable',
  disable: 'Disable',
  cancel_password_required: 'Cancel Password *',
  cancel_password: 'Cancel Password',
  cancel_password_empty: 'Cancel Password cannot be empty',
  confirm_cancel_password_required: 'Confirm Cancel Password *',
  confirm_cancel_password: 'Confirm Cancel Password',
  driver_limit_start_supervisor_trip: 'Driver Limit to Start Supervisor Trip',
  location_type: 'Location Type',
  general: 'General',
  hotel: 'Hotel',
  target_trip_count: 'Target Trip Count',
  restrict_driver_remove_from_queue: 'Restrict Driver Remove From Queue',
  restriction_hours: 'Restriction Hours',
  location_fare_edit: 'Location Fare Edit',
  quick_trip_option_in_supervisor_app: 'Quick Trip Option in Supervisor App',
  driver_app_visibility: 'Driver App Visibility',
  add_driver_to_queue: 'Add Driver To Queue',
  queue_alert_notification: 'Queue Alert Notification',
  queue_alert_notification_time: 'Queue Alert Notification Time (In minutes)',
  open_timing_from: 'Open Timing From',
  open_timing_to: 'Open Timing To',
  get_ready_queue_reorder_time_in_minutes:
    'Get Ready Queue Reorder Time (In minutes)',
  document_verification: 'Document Verification',
  verification_type: 'Verification Type',
  auto_verify: 'Auto Verify',
  manual_verify: 'Manual Verify',
  waiting_queue_n_drivers_to_reorder:
    'Waiting Queue - N number of drivers to Reorder',
  n_drivers_update:
    'N number of drivers (Outside Location) to update current time',
  waiting_queue_time_in_minutes: 'Waiting Queue - Time in minutes to Reorder',
  driver_time_update:
    'Time (in Minutes - Drivers - Outside Location) to update current date time',
  queue_setting: 'Queue Setting',
  manual_setting: 'Manual - Only supervisor can add driver to queue',
  driver_add_to_queue_restriction: 'Driver Add to Queue Restriction',
  multiple: 'Multiple',

  //location driver details

  location_driver_details: 'Location Driver Details',
  add_driver_under_location: 'Add driver under this location zone',
  location_list: 'Location List',
  notes: 'Notes',
  multiple_dropoff_locations: 'Multiple Dropoff Locations',
  latitude: 'Latitude *',
  longitude: 'Longitude *',
  fare: 'Fare *',
  peak_hour_fare: 'Peak Hour Fare',
  contract_amount: 'Contract Amount',
  location_customized_fare: 'Location Customized Fare',
  models: 'Models',

  //location queue
  queue_location_list: 'Queue Location List',
  main_queue: 'Main Queue',
  waiting_queue: 'Waiting Queue',
  queue_location_list_label: 'Queue Location List',
  model_list: 'Model List',
  note_model_filter:
    'Note: You cannot reorder queue if you applied model filter',
  car_plate_number: 'Car Plate Number',
  confirm_queue_change: 'Are you sure you want to change to',
  confirm_remove_driver: 'Are you sure to remove the driver from Queue?',

  //location group

  group_name: 'Group Name',
  kiosk_list: 'Kiosk List',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  count: 'Count',
  count_exceeded: "Notification count can't extend more than ",
  location_queue_driver_logs_record: 'Location queue driver logs record',
  queue_position: 'Queue Position',
  supervisor_name: 'Supervisor Name',
  supervisor_unique_id: 'Supervisor Unique Id',
  added_from: 'Added From',

  //Toll

  search_by_toll_name: 'Search by Toll Name',
  manage_toll_record: 'Manage Toll Record',
  toll_name: 'Toll Name',
  toll_location: 'Toll Location',
  toll_amount: 'Toll Amount',
  add_toll: 'Add Toll',
  edit_toll: 'Edit Toll',
  toll_location_is_required: 'Toll Location is Required',

  //supervisor

  manage_supervisor: 'Manage Supervisor',
  manage_supervisor_record: 'Manage Supervisor Record',
  active_supervisors_report: 'Active Supervisors Report',
  block_supervisors_report: 'Blocked Supervisors Report',
  trash_supervisors_report: 'Trash Supervisors Report',
  all_supervisors_report: 'All Supervisors Report',
  edit_supervisor: 'Edit Supervisor',
  add_supervisor: 'Add Supervisor',

  //Supervisor info
  supervisor_details: 'Supervisor Details',
  view_supervisor: 'View Supervisor',
  send_push_notification: 'Send Push Notification',
  supervisor_not_login_error: 'Seems like this supervisor not logged in!',
  supervisor_monitor_list: 'Supervisor Monitor List',
  push_id: 'Push Id',
  video_link: 'Video Link',
  updated_date: 'Updated Date',
  please_enter_remarks: 'Please enter remarks',

  //Supervisor Logs
  supervisor_login_time: 'Supervisor Login Time',
  supervisor_logout_time: 'Supervisor Logout Time',
  supervisor_login_location: 'Supervisor Login Location',
  supervisor_logout_location: 'Supervisor Logout Location',
  login_image: 'Login Image',
  logout_image: 'Logout Image',
  shift_in_duration_hh_mm_ss: 'Shift IN Duration (HH:MM:SS)',
  login_duration: 'Login Duration (HH:MM:SS)',
  select_columns_you_need: 'Select Columns you need',
  export_selected_data: 'Export Selected Data',
  remove_all: 'Remove All',
  select_all: 'Select All',
  alert: 'Alert'
};

export default en;
